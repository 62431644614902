import React, { useState, useEffect } from "react";
import api from "../../service/api";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/register.module.css";
import logo_azul from "../../img/logo-azul";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import VerificaLogin from "../../utils/verificaLogin";
import { VerificaTipoContaRegistrar } from "../../utils/verificaTipoConta";

import toastError from "../../utils/toastError";
import toastSuccess from "../../utils/toastSuccess";

const Register = () => {
  //Verificando se o usuario esta com o cookie valido
  VerificaLogin();
  VerificaTipoContaRegistrar();

  const navigate = useNavigate();

  //State para receber os objetos do endpoint
  const [type, setType] = useState([]);

  //Use state para receber o valor selecionado do select, tendo o valor padrão como comercial que é o primeiro item
  const [selectValue, setSelectValue] = useState("");

  //State para receber email e senha
  const [values, setValues] = useState({
    email: "",
    password: "",
    type: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { data } = await api.post(
        "/register",
        {
          ...values,
          selectValue,
        },
        { withCredentials: true }
      );

      // verifica se os dados estão certos, senão gera o erro.
      if (data) {
        if (data.errors) {
          const { email, password, type } = data.errors;
          if (email) toastError(email);
          else if (password) toastError(password);
          else if (type) toastError(type);
        } else {
          setTimeout(() => {
            toastSuccess("Cadastrado com sucesso");
          }, 100);
          navigate("/dashboard");
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  //Buscando as informações dos tipos para popular o select
  useEffect(() => {
    api.get("/api/type").then((response) => {
      setType(response.data);
    });
  }, []);

  //Voltando para a tela principal
  const BackToApp = () => {
    navigate("/dashboard");
  };

  //Populando o Select dos tipos de funcionarios
  const optionItems = type.map((val) => (
    <MenuItem value={val.nome} key={val.nome}>
      {val.nome}
    </MenuItem>
  ));

  return (
    <>
      <div className={styles.container}>
        <main className={styles.subContainer}>
          <div className={styles.logoAzul}>
            <img src={logo_azul} alt={"logo-principal"} />
          </div>
          <form
            onSubmit={(e) => handleSubmit(e)}
            className={styles.formRegister}
          >
            <div className={styles.inputs}>
              <TextField
                sx={{
                  width: "100%",
                }}
                label="Email"
                variant="outlined"
                type="email"
                name="email"
                size="small"
                autoComplete="off"
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            </div>
            <div className={styles.inputs}>
              <TextField
                sx={{
                  width: "100%",
                }}
                label="Senha"
                variant="outlined"
                type={"text"}
                name="password"
                size="small"
                autoComplete="off"
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            </div>
            <FormControl size="small">
              <InputLabel id="demo-select-small">Tipo</InputLabel>
              <Select
                sx={{
                  width: "100%",
                }}
                labelId="demo-select-small"
                id="demo-select-small"
                label="Tipo"
                value={selectValue}
                onChange={(e) =>
                  setValues(
                    { ...values, type: e.target.value },
                    setSelectValue(e.target.value)
                  )
                }
              >
                {optionItems}
              </Select>
            </FormControl>
            <input type="submit" value="Registrar" />
          </form>
          <div className={styles.buttonBack}>
            <button onClick={BackToApp} className={styles.buttonBack}>
              Voltar
            </button>
          </div>
        </main>
      </div>
    </>
  );
};

export default Register;
