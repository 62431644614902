import styled from "styled-components";

const BotaoTransferencia = styled.input`
  text-align: center;
  width: 100%;
  padding: 0.4rem;
  background: #a9a9a9;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 5px;
  transition: all 0.3s ease;
  height: 40px;
  :hover {
    background: #b9b9b9;
  }
`;

export default BotaoTransferencia;
