import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import api from "../../../../service/api";
import Navbar from "../../../../components/navbar/Navbar";
import DocumentsContainer from "../../../../styles/DocumentsContainer";
import LoadingRocket from "../../../../components/animations/LoadingRocket";
import ErrorAnimation from "../../../../components/animations/ErrorAnimation";
import SuccessAnimation from "../../../../components/animations/SuccessAnimation";
import style from "styled-components";
import ServerAnimation from "../../../../components/animations/ServerAnimation";
import { Link } from "react-router-dom";
import Botao from "../../../../styles/Botao";
import { MdCancel } from "react-icons/md";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import BotaoExcluir from "../../../../styles/BotaoExcluir";
import toastError from "../../../../utils/toastError";
import toastSuccess from "../../../../utils/toastSuccess";
import { FaUserEdit } from "react-icons/fa";
import Switch from "react-switch";
import { TextField } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch } from "react-icons/ai";

const Table = style.div`
  color: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
`;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: window.innerWidth > 600 ? theme.spacing(5) : theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ListarAcessos = () => {
  const [expanded, setExpanded] = useState("");
  const [loading, setLoading] = useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [acessos, setAcessos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [idAcesso, setIdAcesso] = useState("");
  const [nomeAcesso, setNomeAcesso] = useState("");
  const [search, setSearch] = useState("");

  const [fitolog, setFitolog] = useState(false);
  const [comercial, setComercial] = useState(false);
  const [adm, setAdm] = useState(false);
  const [rh, setRh] = useState(false);
  const [tecnico, setTecnico] = useState(false);
  const [newsis, setNewsis] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [projetos, setProjetos] = useState(false);
  const [controleGestao, setControleGestao] = useState(false);
  const [treinamentos, setTreinamentos] = useState(false);
  const [ti, setTi] = useState(false);
  const [temp, setTemp] = useState(false);
  const [franquias, setFranquias] = useState(false);

  const handleChangeFitolog = () => {
    !fitolog ? setFitolog(true) : setFitolog(false);
  };

  const handleChangeComercial = () => {
    !comercial ? setComercial(true) : setComercial(false);
  };

  const handleChangeAdm = () => {
    !adm ? setAdm(true) : setAdm(false);
  };

  const handleChangeRh = () => {
    !rh ? setRh(true) : setRh(false);
  };

  const handleChangeTecnico = () => {
    !tecnico ? setTecnico(true) : setTecnico(false);
  };

  const handleChangeNewsis = () => {
    !newsis ? setNewsis(true) : setNewsis(false);
  };

  const handleChangeMarketing = () => {
    !marketing ? setMarketing(true) : setMarketing(false);
  };

  const handleChangeProjetos = () => {
    !projetos ? setProjetos(true) : setProjetos(false);
  };

  const handleChangeControleGestao = () => {
    !controleGestao ? setControleGestao(true) : setControleGestao(false);
  };

  const handleChangeTreinamentos = () => {
    !treinamentos ? setTreinamentos(true) : setTreinamentos(false);
  };

  const handleChangeTi = () => {
    !ti ? setTi(true) : setTi(false);
  };

  const handleChangeTemp = () => {
    !temp ? setTemp(true) : setTemp(false);
  };

  const handleChangeFranquias = () => {
    !franquias ? setFranquias(true) : setFranquias(false);
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleOpenEditar = () => setModalEditar(true);
  const handleCloseEditar = () => setModalEditar(false);

  const editarAcesso = (nome) => {
    acessos.map((acesso) => {
      if (acesso.nome === nome) {
        setIdAcesso(acesso.id);
        setNomeAcesso(acesso.nome);
        setFitolog(acesso.fitolog ? true : false);
        setComercial(acesso.comercial ? true : false);
        setAdm(acesso.adm ? true : false);
        setRh(acesso.rh ? true : false);
        setTecnico(acesso.tecnico ? true : false);
        setNewsis(acesso.newsis ? true : false);
        setMarketing(acesso.marketing ? true : false);
        setProjetos(acesso.projetos ? true : false);
        setControleGestao(acesso.controle_gestao ? true : false);
        setTreinamentos(acesso.treinamentos ? true : false);
        setTi(acesso.ti ? true : false);
        setTemp(acesso.temp ? true : false);
        setFranquias(acesso.franquias ? true : false);
      }
      return null;
    });
  };

  const buscaAcessos = () => {
    api.get("api/buscarAcessos").then((response) => {
      setAcessos(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    buscaAcessos();
  }, []);

  const divStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f3f3f3",
    borderRadius: "10px",
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    outline: "none",
  };

  const styleEditar = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxHeight: "80%",
    overflowY: "auto",
    outline: "none",
  };

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const limpaSwitch = () => {
    setIdAcesso("");
    setNomeAcesso("");
    setFitolog(false);
    setComercial(false);
    setAdm(false);
    setRh(false);
    setTecnico(false);
    setNewsis(false);
    setMarketing(false);
    setProjetos(false);
    setControleGestao(false);
    setTreinamentos(false);
    setTi(false);
    setTemp(false);
    setFranquias(false);
  };

  return (
    <>
      {
        <Modal open={openDialog} onClose={handleCloseDialog}>
          <Box sx={style}>
            <h2
              style={{
                fontSize: "1.2rem",
                fontWeight: "normal",
              }}
            >
              Tem certeza que deseja remover{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {nomeAcesso}?
              </span>
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <BotaoExcluir
                type={"submit"}
                value={"Cancelar"}
                style={{
                  width: "100%",
                }}
                onClick={handleCloseDialog}
              />
              <Botao
                type={"submit"}
                value={"Confirmar"}
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  handleCloseDialog();
                  setLoading(true);
                  api
                    .post("api/excluirAcesso", { id: idAcesso })
                    .then((response) => {
                      if (response.data.sucesso) {
                        toastSuccess("Acesso excluído com sucesso");
                        setExpanded(false);
                        return buscaAcessos();
                      }
                      toastError("Erro ao excluir acesso");
                    });
                }}
              />
            </div>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={modalEditar}
          onClose={() => {
            handleCloseEditar();
            limpaSwitch();
          }}
        >
          <Box sx={styleEditar}>
            <h2
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              Editar Acesso
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <TextField
                sx={{
                  width: "100%",
                }}
                value={nomeAcesso}
                onChange={(e) => setNomeAcesso(e.target.value)}
                label="Nome"
                variant="outlined"
              />
              <div style={divStyle}>
                <p>Fitolog</p>
                <Switch
                  checked={fitolog}
                  onChange={handleChangeFitolog}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Comercial</p>
                <Switch
                  checked={comercial}
                  onChange={handleChangeComercial}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Administrativo</p>
                <Switch
                  checked={adm}
                  onChange={handleChangeAdm}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Recuros Humanos</p>
                <Switch
                  checked={rh}
                  onChange={handleChangeRh}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Técnico</p>
                <Switch
                  checked={tecnico}
                  onChange={handleChangeTecnico}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Newsis</p>
                <Switch
                  checked={newsis}
                  onChange={handleChangeNewsis}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Marketing</p>
                <Switch
                  checked={marketing}
                  onChange={handleChangeMarketing}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Projetos</p>
                <Switch
                  checked={projetos}
                  onChange={handleChangeProjetos}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Controle de Gestão</p>
                <Switch
                  checked={controleGestao}
                  onChange={handleChangeControleGestao}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Treinamentos</p>
                <Switch
                  checked={treinamentos}
                  onChange={handleChangeTreinamentos}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>TI</p>
                <Switch
                  checked={ti}
                  onChange={handleChangeTi}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Temp</p>
                <Switch
                  checked={temp}
                  onChange={handleChangeTemp}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>
              <div style={divStyle}>
                <p>Franquias</p>
                <Switch
                  checked={franquias}
                  onChange={handleChangeFranquias}
                  onColor="#045E88"
                  activeBoxShadow={null}
                />
              </div>

              <Botao
                type={"submit"}
                value={"Confirmar"}
                onClick={() => {
                  handleCloseEditar();
                  setLoading(true);
                  api
                    .post("api/editarAcesso", {
                      id: idAcesso,
                      nome: nomeAcesso,
                      fitolog: fitolog ? 1 : 0,
                      comercial: comercial ? 1 : 0,
                      adm: adm ? 1 : 0,
                      rh: rh ? 1 : 0,
                      tecnico: tecnico ? 1 : 0,
                      newsis: newsis ? 1 : 0,
                      marketing: marketing ? 1 : 0,
                      projetos: projetos ? 1 : 0,
                      controle_gestao: controleGestao ? 1 : 0,
                      treinamentos: treinamentos ? 1 : 0,
                      ti: ti ? 1 : 0,
                      temp: temp ? 1 : 0,
                      franquias: franquias ? 1 : 0,
                    })
                    .then((response) => {
                      if (response.data.sucesso) {
                        setLoading(false);
                        buscaAcessos();
                        return toastSuccess("Acesso editado com sucesso");
                      }
                      setLoading(false);
                      toastError("Erro ao editar acesso");
                    });
                }}
              />
              <BotaoExcluir
                type={"submit"}
                value={"Cancelar"}
                style={{
                  width: "100%",
                  marginTop: "0px",
                }}
                onClick={handleCloseEditar}
              />
            </div>
          </Box>
        </Modal>
      }
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <DocumentsContainer>
        <div
          style={
            window.innerWidth > 768
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "2rem",
                  backgroundColor: "#bdbdbd33",
                  padding: "1rem",
                  borderRadius: "5px",
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                }
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <ServerAnimation />
            <h1
              style={
                window.innerWidth > 768
                  ? {
                      color: "#1e1e1e",

                      fontSize: "1.8rem",
                    }
                  : {
                      textAlign: "center",
                      fontSize: "1.4rem",
                    }
              }
            >
              Acessos ao servidor
            </h1>
          </div>
          <div
            style={
              window.innerWidth > 768
                ? null
                : {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "1rem",
                    width: "100%",
                  }
            }
          >
            <div
              style={
                window.innerWidth > 768
                  ? {
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }
                  : { width: "100%" }
              }
            >
              <TextField
                style={{
                  width: "100%",
                }}
                size={"small"}
                variant="outlined"
                placeholder="Buscar Acesso"
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
              />
              <Link to="/administradores/controleServidor/cadastrarAcesso">
                <Botao
                  value={"Cadastrar Novo acesso"}
                  type={"button"}
                  style={
                    window.innerWidth > 768
                      ? {
                          marginTop: "0",
                          padding: "0rem 1rem",
                        }
                      : {
                          width: "84vw",
                        }
                  }
                />
              </Link>
            </div>
          </div>
        </div>
        {acessos
          .filter((acesso) => {
            if (search === "") {
              return acesso;
            } else if (
              acesso.nome.toLowerCase().includes(search.toLowerCase())
            ) {
              return acesso;
            }
            return null;
          })
          .map((acesso, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
              >
                <div
                  style={{
                    fontWeight: "500",
                    color: "#1e1e1e",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {acesso.nome}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                    }}
                  >
                    <FaUserEdit
                      size={20}
                      color={"#1e1e1e"}
                      onClick={(e) => {
                        e.stopPropagation();
                        editarAcesso(acesso.nome);
                        handleOpenEditar();
                      }}
                    />
                    <MdCancel
                      size={20}
                      color={"#da2626"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIdAcesso(acesso.id);
                        setNomeAcesso(acesso.nome);
                        handleOpenDialog();
                      }}
                    />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& > :nth-of-type(odd)": {
                    backgroundColor: "#bdbdbd33",
                    borderRadius: "5px",
                  },
                }}
              >
                <Table>
                  Fitolog{" "}
                  {expanded === `panel${index}` ? (
                    acesso.fitolog ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.fitolog ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Comercial{" "}
                  {expanded === `panel${index}` ? (
                    acesso.comercial ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.comercial ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Administrativo{" "}
                  {expanded === `panel${index}` ? (
                    acesso.adm ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.adm ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  RH
                  {expanded === `panel${index}` ? (
                    acesso.rh ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.rh ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Técnico{" "}
                  {expanded === `panel${index}` ? (
                    acesso.tecnico ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.tecnico ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Newsis{" "}
                  {expanded === `panel${index}` ? (
                    acesso.newsis ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.newsis ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Marketing{" "}
                  {expanded === `panel${index}` ? (
                    acesso.marketing ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.marketing ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Projetos{" "}
                  {expanded === `panel${index}` ? (
                    acesso.projetos ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.projetos ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>
                <Table>
                  Controle de Gestão{" "}
                  {expanded === `panel${index}` ? (
                    acesso.controle_gestao ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.controle_gestao ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Treinamentos{" "}
                  {expanded === `panel${index}` ? (
                    acesso.treinamentos ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.treinamentos ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  TI{" "}
                  {expanded === `panel${index}` ? (
                    acesso.ti ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.ti ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Temp{" "}
                  {expanded === `panel${index}` ? (
                    acesso.temp ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.temp ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>

                <Table>
                  Franquias{" "}
                  {expanded === `panel${index}` ? (
                    acesso.franquias ? (
                      <SuccessAnimation />
                    ) : (
                      <ErrorAnimation />
                    )
                  ) : acesso.franquias ? (
                    <SuccessAnimation />
                  ) : (
                    <ErrorAnimation />
                  )}
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
      </DocumentsContainer>
    </>
  );
};

export default ListarAcessos;
