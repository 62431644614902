import React, { useState, useEffect } from "react";
import Navbar from "../../../components/navbar/Navbar";

import VerificaLogin from "../../../utils/verificaLogin";
import { VerificaTipoContaRH } from "../../../utils/verificaTipoConta";

import Botao from "../../../styles/Botao";

import TextField from "@mui/material/TextField";

import CardContainer from "../../../styles/CardContainer";
import BodyContainer from "../../../styles/BodyContainer";

import DocumentsContainer from "../../../styles/DocumentsContainer";
import api from "../../../service/api";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import formatDataHorasRH from "../../../utils/formatDataHorasRH";
import formatDataTabelaHorasRH from "../../../utils/formatDataTabelaHorasRH";

import Pagination from "@mui/material/Pagination";
import { Tooltip } from "@mui/material";

import toastError from "../../../utils/toastError";
import LoadingRocket from "../../../components/animations/LoadingRocket";

const GeradorDocumentos = () => {
  VerificaLogin();
  VerificaTipoContaRH();

  const [abreCampos, setAbreCampos] = useState(false);

  const [dateIni, setDateIni] = useState(Date);
  const [dateFim, setDateFim] = useState(Date);

  const [horasTrabalhadasData, setHorasTrabalhadasData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(8);

  const handleChangeDatas = () => {
    setCurrentPage(1);
    setLoading(true);
    api
      .post("/api/buscaHorasTrabalhadasRH", {
        date_ini: formatDataHorasRH(dateIni),
        date_fim: formatDataHorasRH(dateFim),
      })
      .then((response) => {
        setHorasTrabalhadasData(response.data);
        setLoading(false);
        setTimeout(() => {
          window.scrollTo({
            top: 550,
            behavior: "smooth",
          });
        }, 200);
        if (response.data.length === 0) {
          setAbreCampos(false);
          toastError("Horários não encontrados para este período");
        } else {
          setAbreCampos(true);
        }
      });
  };

  const duracao = (tempoFim, tempoInicio) => {
    // Iniciando variaveis de horas iniciais e horas finais
    let inicio = new Date();
    let fim = new Date();

    //Verifica se os parametros estão em string ou date
    if (tempoInicio.substr(2, 1) === "/") {
      inicio = new Date(
        tempoInicio.substr(6, 4) +
          "-" +
          tempoInicio.substr(3, 2) +
          "-" +
          tempoInicio.substr(0, 2) +
          " " +
          tempoInicio.substr(11, 2) +
          ":" +
          tempoInicio.substr(14, 2)
      );

      fim = new Date(
        tempoFim.substr(6, 4) +
          "-" +
          tempoFim.substr(3, 2) +
          "-" +
          tempoFim.substr(0, 2) +
          " " +
          tempoFim.substr(11, 2) +
          ":" +
          tempoFim.substr(14, 2)
      );
    } else {
      inicio = new Date(tempoInicio);
      fim = new Date(tempoFim);
    }

    //Verifica se o tempo inicial é maior que o tempo final
    if (inicio > fim) {
      return (
        <Tooltip
          style={{
            cursor: "pointer",
          }}
          title="O horário inicial é maior que o horário final, assim impossibilitando a contabilização do tempo trabalhado"
        >
          <p
            style={{
              color: "#d13737",
            }}
          >
            Horário inválido
          </p>
        </Tooltip>
      );
    }

    //Calcula tempo de duração do serviço
    const diff = Math.abs(fim.getTime() - inicio.getTime());
    const minutosTotais = diff / 60000;

    const horas = "" + minutosTotais / 60;
    const minutos = minutosTotais % 60;

    const minutosFormat = minutos < 10 ? minutos : minutos;

    return horas < 1 ? (
      <p>{minutosFormat}m</p>
    ) : (
      <p>
        {horas.substr(0, 1)}h {minutosFormat}m
      </p>
    );
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <form onClick={(e) => e.preventDefault()}>
          <BodyContainer>
            <h2>Selecione o Período</h2>

            <LocalizationProvider
              adapterLocale={ptBR}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                minDate={new Date("2015-02-01")}
                maxDate={new Date()}
                views={["month", "year"]}
                label="Mês Inicial"
                value={dateIni}
                onChange={(newValue) => {
                  setDateIni(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Mês/Ano",
                    }}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>

            <LocalizationProvider
              adapterLocale={ptBR}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                minDate={new Date("2015-02-01")}
                maxDate={new Date()}
                views={["month", "year"]}
                label="Mês Final"
                value={dateFim}
                onChange={(newValue) => {
                  setDateFim(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Mês/Ano",
                    }}
                    helperText={null}
                  />
                )}
              />
            </LocalizationProvider>

            <Botao
              type="submit"
              name="Editar"
              value="Buscar horas trabalhadas"
              onClick={handleChangeDatas}
            />
          </BodyContainer>
        </form>
      </CardContainer>
      {abreCampos ? (
        <DocumentsContainer>
          <TableContainer component={Paper}>
            <Table
              size={window.innerWidth > 768 ? "medium" : "small"}
              style={{
                minWidth: 900,
              }}
            >
              <TableHead
                sx={
                  window.innerWidth > 768
                    ? {
                        background: "linear-gradient(45deg, #055f89, #0187c6)",
                      }
                    : {
                        background: "#055f89",
                      }
                }
              >
                <TableRow>
                  <TableCell
                    sx={{
                      width: "8%",
                      color: "#ffffff",
                    }}
                  >
                    <p>Laudo</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "12%",
                      color: "#ffffff",
                    }}
                    align="center"
                  >
                    <p>Início</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "12%",
                      color: "#ffffff",
                    }}
                    align="center"
                  >
                    <p>Fim</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "10%",
                      color: "#ffffff",
                    }}
                    align="center"
                  >
                    <p>Duração</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "12%",
                      color: "#ffffff",
                    }}
                    align="center"
                  >
                    <p>Funcionário</p>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "12%",
                      color: "#ffffff",
                    }}
                    align="center"
                  >
                    <p>Cliente</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {horasTrabalhadasData
                  .slice(indexOfFirstPost, indexOfLastPost)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          <p
                            style={
                              window.innerWidth > 768
                                ? null
                                : {
                                    fontSize: "0.8rem",
                                  }
                            }
                          >
                            {row.cod_lau}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            style={
                              window.innerWidth > 768
                                ? null
                                : {
                                    fontSize: "0.8rem",
                                  }
                            }
                          >
                            {formatDataTabelaHorasRH(row.ini_lau)}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            style={
                              window.innerWidth > 768
                                ? null
                                : {
                                    fontSize: "0.8rem",
                                  }
                            }
                          >
                            {formatDataTabelaHorasRH(row.fim_lau)}
                          </p>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={
                            window.innerWidth > 768
                              ? {
                                  fontFamily: "Poppins",
                                }
                              : {
                                  fontFamily: "Poppins",
                                  fontSize: "0.8rem",
                                }
                          }
                        >
                          {duracao(row.fim_lau, row.ini_lau)}
                        </TableCell>
                        <TableCell align="center">
                          {row.nom_adm.length > 20 ? (
                            <Tooltip title={row.nom_adm.toUpperCase()}>
                              <p
                                style={
                                  ({
                                    cursor: "pointer",
                                  },
                                  window.innerWidth > 768
                                    ? null
                                    : {
                                        fontSize: "0.8rem",
                                      })
                                }
                              >
                                {row.nom_adm.toUpperCase().substring(0, 20)}...
                              </p>
                            </Tooltip>
                          ) : (
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {row.nom_adm.toUpperCase()}
                            </p>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {row.razao_social.length > 20 ? (
                            <Tooltip title={row.razao_social.toUpperCase()}>
                              <p
                                style={
                                  ({
                                    cursor: "pointer",
                                  },
                                  window.innerWidth > 768
                                    ? null
                                    : {
                                        fontSize: "0.8rem",
                                      })
                                }
                              >
                                {row.razao_social
                                  .toUpperCase()
                                  .substring(0, 20)}
                                ...
                              </p>
                            </Tooltip>
                          ) : (
                            <p
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {row.razao_social.toUpperCase()}
                            </p>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{
              display: "flex",
              justifyContent: window.innerWidth > 768 ? "right" : "center",
              alignItems: "center",
              marginTop: "50px",
            }}
            count={Math.ceil(horasTrabalhadasData.length / postsPerPage)}
            color="primary"
            size={window.innerWidth > 768 ? "medium" : "small"}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
            page={currentPage}
          />
        </DocumentsContainer>
      ) : null}
    </>
  );
};

export default GeradorDocumentos;
