import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const VerificaLogin = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies([]);

  useEffect(() => {
    const verifyUser = () => {
      if (!cookies.jwt) {
        navigate("/acessoNegado");
      }
    };
    verifyUser();
  }, [cookies, navigate, removeCookie]);
};

export default VerificaLogin;
