import React from "react";
import Lottie from "lottie-react";
import success from "../../assets/lottie/success.json";

const SuccessAnimation = () => (
  <Lottie
    animationData={success}
    loop={false}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "30px",
      height: "30px",
    }}
  />
);

export default SuccessAnimation;
