import React, { useState } from "react";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaOperacional } from "../../../../utils/verificaTipoConta";
import Navbar from "../../../../components/navbar/Navbar";
import CardContainer from "../../../../styles/CardContainer";
import BodyContainer from "../../../../styles/BodyContainer";
import tipoControleEstoque from "../../../../data/tipoControleEstoque";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Botao from "../../../../styles/Botao";

import api from "../../../../service/api";
import toastError from "../../../../utils/toastError";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DocumentsContainer from "../../../../styles/DocumentsContainer";

import { MdCheckBox } from "react-icons/md";
import toastSuccess from "../../../../utils/toastSuccess";
import LoadingRocket from "../../../../components/animations/LoadingRocket";

const ReativarProdutos = () => {
  const [tipoItem, setTipoItem] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [abreCampos, setAbreCampos] = useState(false);
  const [flagItem, setFlagItem] = useState("");

  const handleChangeTipoItem = (event) => {
    setTipoItem(event.target.value);
  };

  const buscaMovimentacoes = () => {
    setLoading(true);
    if (tipoItem === "Produtos") {
      api.get("/api/buscaProdutosInativos").then((response) => {
        if (response.data.length === 0) {
          setLoading(false);
          setAbreCampos(false);
          return toastError("Não há produtos inativos");
        }
        setData(response.data);
        setLoading(false);
        setAbreCampos(true);
        setFlagItem("Produtos");
        if (!loading) {
          setTimeout(() => {
            window.scrollTo({
              top: 550,
              behavior: "smooth",
            });
          }, 250);
        }
      });
    } else if (tipoItem === "Uniformes e EPI's") {
      api.get("/api/buscaUniformesInativos").then((response) => {
        if (response.data.length === 0) {
          setLoading(false);
          setAbreCampos(false);
          return toastError("Não há uniformes inativos");
        }
        setData(response.data);
        setLoading(false);
        setAbreCampos(true);
        setFlagItem("Uniformes");
        if (!loading) {
          setTimeout(() => {
            window.scrollTo({
              top: 550,
              behavior: "smooth",
            });
          }, 250);
        }
      });
    } else {
      toastError("Selecione um tipo de item");
      setLoading(false);
    }
  };

  VerificaLogin();
  VerificaTipoContaOperacional();
  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <BodyContainer>
          <h2>Reativar Produtos</h2>
          <FormControl>
            <InputLabel>Tipo de Item</InputLabel>
            <Select
              label="Tipo de Item"
              value={tipoItem}
              onChange={handleChangeTipoItem}
            >
              {tipoControleEstoque.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Botao
            type={"submit"}
            value="Buscar Produtos"
            onClick={buscaMovimentacoes}
          />
        </BodyContainer>
      </CardContainer>
      {abreCampos ? (
        flagItem === "Produtos" ? (
          <DocumentsContainer>
            <TableContainer component={Paper}>
              <Table
                size={window.innerWidth > 768 ? "medium" : "small"}
                style={{
                  minWidth: 900,
                }}
              >
                <TableHead
                  sx={
                    window.innerWidth > 768
                      ? {
                          background:
                            "linear-gradient(45deg, #055f89, #0187c6)",
                        }
                      : {
                          background: "#055f89",
                        }
                  }
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "20%",
                        color: "#ffffff",
                      }}
                    >
                      <p>Nome</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Medida</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Status</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Reativar Item</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <p>{row.nome}</p>
                        </TableCell>
                        <TableCell align="center">
                          <p>{row.unidade}</p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            style={{
                              padding: "5px",
                              borderRadius: "5px",
                              background: "#A5A5A5",
                              color: "#ffffff",
                            }}
                          >
                            {row.status}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <MdCheckBox
                            size={25}
                            color={"#3ebf2d"}
                            cursor={"pointer"}
                            onClick={() => {
                              api
                                .post("/api/ativarProduto", {
                                  id_item: row.id,
                                })
                                .then((response) => {
                                  if (
                                    response.data ===
                                    "Status alterado com sucesso"
                                  ) {
                                    buscaMovimentacoes();
                                    return toastSuccess(
                                      "Item reativado com sucesso"
                                    );
                                  } else {
                                    return toastError("Erro ao reativar item");
                                  }
                                });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DocumentsContainer>
        ) : (
          <DocumentsContainer>
            <TableContainer component={Paper}>
              <Table
                size={window.innerWidth > 768 ? "medium" : "small"}
                style={{
                  minWidth: 900,
                }}
              >
                <TableHead
                  sx={
                    window.innerWidth > 768
                      ? {
                          background:
                            "linear-gradient(45deg, #055f89, #0187c6)",
                        }
                      : {
                          background: "#055f89",
                        }
                  }
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "20%",
                        color: "#ffffff",
                      }}
                    >
                      <p>Nome</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Tamanho</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Status</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Reativar Item</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <p>{row.nome}</p>
                        </TableCell>
                        <TableCell align="center">
                          <p>{row.tamanho}</p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            style={{
                              padding: "5px",
                              borderRadius: "5px",
                              background: "#A5A5A5",
                              color: "#ffffff",
                            }}
                          >
                            {row.status}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <MdCheckBox
                            size={25}
                            color={"#3ebf2d"}
                            cursor={"pointer"}
                            onClick={() => {
                              api
                                .post("/api/ativarUniforme", {
                                  id_item: row.id,
                                })
                                .then((response) => {
                                  if (
                                    response.data ===
                                    "Status alterado com sucesso"
                                  ) {
                                    buscaMovimentacoes();
                                    return toastSuccess(
                                      "Item reativado com sucesso"
                                    );
                                  } else {
                                    return toastError("Erro ao reativar item");
                                  }
                                });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DocumentsContainer>
        )
      ) : null}
    </>
  );
};

export default ReativarProdutos;
