const tipoOffice = [
  "Home and Business 2019",
  "Home and Business 2013",
  "Profissional Plus 2016",
  "Home and Business 2010",
  "Particular",
  "Não Tem",
];

export default tipoOffice;
