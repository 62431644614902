import styled from "styled-components";

const Botao = styled.input`
  margin-top: 1.5rem;
  padding: 0.4rem;
  background: #055f89;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 5px;
  transition: all 0.3s ease;
  height: 40px;
  :hover {
    background: #0187c6;
  }
`;

export default Botao;
