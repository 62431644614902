import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";

import VerificaLogin from "../../utils/verificaLogin";
import { VerificaTipoContaComercial } from "../../utils/verificaTipoConta";

const Comercial = () => {
  VerificaLogin();
  VerificaTipoContaComercial();
  return (
    <>
      <Navbar />
      <div className="row">
        <Link to="/comercial/geradorContratos" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Gerador de Contratos"}
            paragrafo={"Gere contratos de forma rápida e fácil"}
          />
        </Link>
        <Link to="/comercial/gerenciarDocumentos" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Gerenciador de Contratos"}
            paragrafo={"Gerencie os dados dos contratos"}
          />
        </Link>
        <Link to="/comercial/avaliacaoComercial" className="link">
          <Card cor={"#F3D62E"} titulo={"Avaliação"} paragrafo={"Avaliação"} />
        </Link>
      </div>
    </>
  );
};

export default Comercial;
