import React from "react";
import Lottie from "lottie-react";
import rocket from "../../assets/lottie/rocket.json";
import { Loading } from "../../styles/Loading";

const LoadingRocket = () => (
  <Loading>
    <Lottie
      animationData={rocket}
      loop={true}
      style={{
        width: "125px",
      }}
    />
  </Loading>
);

export default LoadingRocket;
