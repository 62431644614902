import React from "react";
import styles from "../../styles/notfound.module.css";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import Animation404 from "../../components/animations/Animation404";

const NotFound = () => {
  const [cookies] = useCookies(["jwt"]);

  return (
    <div className={styles.container}>
      <main className={styles.subContainer}>
        <Animation404 />
        <div className={styles.error}>
          <h3>A página que você está procurando não existe.</h3>
          <p>
            Você pode ter digitado incorretamente o endereço ou a página pode
            ter sido removida.
          </p>
        </div>
        <Link to={cookies.jwt ? "/dashboard" : "/"} className={styles.botao}>
          VÁ PARA A PÁGINA INICIAL
        </Link>
      </main>
    </div>
  );
};

export default NotFound;
