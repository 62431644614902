import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import VerificaLogin from "../../../utils/verificaLogin";
import { VerificaTipoContaAdministrador } from "../../../utils/verificaTipoConta";
import { Link } from "react-router-dom";
import Card from "../../../components/card/Card";

const InfosGerais = () => {
  VerificaLogin();
  VerificaTipoContaAdministrador();

  return (
    <>
      <Navbar />
      <div className="row">
        <Link to="/administradores/infosGerais/cadastrarInfo" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Cadastrar Informações Gerais"}
            paragrafo={"Cadastre Informações Gerais"}
          />
        </Link>
        <Link to="/administradores/infosGerais/gerenciarInfos" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Gerenciar Informações Gerais"}
            paragrafo={"Gerencie Informações Gerais"}
          />
        </Link>
      </div>
    </>
  );
};

export default InfosGerais;
