import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import Card from "../../../components/card/Card";

import VerificaLogin from "../../../utils/verificaLogin";
import { VerificaTipoContaOperacional } from "../../../utils/verificaTipoConta";

const AvaliacaoOperacional = () => {
  VerificaLogin();
  VerificaTipoContaOperacional();
  return (
    <>
      <Navbar />
      <div className="row">
        <Card titulo={"Aguarde"} paragrafo={"Ainda estamos em construção ⚙️"} />
      </div>
    </>
  );
};

export default AvaliacaoOperacional;
