import React, { useEffect, useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import DocumentsContainer from "../../../../styles/DocumentsContainer";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaAdministrador } from "../../../../utils/verificaTipoConta";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Botao from "../../../../styles/Botao";

import LoadingRocket from "../../../../components/animations/LoadingRocket";
import { FaUserEdit, FaDirections } from "react-icons/fa";
import api from "../../../../service/api";
import { MdEmail, MdCancel, MdPassword } from "react-icons/md";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import toastSuccess from "../../../../utils/toastSuccess";
import toastError from "../../../../utils/toastError";

import InputAdornment from "@mui/material/InputAdornment";

import orderByASC from "../../../../utils/orderByASC";
import orderByDESC from "../../../../utils/orderByDESC";

import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import BotaoExcluir from "../../../../styles/BotaoExcluir";
import { AiOutlineSearch } from "react-icons/ai";

import Pagination from "@mui/material/Pagination";
import { FormControl, Select, MenuItem } from "@mui/material";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";

import MailAnimation from "../../../../components/animations/MailAnimation";

const ControleEmailNewsis = () => {
  VerificaLogin();
  VerificaTipoContaAdministrador();

  const [loading, setLoading] = useState(true);
  const [listaEmails, setListaEmails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openModalDirecionamento, setOpenModalDirecionamento] = useState(false);
  const [openModalEditar, setOpenModalEditar] = useState(false);
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [direcionamento, setDirecionamento] = useState("");
  const [idEmail, setIdEmail] = useState("");
  const [listaDirecionamentos, setListaDirecionamentos] = useState([]);
  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(20);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const [textFieldAdd, setTextFieldAdd] = useState(1);
  const [object, setObject] = useState("");
  const [concat, setConcat] = useState("");

  const [openArrow, setOpenArrow] = useState({
    email: false,
    senha: false,
  });

  useEffect(() => {
    setConcat(Object.values(object).join("/"));
  }, [object]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    limpaCampos();
  };

  const handleOpenModalDirecionamento = () => setOpenModalDirecionamento(true);
  const handleCloseModalDirecionamento = () => {
    setOpenModalDirecionamento(false);
    limpaCampos();
  };

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    limpaCampos();
  };

  const handleOpenModalEdit = () => setOpenModalEditar(true);
  const handleCloseModalEdit = () => {
    setOpenModalEditar(false);
    limpaCampos();
  };

  const handleEmail = (e) => setEmail(e.target.value);
  const handleSenha = (e) => setSenha(e.target.value);
  const handleDirecionamento = (e) => setDirecionamento(e.target.value);

  const buscaEmails = () => {
    api.get("api/buscarEmailsNewsis").then((response) => {
      setListaEmails(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    buscaEmails();
  }, []);

  const limpaCampos = () => {
    setEmail("");
    setSenha("");
    setDirecionamento("");
    setObject("");
    setConcat("");
    setTextFieldAdd(1);
  };

  const options = [10, 20, 50, 200, 500, 1000];

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    outline: "none",
  };

  const styleDirecionamento = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    outline: "none",
  };

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      {
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={style}>
            <h2>Novo E-mail</h2>
            <TextField
              label="E-mail"
              variant="outlined"
              autoComplete="off"
              value={email}
              onChange={handleEmail}
              sx={{
                width: "100%",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <MdEmail />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Senha"
              variant="outlined"
              autoComplete="off"
              value={senha}
              onChange={handleSenha}
              sx={{
                width: "100%",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <MdPassword />
                  </InputAdornment>
                ),
              }}
            />
            {[...Array(textFieldAdd)].map((name, index) => (
              <TextField
                style={{
                  width: "100%",
                }}
                label="Direcionamento"
                variant="outlined"
                autoComplete="off"
                key={index}
                value={object[index] || ""}
                onChange={(e) => {
                  setObject({ ...object, [index]: e.target.value });
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <FaDirections />
                    </InputAdornment>
                  ),
                }}
              />
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <MdRemoveCircle
                size={25}
                color="#d13737"
                cursor={"pointer"}
                onClick={() => {
                  if (textFieldAdd > 1) {
                    setTextFieldAdd(textFieldAdd - 1);
                    delete object[textFieldAdd - 1];
                    setConcat(Object.values(object).join("/"));
                  }
                }}
              />
              <MdAddCircle
                size={25}
                color="#3992ff"
                cursor={"pointer"}
                onClick={() => {
                  setTextFieldAdd(textFieldAdd + 1);
                }}
              />
            </div>
            <Botao
              type={"submit"}
              value={"Cadastrar"}
              style={{
                width: "100%",
              }}
              onClick={() => {
                if (email === "" || senha === "") {
                  return toastError("Preencha todos os campos");
                }
                handleCloseModal();
                setLoading(true);
                api
                  .post("/api/adicionarEmailNewsis", {
                    email,
                    senha,
                    direcionamento: concat,
                  })
                  .then((response) => {
                    if (response.data.erro) {
                      setLoading(false);
                      return toastError(response.data.erro);
                    }
                    buscaEmails();
                    toastSuccess("E-mail cadastrado com sucesso");
                  });
              }}
            />
          </Box>
        </Modal>
      }
      {
        <Modal open={openDialog} onClose={handleCloseDialog}>
          <Box sx={style}>
            <h2
              style={{
                fontSize: "1.2rem",
              }}
            >
              Tem certeza que deseja excluir este e-mail?
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <BotaoExcluir
                type={"submit"}
                value={"Cancelar"}
                style={{
                  width: "100%",
                }}
                onClick={handleCloseDialog}
              />
              <Botao
                type={"submit"}
                value={"Confirmar"}
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  handleCloseDialog();
                  setLoading(true);
                  api
                    .post("/api/deletarEmailNewsis", {
                      id: idEmail,
                    })
                    .then((response) => {
                      if (response.data.sucesso) {
                        buscaEmails();
                        return toastSuccess(response.data.sucesso);
                      } else {
                        return toastError("Erro ao excluir E-mail");
                      }
                    });
                }}
              />
            </div>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={openModalDirecionamento}
          onClose={handleCloseModalDirecionamento}
        >
          <Box sx={styleDirecionamento}>
            <h2>Lista de Direcionamentos</h2>
            {listaDirecionamentos.length > 0 ? (
              listaDirecionamentos.map((item, index) => (
                <p key={index}>{item}</p>
              ))
            ) : (
              <p>Não há direcionamento cadastrado</p>
            )}
          </Box>
        </Modal>
      }
      {
        <Modal open={openModalEditar} onClose={handleCloseModalEdit}>
          <Box sx={style}>
            <h2>Editar E-mail</h2>
            <TextField
              label="E-mail"
              variant="outlined"
              autoComplete="off"
              value={email}
              onChange={handleEmail}
            />
            <TextField
              label="Senha"
              variant="outlined"
              autoComplete="off"
              value={senha}
              onChange={handleSenha}
            />
            <TextField
              label="Direcionamento"
              variant="outlined"
              autoComplete="off"
              value={direcionamento}
              onChange={handleDirecionamento}
            />
            <Botao
              type={"submit"}
              value={"Confirmar"}
              style={{
                width: "100%",
              }}
              onClick={() => {
                handleCloseModalEdit();
                setLoading(true);
                if (email === "" || senha === "") {
                  return toastError("Preencha todos os campos");
                }
                api
                  .post("/api/editarEmailNewsis", {
                    id: idEmail,
                    email,
                    senha,
                    direcionamento,
                  })
                  .then((response) => {
                    if (response.data.sucesso) {
                      buscaEmails();
                      handleCloseModalEdit();
                      return toastSuccess(response.data.sucesso);
                    } else {
                      return toastError("Erro ao editar E-mail");
                    }
                  });
              }}
            />
          </Box>
        </Modal>
      }
      <Navbar />
      <DocumentsContainer>
        <div
          style={
            window.innerWidth > 768
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "2rem",
                  backgroundColor: "#bdbdbd33",
                  padding: "1rem",
                  borderRadius: "5px",
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                }
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <MailAnimation />
            <h1
              style={
                window.innerWidth > 768
                  ? {
                      color: "#1e1e1e",
                      fontWeight: "500",
                      fontSize: "1.8rem",
                    }
                  : {
                      fontSize: "1.4rem",
                      marginBottom: "1rem",
                    }
              }
            >
              E-mails do Newsis
            </h1>
          </div>

          <div
            style={
              window.innerWidth > 768
                ? {
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    width: "500px",
                  }
                : {
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    width: "100%",
                    marginBottom: "2rem",
                  }
            }
          >
            <TextField
              style={{
                width: "100%",
              }}
              size={"small"}
              variant="outlined"
              placeholder="Buscar E-mail"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AiOutlineSearch />
                  </InputAdornment>
                ),
              }}
            />
            <Botao
              type={"submit"}
              value={"Novo E-mail"}
              style={{
                width: "50%",
                marginTop: "0",
              }}
              onClick={handleOpenModal}
            />
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table
            size={window.innerWidth > 768 ? "medium" : "small"}
            style={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={
                window.innerWidth > 768
                  ? {
                      background: "linear-gradient(45deg, #055f89, #0187c6)",
                    }
                  : {
                      background: "#055f89",
                    }
              }
            >
              <TableRow>
                <TableCell
                  sx={{
                    width: "40%",
                    color: "#ffffff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <p>E-mail</p>
                    {openArrow.email ? (
                      <IoIosArrowUp
                        cursor={"pointer"}
                        size={20}
                        onClick={() => {
                          setOpenArrow({
                            email: false,
                          });
                          orderByASC(listaEmails, "email");
                        }}
                      />
                    ) : (
                      <IoIosArrowDown
                        cursor={"pointer"}
                        size={20}
                        onClick={() => {
                          setOpenArrow({
                            email: true,
                            senha: false,
                          });
                          orderByDESC(listaEmails, "email");
                        }}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    width: "20%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p>Senha</p>
                    {openArrow.senha ? (
                      <IoIosArrowUp
                        cursor={"pointer"}
                        size={20}
                        onClick={() => {
                          setOpenArrow({
                            senha: false,
                          });
                          orderByASC(listaEmails, "senha");
                        }}
                      />
                    ) : (
                      <IoIosArrowDown
                        cursor={"pointer"}
                        size={20}
                        onClick={() => {
                          setOpenArrow({
                            senha: true,
                            email: false,
                          });
                          orderByDESC(listaEmails, "senha");
                        }}
                      />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Direcionamento</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Editar</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Excluir</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listaEmails
                .filter((email) => {
                  if (search === "") {
                    return email;
                  } else if (
                    email.email.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return email;
                  }
                  return null;
                })
                .slice(indexOfFirstPost, indexOfLastPost)
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="center">{row.senha}</TableCell>
                    <TableCell align="center">
                      <FaDirections
                        cursor={"pointer"}
                        size={20}
                        color={"#05628F"}
                        onClick={() => {
                          setListaDirecionamentos(
                            row.direcionamento.split("/").filter((item) => {
                              if (item !== "") {
                                return item;
                              }
                              return null;
                            })
                          );
                          handleOpenModalDirecionamento();
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <FaUserEdit
                        cursor={"pointer"}
                        size={20}
                        color={"#05628F"}
                        onClick={() => {
                          setIdEmail(row.id);
                          setEmail(row.email);
                          setSenha(row.senha);
                          setDirecionamento(row.direcionamento);
                          handleOpenModalEdit();
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <MdCancel
                        cursor={"pointer"}
                        size={20}
                        color={"#da2626"}
                        onClick={() => {
                          setIdEmail(row.id);
                          handleOpenDialog();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div
          style={
            window.innerWidth > 768
              ? {
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 10px",
                }
              : {
                  marginTop: "50px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }
          }
        >
          <FormControl
            size="small"
            sx={{
              width: "100px",
            }}
          >
            <Select
              value={postsPerPage}
              onChange={(e) => {
                setPostsPerPage(e.target.value);
              }}
            >
              {options.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Pagination
            style={
              window.innerWidth > 768
                ? {
                    display: "flex",
                    alignItems: "center",
                  }
                : {
                    marginTop: "30px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }
            }
            count={Math.ceil(
              listaEmails.filter((item) =>
                item.email.toLowerCase().includes(search.toLowerCase())
              ).length / postsPerPage
            )}
            color="primary"
            size={window.innerWidth > 768 ? "medium" : "small"}
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
            page={currentPage}
          />
        </div>
      </DocumentsContainer>
    </>
  );
};

export default ControleEmailNewsis;
