import React, { useState, useEffect } from "react";

// componentes
import Navbar from "../../../components/navbar/Navbar";

// Funções para verificar permissões
import VerificaLogin from "../../../utils/verificaLogin";
import { VerificaTipoContaComercial } from "../../../utils/verificaTipoConta";

// services
import api from "../../../service/api";

// styles
import DocumentsContainer from "../../../styles/DocumentsContainer";
import Botao from "../../../styles/Botao";

// material ui components
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";

// react icons
import { MdDownloadForOffline, MdCancel } from "react-icons/md";
import { FaFileSignature } from "react-icons/fa";
import { RiMailSendFill } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import { AiOutlineCheck } from "react-icons/ai";
import { TiArrowForward } from "react-icons/ti";
import Pagination from "@mui/material/Pagination";

import { MdPersonRemoveAlt1 } from "react-icons/md";

import toastError from "../../../utils/toastError";
import toastSuccess from "../../../utils/toastSuccess";
import LoadingRocket from "../../../components/animations/LoadingRocket";

const GerenciarDocumentos = () => {
  VerificaLogin();
  VerificaTipoContaComercial();

  const [documentosData, setDocumentosData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [idDocumento, setIdDocumento] = useState("");
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [email, setEmail] = useState([]);
  const [signatarios, setSignatarios] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(6);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  useEffect(() => {
    api.get("/api/buscarDocumentosDoCofre").then((response) => {
      setDocumentosData(response.data);
      setLoading(false);
    });
  }, [loading]);

  const downloadWithUrl = async (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "";
    link.click();
  };

  // verifica o statusId e retorna a cor
  const verificaCorDoStatus = (status) => {
    if (status === "1") {
      return "#8cff00";
    } else if (status === "2") {
      return "#f6dd00";
    } else if (status === "3") {
      return "#3992ff";
    } else if (status === "4") {
      return "#1eb300";
    }
  };

  // estilo do modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      {
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box sx={style}>
            <h3
              style={{
                marginBottom: "20px",
              }}
            >
              Cadastrar e-mails das assinaturas
            </h3>
            <TextField
              value={email}
              sx={{
                width: "100%",
              }}
              label="E-mail"
              variant="outlined"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Botao
              type="submit"
              value="Cadastrar E-mail"
              style={{
                width: "100%",
              }}
              onClick={() => {
                if (email.length > 0) {
                  api
                    .post("/api/cadastrarAssinaturaNoDocumento", {
                      id_document: idDocumento,
                      email: email,
                      act: "1",
                      foreign: "1",
                      certificadoicpbr: "0",
                      assinatura_presencial: "0",
                      docauth: "0",
                      docauthandselfie: "0",
                      embed_methodauth: "email",
                      embed_smsnumber: "",
                      upload_allow: "0",
                      upload_obs: "0",
                    })
                    .then(() => {
                      setEmail("");
                      setOpen(false);
                      toastSuccess("E-mail cadastrado com sucesso");
                    });
                } else {
                  toastError("Preencha o campo E-mail");
                }
              }}
            />
          </Box>
        </Modal>
      }
      {
        <Modal
          open={openInfo && !loading}
          onClose={() => {
            setOpenInfo(false);
            setSignatarios([]);
          }}
        >
          <Box sx={style}>
            <h2
              style={{
                marginBottom: "20px",
              }}
            >
              Informações do Documento
            </h2>
            <h4
              style={{
                marginBottom: "20px",
              }}
            >
              E-mails cadastrados:
            </h4>

            {signatarios.map((signatarios, index) =>
              signatarios.list !== null ? (
                signatarios.list.map((signatario, index) => {
                  const verificaCor = () => {
                    if (signatario.signed === "0") {
                      return "#c72424";
                    } else if (signatario.signed === "1") {
                      return "#1eb300";
                    }
                  };
                  const verificaSeJaAssinou = () => {
                    if (signatario.signed === "0") {
                      return (
                        <div>
                          <TiArrowForward
                            style={{
                              marginLeft: "10px",
                            }}
                            cursor={"pointer"}
                            size={20}
                            color={"#05628F"}
                            onClick={() =>
                              api
                                .post("/api/reenviarDocumentoParaAssinar", {
                                  id_doc: signatarios.uuidDoc,
                                  email: signatario.email,
                                })
                                .then(() => {
                                  setOpen(false);
                                  toastSuccess("E-mail reenviado com sucesso");
                                })
                            }
                          />
                          <MdPersonRemoveAlt1
                            style={{
                              marginLeft: "10px",
                            }}
                            cursor={"pointer"}
                            size={20}
                            color={"#c72424"}
                            onClick={() => {
                              setLoading(true);
                              api
                                .post("/api/removerAssinaturaDoDocumento", {
                                  id_doc: signatarios.uuidDoc,
                                  id_assinatura: signatario.key_signer,
                                  email_assinatura: signatario.email,
                                })
                                .then(() => {
                                  setOpenInfo(false);
                                  setSignatarios([]);
                                  toastSuccess("E-mail removido com sucesso");
                                });
                            }}
                          />
                        </div>
                      );
                    } else if (signatario.signed === "1") {
                      return (
                        <AiOutlineCheck
                          style={{
                            marginLeft: "10px",
                          }}
                          size={20}
                          color={"#1eb300"}
                        />
                      );
                    }
                  };

                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      {signatario.email.length > 20 ? (
                        <Tooltip title={signatario.email}>
                          <p
                            style={{
                              color: verificaCor(),
                              cursor: "pointer",
                            }}
                          >
                            {signatario.email.substring(0, 20)}...
                          </p>
                        </Tooltip>
                      ) : (
                        <p
                          style={{
                            color: verificaCor(),
                            cursor: "pointer",
                          }}
                        >
                          {signatario.email}
                        </p>
                      )}
                      {verificaSeJaAssinou()}
                    </div>
                  );
                })
              ) : (
                <p key={index}>Nenhum e-mail cadastrado</p>
              )
            )}
          </Box>
        </Modal>
      }
      <Navbar />
      <DocumentsContainer>
        <TableContainer component={Paper}>
          <Table
            size={window.innerWidth > 768 ? "medium" : "small"}
            style={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={
                window.innerWidth > 768
                  ? {
                      background: "linear-gradient(45deg, #055f89, #0187c6)",
                    }
                  : {
                      background: "#055f89",
                    }
              }
            >
              <TableRow>
                <TableCell
                  sx={{
                    width: "20%",
                    color: "#ffffff",
                  }}
                >
                  <p>Nome</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Status</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Cadastrar Assinatura</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  {window.innerWidth > 768 ? (
                    <p>Enviar e-mail p/ Assinar</p>
                  ) : (
                    <p>Enviar e-mail</p>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Informações</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Download</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                  }}
                  align="center"
                >
                  <p>Cancelar</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentosData
                .filter((doc, index) => {
                  return doc.statusId !== "6" && index !== 0;
                })
                .slice(indexOfFirstPost, indexOfLastPost)
                .map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.nameDoc.length > 25 ? (
                          <Tooltip title={row.nameDoc.toUpperCase()}>
                            <p
                              style={
                                ({
                                  cursor: "pointer",
                                },
                                window.innerWidth > 768
                                  ? null
                                  : { fontSize: "0.8rem" })
                              }
                            >
                              {row.nameDoc.toUpperCase().substring(0, 25)}...
                            </p>
                          </Tooltip>
                        ) : (
                          <p
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {row.nameDoc.toUpperCase()}
                          </p>
                        )}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={
                          window.innerWidth > 768
                            ? null
                            : { fontSize: "0.8rem" }
                        }
                      >
                        {row.statusName.length > 10 ? (
                          <Tooltip title={row.statusName.toUpperCase()}>
                            <p
                              style={{
                                cursor: "pointer",
                                backgroundColor: verificaCorDoStatus(
                                  row.statusId
                                ),
                                color: "#ffffff",
                                borderRadius: "5px",
                                padding: "4px",
                                margin: "0px 15px",
                              }}
                            >
                              {row.statusName.toUpperCase().substring(0, 10)}...
                            </p>
                          </Tooltip>
                        ) : (
                          <p
                            style={{
                              cursor: "pointer",
                              backgroundColor: verificaCorDoStatus(
                                row.statusId
                              ),
                              color: "#ffffff",
                              borderRadius: "5px",
                              padding: "4px",
                              margin: "0px 15px",
                            }}
                          >
                            {row.statusName.toUpperCase()}
                          </p>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <FaFileSignature
                          cursor={"pointer"}
                          size={25}
                          color="#05628F"
                          onClick={() => {
                            setIdDocumento(row.uuidDoc);
                            row.statusName !== "Finalizado"
                              ? setOpen(true)
                              : toastError(
                                  "Não é possível cadastrar uma nova assinatura em um documento finalizado"
                                );
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <RiMailSendFill
                          cursor={"pointer"}
                          size={25}
                          color="#05628F"
                          onClick={async () => {
                            setLoading(true);
                            row.statusName !== "Finalizado"
                              ? await api
                                  .post(`/api/enviarDocumentoParaAssinar`, {
                                    id_document: row.uuidDoc,
                                    message: "Favor, Assinar!",
                                    skip_email: "0",
                                    workflow: "0",
                                  })
                                  .then((res) => {
                                    const verificaSeJaEnviouEmail = () => {
                                      if (
                                        res.data.mensagem_pt ===
                                        "O documento já foi enviado para assinatura."
                                      ) {
                                        toastError(
                                          "O documento já foi enviado para assinatura"
                                        );
                                      } else if (
                                        res.data.mensagem_pt ===
                                        "Documento sem signatários."
                                      ) {
                                        toastError(
                                          "Documento sem signatários cadastrados"
                                        );
                                      } else {
                                        toastSuccess(
                                          "E-mail enviado com sucesso"
                                        );
                                      }
                                    };
                                    verificaSeJaEnviouEmail();
                                  })
                              : toastError(
                                  "Não é possível enviar um e-mail para um documento finalizado"
                                );
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <HiDotsHorizontal
                          cursor={"pointer"}
                          size={25}
                          color="#05628F"
                          onClick={() => {
                            setIdDocumento(row.uuidDoc);
                            setLoading(true);
                            setOpenInfo(true);
                            api
                              .post("/api/listarSignatariosDeDocumento", {
                                id_doc: row.uuidDoc,
                              })
                              .then((res) => {
                                setSignatarios(res.data);
                              });
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <MdDownloadForOffline
                          cursor={"pointer"}
                          size={25}
                          color="#05628F"
                          onClick={async () => {
                            await api
                              .post(`/api/downloadDeDocumento`, {
                                id_doc: row.uuidDoc,
                              })
                              .then((res) => {
                                downloadWithUrl(res.data.url);
                                toastSuccess(
                                  "Fazendo o download do Documento..."
                                );
                              });
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <MdCancel
                          cursor={"pointer"}
                          size={25}
                          color="#da2626"
                          onClick={() => {
                            return row.statusName !== "Finalizado"
                              ? (setLoading(true),
                                api
                                  .post(`/api/cancelarDocumento`, {
                                    id_doc: row.uuidDoc,
                                  })
                                  .then(() => {
                                    toastSuccess(
                                      "Documento cancelado com sucesso"
                                    );
                                  }))
                              : toastError(
                                  "Não é possível cancelar um documento finalizado"
                                );
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            display: "flex",
            justifyContent: window.innerWidth > 768 ? "right" : "center",
            alignItems: "center",
            marginTop: "50px",
          }}
          count={Math.ceil(
            documentosData.filter((item) => item.statusId !== "6").length /
              postsPerPage
          )}
          color="primary"
          size={window.innerWidth > 768 ? "medium" : "small"}
          onChange={(event, value) => {
            setCurrentPage(value);
          }}
          page={currentPage}
        />
      </DocumentsContainer>
    </>
  );
};

export default GerenciarDocumentos;
