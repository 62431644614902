import React, { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";

import VerificaLogin from "../../../utils/verificaLogin";
import { VerificaTipoContaOperacional } from "../../../utils/verificaTipoConta";
import CardContainer from "../../../styles/CardContainer";
import BodyContainer from "../../../styles/BodyContainer";
import DocumentsContainer from "../../../styles/DocumentsContainer";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import api from "../../../service/api";

import Botao from "../../../styles/Botao";
import BotaoExcluir from "../../../styles/BotaoExcluir";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Tooltip } from "@mui/material";

import { HiDotsHorizontal } from "react-icons/hi";

import Modal from "@mui/material/Modal";
import toastError from "../../../utils/toastError";
import toastSuccess from "../../../utils/toastSuccess";

import tipoControleEstoque from "../../../data/tipoControleEstoque";

import TextField from "@mui/material/TextField";
import Pagination from "@mui/material/Pagination";

import { IoMdAddCircle, IoMdRemoveCircle } from "react-icons/io";
import { BiTransfer } from "react-icons/bi";

import orderByASC from "../../../utils/orderByASC";
import orderByDESC from "../../../utils/orderByDESC";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";
import InputAdornment from "@mui/material/InputAdornment";

import formatOnlyNumber from "../../../utils/formatOnlyNumber";

import { MdError } from "react-icons/md";

import BotaoConfirmar from "../../../styles/BotaoConfirmar";
import LoadingRocket from "../../../components/animations/LoadingRocket";

const ControleEstoque = () => {
  VerificaLogin();
  VerificaTipoContaOperacional();

  const [unidadesData, setUnidadesData] = useState([]);
  const [estoqueData, setEstoqueData] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [tipoItem, setTipoItem] = useState("");
  const [tipoItemFlag, setTipoItemFlag] = useState("");
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);
  const [abreCampos, setAbreCampos] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalEntrada, setOpenModalEntrada] = useState(false);
  const [openModalBaixa, setOpenModalBaixa] = useState(false);
  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);

  const [itemSelecionadoProduto, setItemSelecionadoProduto] = useState({});
  const [itemSelecionadoUniforme, setItemSelecionadoUniforme] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(8);

  const [quantidadeProduto, setQuantidadeProduto] = useState("");
  const [notaFiscalProduto, setNotaFiscalProduto] = useState("");

  const [quantidadeUniforme, setQuantidadeUniforme] = useState("");
  const [notaFiscalUniforme, setNotaFiscalUniforme] = useState("");

  const [valorPagoProduto, setValorPagoProduto] = useState(0);
  const [valorPagoUniforme, setValorPagoUniforme] = useState(0);

  const [placasData, setPlacasData] = useState([]);
  const [placa, setPlaca] = useState("");

  const [operadorData, setOperadorData] = useState([]);
  const [operador, setOperador] = useState("");
  const [unidadeDestino, setUnidadeDestino] = useState("");

  const [quantidadeTransferencia, setQuantidadeTransferencia] = useState("");
  const [modalComparaUltimoValorPago, setModalComparaUltimoValorPago] =
    useState(false);

  const [mensagemComparacao, setMensagemComparacao] = useState("");

  const [openArrowProduto, setOpenArrowProduto] = useState({
    nome: false,
    medida: false,
    quantidade: false,
    valor_base: false,
  });

  const [openArrowUniforme, setOpenArrowUniforme] = useState({
    nome: false,
    tamanho: false,
    quantidade: false,
    valor_base: false,
  });

  const clearArrows = () => {
    setOpenArrowProduto({
      nome: false,
      medida: false,
      quantidade: false,
    });
    setOpenArrowUniforme({
      nome: false,
      tamanho: false,
      quantidade: false,
    });
  };

  useEffect(() => {
    setNotaFiscalProduto(formatOnlyNumber(notaFiscalProduto));
  }, [notaFiscalProduto]);

  useEffect(() => {
    setNotaFiscalUniforme(formatOnlyNumber(notaFiscalUniforme));
  }, [notaFiscalUniforme]);

  // função que adiciona zero na casa decimal caso seja necessário
  const addZeroToFinal = (value) => {
    let valueBefore = "";
    let valueAfter = "";
    let boolean = false;

    for (let i = 0; i < value.length; i++) {
      if (value[i] === ",") {
        boolean = true;
      }
      if (boolean) {
        valueBefore = valueBefore + value[i];
      } else {
        valueAfter = valueAfter + value[i];
      }
    }

    if (valueBefore.length === 2) {
      valueBefore = valueBefore + "0";
      value = valueAfter + valueBefore;
    }

    if (valueBefore.length === 0) {
      return (value = value + ",00");
    } else {
      return value;
    }
  };

  const [search, setSearch] = useState("");

  useEffect(() => {
    api.get("/api/buscaUnidades").then((response) => {
      setUnidadesData(response.data);
      setLoading(false);
    });
  }, [loading]);

  useEffect(() => {
    api.get("/api/buscaPlacas").then((response) => {
      setPlacasData(response.data);
    });
  }, []);

  useEffect(() => {
    api
      .post("/api/buscaOperadores", {
        id_unidade: unidade,
      })
      .then((response) => {
        setOperadorData(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (tipoItem === "Produtos") {
      api
        .post("/api/buscaItensEstoqueProdutos", {
          id_unidade: unidade,
        })
        .then((response) => {
          setEstoqueData(response.data);
        });
    } else if (tipoItem === "Uniformes e EPI's") {
      api
        .post("/api/buscaItensEstoqueUniformes", {
          id_unidade: unidade,
        })
        .then((response) => {
          setEstoqueData(response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleChangeUnidade = (event) => {
    setUnidade(event.target.value);
  };

  const handleChangeTipoItem = (event) => {
    setTipoItem(event.target.value);
  };

  const handleChangeUnidadeDestino = (event) => {
    setUnidadeDestino(event.target.value);
  };

  const buscaEstoque = () => {
    if (tipoItem === "Produtos") {
      setLoading(true);
      api
        .post("/api/buscaItensEstoqueProdutos", {
          id_unidade: unidade,
        })
        .then((response) => {
          if (
            response.data.length === 0 ||
            response.data.every((item) => item.status === "INATIVO")
          ) {
            setAbreCampos(false);
            toastError("Não há Produtos cadastrados");
          } else {
            setSearch("");
            setCurrentPage(1);
            setTipoItemFlag("Produtos");
            setEstoqueData(response.data);
            setAbreCampos(true);
            clearArrows();
            if (!loading) {
              setTimeout(() => {
                window.scrollTo({
                  top: 550,
                  behavior: "smooth",
                });
              }, 250);
            }
          }
        });
    } else if (tipoItem === "Uniformes e EPI's") {
      setLoading(true);
      api
        .post("/api/buscaItensEstoqueUniformes", {
          id_unidade: unidade,
        })
        .then((response) => {
          if (
            response.data.length === 0 ||
            response.data.every((item) => item.status === "INATIVO")
          ) {
            setAbreCampos(false);
            toastError("Não há uniformes e EPI's cadastrados");
          } else {
            setSearch("");
            setCurrentPage(1);
            setTipoItemFlag("Uniformes e EPI's");
            setEstoqueData(response.data);
            setAbreCampos(true);
            clearArrows();
            if (!loading) {
              setTimeout(() => {
                window.scrollTo({
                  top: 550,
                  behavior: "smooth",
                });
              }, 250);
            }
          }
        });
    }
  };

  // estilo do modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  return (
    <>
      {loading || loading2 ? <LoadingRocket /> : null}
      {
        <Modal
          open={modalComparaUltimoValorPago}
          onClose={() => {
            setModalComparaUltimoValorPago(false);
          }}
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <MdError size={25} color={"#d13637"} />
            </div>
            <p
              style={{
                textAlign: "center",
              }}
            >
              {mensagemComparacao}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              {tipoItem === "Produtos" ? (
                <BotaoConfirmar
                  type={"submit"}
                  value={"Confirmar"}
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setLoading(true);
                    setLoading2(true);
                    api
                      .post("/api/movimentacaoIncluirProduto", {
                        id_unidade: unidade,
                        id_produto: itemSelecionadoProduto.id,
                        quantidade: quantidadeProduto,
                        n_fiscal: notaFiscalProduto,
                        valor_pago: valorPagoProduto,
                      })
                      .then((response) => {
                        if (response.data.error) {
                          setLoading2(false);
                          setLoading(false);
                          toastError(response.data.error);
                        } else {
                          setLoading2(false);
                          setLoading(false);
                          setModalComparaUltimoValorPago(false);
                          setOpenModalEntrada(false);
                          toastSuccess("Entrada realizada com sucesso");
                          setQuantidadeProduto("");
                          setNotaFiscalProduto("");
                          setValorPagoProduto("");
                        }
                      });
                  }}
                />
              ) : (
                <BotaoConfirmar
                  type={"submit"}
                  value={"Confirmar"}
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setLoading(true);
                    setLoading2(true);
                    api
                      .post("/api/movimentacaoIncluirUniforme", {
                        id_unidade: unidade,
                        id_uniforme: itemSelecionadoUniforme.id,
                        quantidade: quantidadeUniforme,
                        n_fiscal: notaFiscalUniforme,
                        valor_pago: valorPagoUniforme,
                      })
                      .then((response) => {
                        if (response.data.error) {
                          setLoading2(false);
                          setLoading(false);
                          toastError(response.data.error);
                        } else {
                          setLoading2(false);
                          setLoading(false);
                          setModalComparaUltimoValorPago(false);
                          setOpenModalEntrada(false);
                          toastSuccess("Entrada realizada com sucesso");
                          setQuantidadeUniforme("");
                          setNotaFiscalUniforme("");
                          setValorPagoUniforme("");
                        }
                      });
                  }}
                />
              )}
              <BotaoExcluir
                type={"submit"}
                value={"Cancelar"}
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  setModalComparaUltimoValorPago(false);
                }}
              />
            </div>
          </Box>
        </Modal>
      }
      {
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box sx={style}>
            <>
              <h3
                style={{
                  marginBottom: "20px",
                }}
              >
                Editar Item
              </h3>
              {tipoItemFlag === "Produtos" ? (
                <>
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={itemSelecionadoProduto.nome}
                    label="Nome do Produto"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoProduto({
                        ...itemSelecionadoProduto,
                        nome: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={itemSelecionadoProduto.unidade}
                    label="Unidade de Medida"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoProduto({
                        ...itemSelecionadoProduto,
                        unidade: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={itemSelecionadoProduto.quantidade_minima}
                    label="Quantidade Mínima"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoProduto({
                        ...itemSelecionadoProduto,
                        quantidade_minima: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={
                      !itemSelecionadoProduto.valor_base
                        ? ""
                        : itemSelecionadoProduto.valor_base
                            .toString()
                            .split(".")
                            .join(",")
                    }
                    label="Valor Base"
                    placeholder="R$ 00,00"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoProduto({
                        ...itemSelecionadoProduto,
                        valor_base: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                    }}
                    value={
                      itemSelecionadoProduto.cod_barras
                        ? itemSelecionadoProduto.cod_barras
                        : ""
                    }
                    label="Código de Barras"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoProduto({
                        ...itemSelecionadoProduto,
                        cod_barras: e.target.value,
                      })
                    }
                  />
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Editar Item"}
                    onClick={() => {
                      setLoading(true);
                      setOpenModal(false);
                      api
                        .post("/api/atualizarProduto", {
                          id_produto: itemSelecionadoProduto.id,
                          nome: itemSelecionadoProduto.nome,
                          unidade_medida: itemSelecionadoProduto.unidade,
                          quantidade_minima:
                            itemSelecionadoProduto.quantidade_minima,
                          id_unidade: unidade,
                          valor_base: itemSelecionadoProduto.valor_base,
                          cod_barras: itemSelecionadoProduto.cod_barras,
                        })
                        .then((response) => {
                          if (response.data.error) {
                            toastError(response.data.error);
                          } else {
                            toastSuccess(response.data);
                          }
                        });
                    }}
                  />
                  <BotaoExcluir
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Inativar Item"}
                    onClick={() => {
                      setLoading(true);
                      setOpenModal(false);
                      api
                        .post("/api/inativarProduto", {
                          id_produto: itemSelecionadoProduto.id,
                          id_unidade: unidade,
                        })
                        .then(() => {
                          toastSuccess("Produto inativado com sucesso");
                        });
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={itemSelecionadoUniforme.nome}
                    label="Nome do Produto"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoUniforme({
                        ...itemSelecionadoUniforme,
                        nome: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={itemSelecionadoUniforme.tamanho}
                    label="Tamanho"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoUniforme({
                        ...itemSelecionadoUniforme,
                        tamanho: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={itemSelecionadoUniforme.quantidade_minima}
                    label="Quantidade Mínima"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoUniforme({
                        ...itemSelecionadoUniforme,
                        quantidade_minima: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={
                      !itemSelecionadoUniforme.valor_base
                        ? ""
                        : itemSelecionadoUniforme.valor_base
                            .toString()
                            .split(".")
                            .join(",")
                    }
                    label="Valor Base"
                    placeholder="R$ 00,00"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoUniforme({
                        ...itemSelecionadoUniforme,
                        valor_base: e.target.value,
                      })
                    }
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={
                      itemSelecionadoUniforme.cod_barras
                        ? itemSelecionadoUniforme.cod_barras
                        : ""
                    }
                    label="Código de Barras"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) =>
                      setItemSelecionadoUniforme({
                        ...itemSelecionadoUniforme,
                        cod_barras: e.target.value,
                      })
                    }
                  />
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Editar Item"}
                    onClick={() => {
                      setLoading(true);
                      setOpenModal(false);
                      api
                        .post("/api/atualizarUniformes", {
                          id_uniforme: itemSelecionadoUniforme.id,
                          tamanho: itemSelecionadoUniforme.tamanho,
                          nome: itemSelecionadoUniforme.nome,
                          quantidade_minima:
                            itemSelecionadoUniforme.quantidade_minima,
                          valor_base: itemSelecionadoUniforme.valor_base,
                          cod_barras: itemSelecionadoUniforme.cod_barras,
                          id_unidade: unidade,
                        })
                        .then((response) => {
                          if (response.data.error) {
                            toastError(response.data.error);
                          } else {
                            toastSuccess(response.data);
                          }
                        });
                    }}
                  />
                  <BotaoExcluir
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Inativar Item"}
                    onClick={() => {
                      setLoading(true);
                      setOpenModal(false);
                      api
                        .post("/api/inativarUniforme", {
                          id_uniforme: itemSelecionadoUniforme.id,
                          id_unidade: unidade,
                        })
                        .then(() => {
                          toastSuccess("Uniforme / EPI inativado com sucesso");
                        });
                    }}
                  />
                </>
              )}
            </>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={openModalEntrada}
          onClose={() => {
            setOpenModalEntrada(false);
            setQuantidadeProduto("");
            setNotaFiscalProduto("");
            setQuantidadeUniforme("");
            setNotaFiscalUniforme("");
            setValorPagoProduto(0);
            setValorPagoUniforme(0);
          }}
        >
          <Box sx={style}>
            <>
              <h3
                style={{
                  marginBottom: "20px",
                }}
              >
                Entrada
              </h3>
              {tipoItemFlag === "Produtos" ? (
                <>
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={quantidadeProduto}
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setQuantidadeProduto(e.target.value)}
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={!valorPagoProduto ? "" : valorPagoProduto}
                    label="Valor Pago"
                    placeholder="R$ 00,00"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setValorPagoProduto(e.target.value)}
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={formatOnlyNumber(notaFiscalProduto)}
                    label="Nota Fiscal"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setNotaFiscalProduto(e.target.value)}
                  />
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Entrada"}
                    onClick={() => {
                      if (
                        !notaFiscalProduto ||
                        !quantidadeProduto ||
                        !valorPagoProduto
                      ) {
                        toastError("Preencha todos os campos");
                      } else {
                        setLoading(true);
                        setOpenModalEntrada(false);
                        api
                          .post("/api/comparaValoresDeCompraProduto", {
                            valor_pago: valorPagoProduto,
                            id_produto: itemSelecionadoProduto.id,
                          })
                          .then((response) => {
                            if (response.data !== true) {
                              setLoading(false);
                              setMensagemComparacao(response.data);
                              return setModalComparaUltimoValorPago(true);
                            } else {
                              api
                                .post("/api/movimentacaoIncluirProduto", {
                                  id_produto: itemSelecionadoProduto.id,
                                  id_unidade: unidade,
                                  quantidade: quantidadeProduto,
                                  n_fiscal: notaFiscalProduto,
                                  valor_pago: valorPagoProduto,
                                })
                                .then((response) => {
                                  if (response.data.error) {
                                    setQuantidadeProduto("");
                                    setNotaFiscalProduto("");
                                    setValorPagoProduto(0);
                                    toastError(response.data.error);
                                  } else {
                                    setQuantidadeProduto("");
                                    setNotaFiscalProduto("");
                                    setValorPagoProduto(0);
                                    toastSuccess(
                                      "Entrada de produto realizada com sucesso"
                                    );
                                  }
                                });
                            }
                          });
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={quantidadeUniforme}
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setQuantidadeUniforme(e.target.value)}
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={!valorPagoUniforme ? "" : valorPagoUniforme}
                    label="Valor Pago"
                    placeholder="R$ 00,00"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setValorPagoUniforme(e.target.value)}
                  />
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={formatOnlyNumber(notaFiscalUniforme)}
                    label="Nota Fiscal"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setNotaFiscalUniforme(e.target.value)}
                  />
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Entrada"}
                    onClick={() => {
                      if (
                        !notaFiscalUniforme ||
                        !quantidadeUniforme ||
                        !valorPagoUniforme
                      ) {
                        toastError("Preencha todos os campos");
                      } else {
                        setLoading(true);
                        setOpenModalEntrada(false);
                        api
                          .post("/api/comparaValoresDeCompraUniforme", {
                            valor_pago: valorPagoUniforme,
                            id_uniforme: itemSelecionadoUniforme.id,
                          })
                          .then((response) => {
                            if (response.data !== true) {
                              setLoading(false);
                              setMensagemComparacao(response.data);
                              return setModalComparaUltimoValorPago(true);
                            } else {
                              api
                                .post("/api/movimentacaoIncluirUniforme", {
                                  id_uniforme: itemSelecionadoUniforme.id,
                                  id_unidade: unidade,
                                  quantidade: quantidadeUniforme,
                                  n_fiscal: notaFiscalUniforme,
                                  valor_pago: valorPagoUniforme,
                                })
                                .then((response) => {
                                  if (response.data.error) {
                                    setQuantidadeUniforme("");
                                    setNotaFiscalUniforme("");
                                    setValorPagoUniforme(0);
                                    toastError(response.data.error);
                                  } else {
                                    setQuantidadeUniforme("");
                                    setNotaFiscalUniforme("");
                                    setValorPagoUniforme(0);
                                    toastSuccess(
                                      "Entrada de produto realizada com sucesso"
                                    );
                                  }
                                });
                            }
                          });
                      }
                    }}
                  />
                </>
              )}
            </>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={openModalBaixa}
          onClose={() => {
            setOpenModalBaixa(false);
            setQuantidadeProduto("");
            setQuantidadeUniforme("");
            setPlaca("");
            setOperador("");
          }}
        >
          <Box sx={style}>
            <>
              <h3
                style={{
                  marginBottom: "20px",
                }}
              >
                Baixa
              </h3>
              {tipoItemFlag === "Produtos" ? (
                <>
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={quantidadeProduto}
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setQuantidadeProduto(e.target.value)}
                  />
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <InputLabel>Placa do Carro</InputLabel>
                    <Select
                      value={placa}
                      label="Placa do Carro"
                      onChange={(e) => setPlaca(e.target.value)}
                    >
                      {placasData
                        .filter((placas) => placas.placa !== "-")
                        .map((placas, index) => (
                          <MenuItem key={index} value={placas.id}>
                            {placas.placa}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Baixa"}
                    onClick={() => {
                      if (!quantidadeProduto || !placa) {
                        toastError("Preencha todos os campos");
                      } else {
                        setLoading(true);
                        setOpenModalBaixa(false);
                        if (
                          quantidadeProduto > itemSelecionadoProduto.quantidade
                        ) {
                          setQuantidadeProduto("");
                          setPlaca("");
                          toastError(
                            "Quantidade de produto maior que a disponível"
                          );
                        } else {
                          api
                            .post("/api/movimentacaoDarBaixaProduto", {
                              id_produto: itemSelecionadoProduto.id,
                              id_unidade: unidade,
                              quantidade: quantidadeProduto,
                              id_placa: placa,
                            })
                            .then((response) => {
                              if (response.data.error) {
                                setQuantidadeProduto("");
                                setPlaca("");
                                toastError(response.data.error);
                              } else {
                                if (
                                  itemSelecionadoProduto.quantidade -
                                    quantidadeProduto <
                                  itemSelecionadoProduto.quantidade_minima
                                ) {
                                  api.post("/api/enviarEmailOperacional", {
                                    produto: itemSelecionadoProduto.nome,
                                    unidade: itemSelecionadoProduto.unidade,
                                  });
                                }
                                setQuantidadeProduto("");
                                setPlaca("");
                                toastSuccess(
                                  "Baixa de produto realizada com sucesso"
                                );
                              }
                            });
                        }
                      }
                    }}
                  />
                </>
              ) : (
                <>
                  <TextField
                    sx={{
                      width: "100%",
                      marginBottom: "10px",
                    }}
                    value={quantidadeUniforme}
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setQuantidadeUniforme(e.target.value)}
                  />
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <InputLabel>Operador</InputLabel>
                    <Select
                      value={operador}
                      label="Operador"
                      onChange={(e) => setOperador(e.target.value)}
                    >
                      {operadorData
                        .filter(
                          (operadores) =>
                            operadores.nome !== "Nenhum operador" &&
                            operadores.nome !== "-"
                        )
                        .map((operadores, index) => (
                          <MenuItem key={index} value={operadores.id}>
                            {operadores.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Baixa"}
                    onClick={() => {
                      if (!quantidadeUniforme || !operador) {
                        toastError("Preencha todos os campos");
                      } else {
                        setLoading(true);
                        setOpenModalBaixa(false);
                        if (
                          quantidadeUniforme >
                          itemSelecionadoUniforme.quantidade
                        ) {
                          setQuantidadeUniforme("");
                          setOperador("");
                          toastError(
                            "Quantidade de uniforme maior que a disponível"
                          );
                        } else {
                          api
                            .post("/api/movimentacaoDarBaixaUniforme", {
                              id_uniforme: itemSelecionadoUniforme.id,
                              id_unidade: unidade,
                              quantidade: quantidadeUniforme,
                              id_operador: operador,
                            })
                            .then((response) => {
                              if (response.data.error) {
                                setQuantidadeUniforme("");
                                setOperador("");
                                toastError(response.data.error);
                              } else {
                                if (
                                  itemSelecionadoUniforme.quantidade -
                                    quantidadeUniforme <
                                  itemSelecionadoUniforme.quantidade_minima
                                ) {
                                  api.post("/api/enviarEmailOperacional", {
                                    produto: itemSelecionadoUniforme.nome,
                                    unidade: itemSelecionadoUniforme.tamanho,
                                  });
                                }
                                setQuantidadeUniforme("");
                                setOperador("");
                                toastSuccess(
                                  "Baixa de uniforme realizada com sucesso"
                                );
                              }
                            });
                        }
                      }
                    }}
                  />
                </>
              )}
            </>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={openModalTransferencia}
          onClose={() => {
            setOpenModalTransferencia(false);
            setQuantidadeTransferencia("");
            setUnidadeDestino("");
          }}
        >
          <Box sx={style}>
            <>
              <h3
                style={{
                  marginBottom: "20px",
                }}
              >
                Transferência entre unidades
              </h3>
              {tipoItemFlag === "Produtos" ? (
                <>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <InputLabel>Unidades</InputLabel>
                    <Select
                      value={unidadeDestino}
                      label="Assinatura"
                      onChange={handleChangeUnidadeDestino}
                    >
                      {unidadesData
                        .filter((unidadesparam) => unidade !== unidadesparam.id)
                        .map((unidades, index) => (
                          <MenuItem key={index} value={unidades.id}>
                            {unidades.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    value={quantidadeTransferencia}
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setQuantidadeTransferencia(e.target.value)}
                  />
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Transferir"}
                    onClick={() => {
                      setLoading2(true);
                      setOpenModalTransferencia(false);
                      if (
                        quantidadeTransferencia >
                        itemSelecionadoProduto.quantidade
                      ) {
                        toastError(
                          "Quantidade de produto maior que a disponível"
                        );
                      } else {
                        api
                          .post("/api/transicaoEntreUnidadesProduto", {
                            id_produto: itemSelecionadoProduto.id,
                            remetente: unidade,
                            destinatario: unidadeDestino,
                            quantidade: quantidadeTransferencia,
                          })
                          .then((response) => {
                            setLoading(true);
                            if (!response.data.error) {
                              toastSuccess(
                                "Transferência de produto realizada com sucesso"
                              );
                            } else {
                              toastError(response.data.error);
                            }
                            setLoading2(false);
                          });
                      }
                      setLoading(false);
                      setQuantidadeTransferencia("");
                      setUnidadeDestino("");
                    }}
                  />
                </>
              ) : (
                <>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <InputLabel>Unidades</InputLabel>
                    <Select
                      value={unidadeDestino}
                      label="Assinatura"
                      onChange={handleChangeUnidadeDestino}
                    >
                      {unidadesData
                        .filter((unidadesparam) => unidade !== unidadesparam.id)
                        .map((unidades, index) => (
                          <MenuItem key={index} value={unidades.id}>
                            {unidades.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    value={quantidadeTransferencia}
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(e) => setQuantidadeTransferencia(e.target.value)}
                  />
                  <Botao
                    style={{
                      width: "100%",
                    }}
                    type="submit"
                    value={"Transferir"}
                    onClick={() => {
                      setLoading2(true);
                      setOpenModalTransferencia(false);
                      if (
                        quantidadeTransferencia >
                        itemSelecionadoUniforme.quantidade
                      ) {
                        toastError(
                          "Quantidade de uniforme maior que a disponível"
                        );
                        setLoading2(false);
                      } else {
                        api
                          .post("/api/transicaoEntreUnidadesUniforme", {
                            id_uniforme: itemSelecionadoUniforme.id,
                            remetente: unidade,
                            destinatario: unidadeDestino,
                            quantidade: quantidadeTransferencia,
                          })
                          .then((response) => {
                            setLoading(true);
                            if (!response.data.error) {
                              toastSuccess(
                                "Transferência de uniforme realizada com sucesso"
                              );
                            } else {
                              toastError(response.data.error);
                            }
                            setLoading2(false);
                          });
                      }
                      setLoading(false);
                      setQuantidadeTransferencia("");
                      setUnidadeDestino("");
                    }}
                  />
                </>
              )}
            </>
          </Box>
        </Modal>
      }
      <Navbar />
      <CardContainer>
        <form onClick={(e) => e.preventDefault()}>
          <BodyContainer>
            <h2>Selecione a Unidade</h2>
            <FormControl>
              <InputLabel>Unidade</InputLabel>
              <Select
                label="Unidade"
                value={unidade}
                onChange={handleChangeUnidade}
              >
                {unidadesData.map((unidades, index) => (
                  <MenuItem key={index} value={unidades.id}>
                    {unidades.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Tipo de Item</InputLabel>
              <Select
                label="Tipo de Item"
                value={tipoItem}
                onChange={handleChangeTipoItem}
              >
                {tipoControleEstoque.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Botao
              type={"submit"}
              value="Buscar Itens"
              onClick={() => {
                buscaEstoque();
              }}
            />
          </BodyContainer>
        </form>
      </CardContainer>
      {abreCampos ? (
        <DocumentsContainer>
          {tipoItemFlag === "Produtos" ? (
            <div
              style={
                window.innerWidth > 768
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "40px",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "center",
                      marginBottom: "20px",
                    }
              }
            >
              <TextField
                variant="outlined"
                placeholder="Busca"
                value={search}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearch(e.target.value);
                }}
                style={
                  window.innerWidth > 768 ? { width: "20%" } : { width: "100%" }
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
              />
              <p
                style={
                  window.innerWidth > 768
                    ? {
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                      }
                    : {
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                        marginBottom: "20px",
                      }
                }
              >
                Total de produtos:{" "}
                {search
                  ? estoqueData
                      .filter((item) => item.status !== "INATIVO")
                      .filter((produto) => {
                        if (
                          produto.nome
                            .toUpperCase()
                            .includes(search.toUpperCase()) ||
                          produto.unidade
                            .toUpperCase()
                            .includes(search.toUpperCase()) ||
                          produto.quantidade
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())
                        ) {
                          return produto;
                        } else {
                          return null;
                        }
                      }).length
                  : estoqueData.filter((item) => item.status !== "INATIVO")
                      .length}
              </p>
            </div>
          ) : (
            <div
              style={
                window.innerWidth > 768
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "40px",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "center",
                      marginBottom: "20px",
                    }
              }
            >
              <TextField
                variant="outlined"
                placeholder="Busca"
                value={search}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearch(e.target.value);
                }}
                style={
                  window.innerWidth > 768 ? { width: "20%" } : { width: "100%" }
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
              />
              <p
                style={
                  window.innerWidth > 768
                    ? {
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                      }
                    : {
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                        marginBottom: "20px",
                      }
                }
              >
                Total de uniformes e EPI'S:{" "}
                {search
                  ? estoqueData
                      .filter((item) => item.status !== "INATIVO")
                      .filter((produto) => {
                        if (
                          produto.nome
                            .toUpperCase()
                            .includes(search.toUpperCase()) ||
                          produto.tamanho
                            .toUpperCase()
                            .includes(search.toUpperCase()) ||
                          produto.quantidade
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())
                        ) {
                          return produto;
                        } else {
                          return null;
                        }
                      }).length
                  : estoqueData.filter((item) => item.status !== "INATIVO")
                      .length}
              </p>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table size="medium" aria-label="a dense table">
              {tipoItemFlag === "Produtos" ? (
                <TableHead
                  sx={
                    window.innerWidth > 768
                      ? {
                          background:
                            "linear-gradient(45deg, #055f89, #0187c6)",
                        }
                      : {
                          background: "#055f89",
                        }
                  }
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "20%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <p>Tipo</p>
                        {openArrowProduto.nome ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                              });
                              orderByASC(estoqueData, "nome");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: true,
                                medida: false,
                                quantidade: false,
                                valor_base: false,
                              });
                              orderByDESC(estoqueData, "nome");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Medida</p>
                        {openArrowProduto.medida ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                medida: false,
                              });
                              orderByASC(estoqueData, "unidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: true,
                                quantidade: false,
                                valor_base: false,
                              });
                              orderByDESC(estoqueData, "unidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Quantidade</p>
                        {openArrowProduto.quantidade ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                quantidade: false,
                              });
                              orderByASC(estoqueData, "quantidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: true,
                                valor_base: false,
                              });
                              orderByDESC(estoqueData, "quantidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Valor</p>
                        {openArrowProduto.valor_base ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                valor_base: false,
                              });
                              orderByASC(estoqueData, "valor_base");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                valor_base: true,
                              });
                              orderByDESC(estoqueData, "valor_base");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Entrada</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Baixa</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Transferir</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Editar Item</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : (
                <TableHead
                  sx={
                    window.innerWidth > 768
                      ? {
                          background:
                            "linear-gradient(45deg, #055f89, #0187c6)",
                        }
                      : {
                          background: "#055f89",
                        }
                  }
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "20%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <p>Nome</p>
                        {openArrowUniforme.nome ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                              });
                              orderByASC(estoqueData, "nome");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: true,
                                medida: false,
                                quantidade: false,
                                valor_base: false,
                              });
                              orderByDESC(estoqueData, "nome");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Tamanho</p>
                        {openArrowUniforme.tamanho ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                tamanho: false,
                              });
                              orderByASC(estoqueData, "tamanho");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: true,
                                quantidade: false,
                                valor_base: false,
                              });
                              orderByDESC(estoqueData, "tamanho");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Quantidade</p>
                        {openArrowUniforme.quantidade ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                quantidade: false,
                              });
                              orderByASC(estoqueData, "quantidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: false,
                                quantidade: true,
                                valor_base: false,
                              });
                              orderByDESC(estoqueData, "quantidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Valor</p>
                        {openArrowUniforme.valor_base ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                valor_base: false,
                              });
                              orderByASC(estoqueData, "valor_base");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                valor_base: true,
                              });
                              orderByDESC(estoqueData, "valor_base");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Entrada</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Baixa</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Transferir</p>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <p>Editar Item</p>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {estoqueData
                  .filter((item) => item.status !== "INATIVO")
                  .filter((produto) => {
                    if (search === "") {
                      return produto;
                    } else if (tipoItemFlag === "Produtos") {
                      if (
                        produto.nome
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.valor_base
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                      ) {
                        return produto;
                      } else {
                        return null;
                      }
                    } else if (tipoItemFlag === "Uniformes e EPI's") {
                      if (
                        produto.nome
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.tamanho
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.valor_base
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                      ) {
                        return produto;
                      } else {
                        return null;
                      }
                    } else {
                      return null;
                    }
                  })
                  .slice(indexOfFirstPost, indexOfLastPost)
                  .map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {tipoItemFlag === "Produtos" ? (
                          <>
                            <TableCell
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              {row.nome.length > 20 ? (
                                <Tooltip title={row.nome}>
                                  <p>{row.nome.substring(0, 20)}...</p>
                                </Tooltip>
                              ) : (
                                <p>{row.nome}</p>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              <p>{row.unidade}</p>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              <p>{row.quantidade}</p>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              {!row.valor_base ? (
                                <p>-</p>
                              ) : (
                                <p>
                                  R${" "}
                                  {addZeroToFinal(
                                    row.valor_base
                                      .toString()
                                      .split(".")
                                      .join(",")
                                  )}
                                </p>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IoMdAddCircle
                                size={20}
                                cursor="pointer"
                                color={"#3ebf2d"}
                                onClick={() => {
                                  setItemSelecionadoProduto(row);
                                  setOpenModalEntrada(true);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <IoMdRemoveCircle
                                size={20}
                                cursor="pointer"
                                color={"#d13737"}
                                onClick={() => {
                                  setItemSelecionadoProduto(row);
                                  setOpenModalBaixa(true);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <BiTransfer
                                size={20}
                                cursor="pointer"
                                color={"#05628f"}
                                onClick={() => {
                                  setItemSelecionadoProduto(row);
                                  setOpenModalTransferencia(true);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <HiDotsHorizontal
                                size={20}
                                cursor="pointer"
                                onClick={() => {
                                  setItemSelecionadoProduto(row);
                                  setOpenModal(true);
                                }}
                              />
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              {row.nome.length > 20 ? (
                                <Tooltip title={row.nome}>
                                  <p>{row.nome.substring(0, 20)}...</p>
                                </Tooltip>
                              ) : (
                                <p>{row.nome}</p>
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              <p>{row.tamanho}</p>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              <p>{row.quantidade}</p>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                color:
                                  row.quantidade <= row.quantidade_minima
                                    ? "#d13737"
                                    : "black",
                              }}
                            >
                              {!row.valor_base ? (
                                <p>-</p>
                              ) : (
                                <p>
                                  R${" "}
                                  {addZeroToFinal(
                                    row.valor_base
                                      .toString()
                                      .split(".")
                                      .join(",")
                                  )}
                                </p>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IoMdAddCircle
                                size={20}
                                cursor="pointer"
                                color={"#3ebf2d"}
                                onClick={() => {
                                  setItemSelecionadoUniforme(row);
                                  setOpenModalEntrada(true);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <IoMdRemoveCircle
                                size={20}
                                cursor="pointer"
                                color={"#d13737"}
                                onClick={() => {
                                  setItemSelecionadoUniforme(row);
                                  setOpenModalBaixa(true);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <BiTransfer
                                size={20}
                                cursor="pointer"
                                color={"#05628f"}
                                onClick={() => {
                                  setItemSelecionadoUniforme(row);
                                  setOpenModalTransferencia(true);
                                }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <HiDotsHorizontal
                                size={20}
                                cursor="pointer"
                                onClick={() => {
                                  setItemSelecionadoUniforme(row);
                                  setOpenModal(true);
                                }}
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              marginTop: "50px",
            }}
            count={Math.ceil(
              tipoItemFlag === "Produtos"
                ? estoqueData
                    .filter((item) => item.status !== "INATIVO")
                    .filter(
                      (produto) =>
                        produto.nome
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        produto.valor_base
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                    ).length / postsPerPage
                : estoqueData
                    .filter((item) => item.status !== "INATIVO")
                    .filter(
                      (uniforme) =>
                        uniforme.nome
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        uniforme.tamanho
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        uniforme.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        uniforme.valor_base
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                    ).length / postsPerPage
            )}
            color="primary"
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
            page={currentPage}
          />
        </DocumentsContainer>
      ) : null}
    </>
  );
};

export default ControleEstoque;
