const tipoEmpresa = [
  "Unicontrol",
  "Fitolog",
  "Labs",
  "Newsis",
  "ECORE",
  "Corelog",
];

tipoEmpresa.sort();

export default tipoEmpresa;
