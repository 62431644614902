import React, { useState, useEffect } from "react";
import VerificaLogin from "../../../../utils/verificaLogin";
import Navbar from "../../../../components/navbar/Navbar";
import CardContainer from "../../../../styles/CardContainer";
import BodyContainer from "../../../../styles/BodyContainer";
import formatCPF from "../../../../utils/formatCPF";
import Botao from "../../../../styles/Botao";
import { useNavigate } from "react-router-dom";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Material UI Date-Picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ptBR } from "date-fns/locale";
import formatCEP from "../../../../utils/formatCEP";
import formatPIS from "../../../../utils/formatPIS";
import api from "../../../../service/api";
import formatMoney from "../../../../utils/formatMoney";
import { VerificaTipoContaRH } from "../../../../utils/verificaTipoConta";

import toastError from "../../../../utils/toastError";
import toastSuccess from "../../../../utils/toastSuccess";

const listaDeCargos = [
  "Agrônomo Júnior",
  "Agrônomo Pleno",
  "Analista Administrativo",
  "Assistente de Atendimento",
  "Analista de Marketing",
  "Analista de Negócios",
  "Analista de RH",
  "Analista de Vendas",
  "Aprendiz",
  "Assistente Administrativo",
  "Assistente de RH",
  "Assistente de Vendas",
  "Autônomo",
  "Auxiliar ADM Operacional",
  "Auxiliar Administrativo",
  "Auxiliar Administrativo Financeiro",
  "Auxiliar Contábil",
  "Auxiliar de Marketing",
  "Auxiliar de Vendas",
  "Bióloga Júnior",
  "Bióloga Pleno",
  "Bióloga Sênior",
  "Consultor de Processos",
  "Controlador de Pragas",
  "Controlador de Pragas Júnior",
  "Controlador de Pragas Master",
  "Controlador de Pragas Pleno",
  "Controlador de Pragas Sênior",
  "Desenvolvedor de TI",
  "Diretor - Presidente",
  "Engenheiro Agrônomo",
  "Estagiário",
  "Gerente Comercial",
  "Gerente de Unidade",
  "Gerente Geral",
  "Representante Comercial",
  "Supervisor de Vendas",
  "Supervisor Técnico",
  "Técnico em Manutenção",
  "Técnico de Segurança do Trabalho",
  "Agenciador de propaganda",
  "Assistente de DP",
  "Analista de DP",
  "Auxiliar de DP",
];

const listaDeEscolaridades = [
  "Analfabeto",
  "Até quarta série incompleta (primário incompleto)",
  "Com quarta série completa (primário completo)",
  "Primeiro grau incompleto",
  "Primeiro grau completo",
  "Segundo grau incompleto",
  "Segundo grau completo",
  "Superior incompleto",
  "Superior completo",
  "Mestrado completo",
  "Doutorado completo",
  "Pós-graduação/especialização",
  "Pós-doutorado",
];

const listaDeEstadoCivil = [
  "Solteiro",
  "Casado",
  "Separado",
  "Divorciado",
  "Viúvo",
];

const listaDeNacionalidades = [
  "Brasileiro",
  "Argentino",
  "Uruguaio",
  "Angolano",
];

const listaDeNiveis = [
  "Agenciador de propaganda",
  "Agrônomo Júnior",
  "Agrônomo Pleno",
  "Analista Administrativo",
  "Analista de RH",
  "Analista de Vendas",
  "Assistente Administrativo",
  "Assistente de Vendas",
  "Assistente de RH",
  "Aprendiz",
  "Auxiliar Administrativo",
  "Auxiliar Administrativo Financeiro",
  "Auxiliar Administrativo/Operacional",
  "Auxiliar Contábil",
  "Auxiliar de Marketing",
  "Auxiliar de Vendas",
  "Biologa Júnior",
  "Biologa Pleno",
  "Biologa Sênior",
  "Consultor Comercial",
  "Consultor de Processos",
  "Consultor de RH",
  "Controlador de Pragas",
  "Controlador de Pragas Júnior",
  "Controlador de Pragas Master",
  "Controlador de Pragas Pleno",
  "Controlador de Pragas Sênior",
  "Diretor-Presidente",
  "Diretor",
  "Engenheiro Agrônomo",
  "Estagiário",
  "Analista de Negócios",
  "Gerente Comercial",
  "Gerente de Unidade",
  "Gerente Geral",
  "Supervisor de Vendas",
  "Supervisor Técnico",
  "Técnico de Segurança do Trabalho",
];

const listaDeDepartamentos = [
  "Operacional",
  "Técnico",
  "Administrativo",
  "RH",
  "Comercial",
  "Gerencial",
  "Administrativo-Operacional",
  "Afastado",
];

const listaDeEmpresas = [
  "UNICONTROL CONTROLE DE PRAGAS LTDA",
  "UNICONTROL CONTROLE DE PRAGAS S/A - FILIAL FARROUPILHA",
  "FITOLOG LICENCIAMENTO DE FRANQUIAS LTDA",
  "NEWSIS SISTEMAS E SERVIÇOS DE INTERNET LTDA",
];

const listaCentroDeCusto = [
  "OPERACIONAL CANOAS",
  "COMERCIAL CANOAS",
  "ADMINISTRATIVO CANOAS",
  "DIREÇÃO",
  "OPERCIONAL FARROUPILHA",
  "COMERCIAL FARROUPILHA",
  "ADMINISTRATIVO FARROUPILHA",
  "ADMINISTRATIVO GERAL",
  "OPERCIONAL GERAL",
  "COMERCIAL GERAL",
];

// Ordenando por ordem alfabética
listaDeCargos.sort();
listaDeEscolaridades.sort();
listaDeEstadoCivil.sort();
listaDeNacionalidades.sort();
listaDeNiveis.sort();
listaDeDepartamentos.sort();
listaDeEmpresas.sort();
listaCentroDeCusto.sort();

const CadastrarFuncionario = () => {
  VerificaLogin();
  VerificaTipoContaRH();
  const navigate = useNavigate();
  const [nome, setNome] = useState("");
  const [dataNascimento, setDataNascimento] = useState(null);
  const [cpf, setCpf] = useState("");
  const [ctps, setCtps] = useState("");
  const [serie, setSerie] = useState("");
  const [cargo, setCargo] = useState("");
  const [cbo, setCbo] = useState("");
  const [escolaridade, setEscolaridade] = useState("");
  const [estadoCivil, setEstadoCivil] = useState("");
  const [nacionalidade, setNacionalidade] = useState("Brasileiro");
  const [pis, setPis] = useState("");
  const [rg, setRg] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [cep, setCep] = useState("");
  const [nivel, setNivel] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [centroDeCusto, setCentroDeCusto] = useState("");
  const [dataAdmissao, setDataAdmissao] = useState(null);
  const [dataDemissao, setDataDemissao] = useState(null);
  const [salarioInicial, setSalarioInicial] = useState("");
  const [salarioAtual, setSalarioAtual] = useState("");
  const [verificaCPF, setVerificaCPF] = useState(false);

  useEffect(() => {
    setCpf(formatCPF(cpf));
  }, [cpf]);

  useEffect(() => {
    setCep(formatCEP(cep));
  }, [cep]);

  useEffect(() => {
    setPis(formatPIS(pis));
  }, [pis]);

  useEffect(() => {
    setSalarioInicial(formatMoney(salarioInicial));
  }, [salarioInicial]);

  useEffect(() => {
    setSalarioAtual(formatMoney(salarioAtual));
  }, [salarioAtual]);

  useEffect(() => {
    if (cpf.length === 14) {
      api.post("/api/validarCpfCnpj", { cpf }).then((response) => {
        setVerificaCPF(response.data);
      });
    }
  }, [cpf]);

  useEffect(() => {
    if (cep.length === 9) {
      api.post("/api/buscaCep", { cep }).then((response) => {
        try {
          if (response) {
            setRua(response.data.logradouro);
            setBairro(response.data.bairro);
            setCidade(response.data.localidade);
            setUf(response.data.uf);
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }, [cep]);

  useEffect(
    () => {
      if (cep.length !== 9) {
        setRua("");
        setBairro("");
        setCidade("");
        setUf("");
      }
    },
    [cep],
    [rua],
    [bairro],
    [cidade],
    [uf]
  );

  const handleChangeNome = (event) => {
    setNome(event.target.value);
  };

  const handleChangeDataNascimento = (date) => {
    setDataNascimento(date);
  };

  const handleChangeCPF = (event) => {
    setCpf(event.target.value);
  };

  const handleChangeCTPS = (event) => {
    setCtps(event.target.value);
  };

  const handleChangeSerie = (event) => {
    setSerie(event.target.value);
  };

  const handleChangeCargo = (event) => {
    setCargo(event.target.value);
  };

  const handleChangeCbo = (event) => {
    setCbo(event.target.value);
  };

  const handleChangeEscolaridade = (event) => {
    setEscolaridade(event.target.value);
  };

  const handleChangeEstadoCivil = (event) => {
    setEstadoCivil(event.target.value);
  };

  const handleChangeNacionalidade = (event) => {
    setNacionalidade(event.target.value);
  };

  const handleChangePis = (event) => {
    setPis(event.target.value);
  };

  const handleChangeRg = (event) => {
    setRg(event.target.value);
  };

  const handleChangeRua = (event) => {
    setRua(event.target.value);
  };

  const handleChangeNumero = (event) => {
    setNumero(event.target.value);
  };

  const handleChangeBairro = (event) => {
    setBairro(event.target.value);
  };

  const handleChangeCidade = (event) => {
    setCidade(event.target.value);
  };

  const handleChangeUf = (event) => {
    setUf(event.target.value);
  };

  const handleChangeCep = (event) => {
    setCep(event.target.value);
  };

  const handleChangeNivel = (event) => {
    setNivel(event.target.value);
  };

  const handleChangeDepartamento = (event) => {
    setDepartamento(event.target.value);
  };

  const handleChangeEmpresa = (event) => {
    setEmpresa(event.target.value);
  };

  const handleChangeCentroDeCusto = (event) => {
    setCentroDeCusto(event.target.value);
  };

  const handleChangeDataAdmissao = (date) => {
    setDataAdmissao(date);
  };

  const handleChangeDataDemissao = (date) => {
    setDataDemissao(date);
  };

  const handleChangeSalarioInicial = (event) => {
    setSalarioInicial(event.target.value);
  };

  const handleChangeSalarioAtual = (event) => {
    setSalarioAtual(event.target.value);
  };

  const verificaTipoDeDocumento = () => {
    if (!nome) {
      toastError("O nome é obrigatório");
      return false;
    }
    if (!dataNascimento) {
      toastError("A data de nascimento é obrigatória");
      return false;
    }
    if (!cpf) {
      toastError("O CPF é obrigatório");
      return false;
    }
    if (!verificaCPF) {
      toastError("O CPF digitado não existe");
      return false;
    }
    if (!ctps) {
      toastError("A CTPS é obrigatória");
      return false;
    }
    if (!serie) {
      toastError("A série é obrigatória");
      return false;
    }
    if (!cargo) {
      toastError("O cargo é obrigatório");
      return false;
    }
    if (!cbo) {
      toastError("O CBO é obrigatório");
      return false;
    }
    if (!escolaridade) {
      toastError("A escolaridade é obrigatória");
      return false;
    }
    if (!estadoCivil) {
      toastError("O estado civil é obrigatório");
      return false;
    }
    if (!nacionalidade) {
      toastError("A nacionalidade é obrigatória");
      return false;
    }
    if (!pis) {
      toastError("O PIS é obrigatório");
      return false;
    }
    if (!rg) {
      toastError("O RG é obrigatório");
      return false;
    }
    if (!rua) {
      toastError("A rua é obrigatória");
      return false;
    }
    if (!numero) {
      toastError("O número é obrigatório");
      return false;
    }
    if (!bairro) {
      toastError("O bairro é obrigatório");
      return false;
    }
    if (!cidade) {
      toastError("A cidade é obrigatória");
      return false;
    }
    if (!uf) {
      toastError("O estado é obrigatório");
      return false;
    }
    if (!cep) {
      toastError("O CEP é obrigatório");
      return false;
    }
    if (!nivel) {
      toastError("O nível é obrigatório");
      return false;
    }
    if (!departamento) {
      toastError("O departamento é obrigatório");
      return false;
    }
    if (!empresa) {
      toastError("A empresa é obrigatória");
      return false;
    }
    if (!centroDeCusto) {
      toastError("O centro de custo é obrigatório");
      return false;
    }
    if (!dataAdmissao) {
      toastError("A data de admissão é obrigatória");
      return false;
    }
    if (!salarioInicial) {
      toastError("O salário inicial é obrigatório");
      return false;
    }

    if (
      nome &&
      dataNascimento &&
      cpf &&
      verificaCPF &&
      ctps &&
      serie &&
      cargo &&
      cbo &&
      escolaridade &&
      estadoCivil &&
      nacionalidade &&
      pis &&
      rg &&
      rua &&
      numero &&
      bairro &&
      cidade &&
      uf &&
      cep &&
      nivel &&
      departamento &&
      empresa &&
      centroDeCusto &&
      dataAdmissao &&
      salarioInicial
    ) {
      return true;
    }
  };

  const enviaDados = async () => {
    if (verificaTipoDeDocumento()) {
      try {
        const { data, status } = await api.post(
          "/api/adicionarFuncionarios",
          {
            ...{
              nome,
              data_nascimento: dataNascimento,
              cpf,
              ctps,
              serie,
              cargo,
              cbo,
              escolaridade,
              estado_civil: estadoCivil,
              nacionalidade,
              pis,
              rg,
              rua,
              numero,
              bairro,
              cidade,
              uf,
              cep,
              nivel,
              departamento,
              empresa,
              centro_custo: centroDeCusto,
              data_admissao: dataAdmissao,
              data_demissao: dataDemissao,
              salario_inicial: salarioInicial,
              salario_atual: salarioAtual,
            },
          },
          { withCredentials: true }
        );
        if (data) {
          if (status === 200) {
            setTimeout(() => {
              toastSuccess("Funcionário cadastrado com sucesso");
            }, 100);
            navigate("/dashboard");
          }
        }
      } catch {
        toastError("Erro ao cadastrar funcionário");
      }
    } else {
      toastError("Erro ao cadastrar funcionário");
    }
  };

  return (
    <>
      <Navbar />
      <CardContainer>
        <form onClick={(e) => e.preventDefault()}>
          <BodyContainer>
            <h2>Dados do Funcionário</h2>
            <TextField
              label="Nome"
              value={nome}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeNome}
            />
            <LocalizationProvider
              adapterLocale={ptBR}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Data de Nascimento"
                value={dataNascimento}
                onChange={handleChangeDataNascimento}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              label="CPF"
              value={formatCPF(cpf)}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeCPF}
            />
            <TextField
              label="CTPS"
              value={ctps}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeCTPS}
            />
            <TextField
              label="Série"
              value={serie}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeSerie}
            />
            <FormControl>
              <InputLabel>Cargo</InputLabel>
              <Select value={cargo} label="Cargo" onChange={handleChangeCargo}>
                {listaDeCargos.map((cargos, index) => (
                  <MenuItem key={index} value={cargos}>
                    {cargos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="CBO nº"
              value={cbo}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeCbo}
            />
            <FormControl>
              <InputLabel>Escolaridade</InputLabel>
              <Select
                value={escolaridade}
                label="Escolaridade"
                onChange={handleChangeEscolaridade}
              >
                {listaDeEscolaridades.map((escolaridades, index) => (
                  <MenuItem key={index} value={escolaridades}>
                    {escolaridades}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Estado Civil</InputLabel>
              <Select
                value={estadoCivil}
                label="Estado Civil"
                onChange={handleChangeEstadoCivil}
              >
                {listaDeEstadoCivil.map((estados, index) => (
                  <MenuItem key={index} value={estados}>
                    {estados}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Nacionalidade</InputLabel>
              <Select
                value={nacionalidade}
                label="Nacionalidade"
                onChange={handleChangeNacionalidade}
              >
                {listaDeNacionalidades.map((nacionalidades, index) => (
                  <MenuItem key={index} value={nacionalidades}>
                    {nacionalidades}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="PIS"
              value={formatPIS(pis)}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangePis}
            />
            <TextField
              label="RG"
              value={rg}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeRg}
            />
          </BodyContainer>
          <BodyContainer>
            <h2>Endereço do Funcionário</h2>
            <TextField
              label="CEP"
              value={formatCEP(cep) || ""}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeCep}
            />
            <TextField
              label="Rua"
              value={rua || ""}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeRua}
            />
            <TextField
              label="Número/Complemento"
              value={numero}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeNumero}
            />
            <TextField
              label="Bairro"
              value={bairro || ""}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeBairro}
            />
            <TextField
              label="Cidade"
              value={cidade || ""}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeCidade}
            />
            <TextField
              label="UF"
              value={uf || ""}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeUf}
            />
          </BodyContainer>
          <BodyContainer>
            <h2>Dados da Função</h2>
            <FormControl>
              <InputLabel>Nível</InputLabel>
              <Select value={nivel} label="Nível" onChange={handleChangeNivel}>
                {listaDeNiveis.map((niveis, index) => (
                  <MenuItem key={index} value={niveis}>
                    {niveis}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Departamento</InputLabel>
              <Select
                value={departamento}
                label="Departamento"
                onChange={handleChangeDepartamento}
              >
                {listaDeDepartamentos.map((departamentos, index) => (
                  <MenuItem key={index} value={departamentos}>
                    {departamentos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Empresa</InputLabel>
              <Select
                value={empresa}
                label="Empresa"
                onChange={handleChangeEmpresa}
              >
                {listaDeEmpresas.map((empresas, index) => (
                  <MenuItem key={index} value={empresas}>
                    {empresas}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel>Centro de Custo</InputLabel>
              <Select
                value={centroDeCusto}
                label="Centro de Custo"
                onChange={handleChangeCentroDeCusto}
              >
                {listaCentroDeCusto.map((centroDeCustos, index) => (
                  <MenuItem key={index} value={centroDeCustos}>
                    {centroDeCustos}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider
              adapterLocale={ptBR}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Data de Admissão"
                value={dataAdmissao}
                onChange={handleChangeDataAdmissao}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider
              adapterLocale={ptBR}
              dateAdapter={AdapterDateFns}
            >
              <DatePicker
                label="Data de Demissão"
                value={dataDemissao}
                onChange={handleChangeDataDemissao}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <TextField
              label="Salário Inicial"
              value={formatMoney(salarioInicial)}
              variant="outlined"
              autoComplete="off"
              placeholder="R$ 00.000,00"
              onChange={handleChangeSalarioInicial}
            />
            <TextField
              label="Salário Atual"
              value={formatMoney(salarioAtual)}
              variant="outlined"
              autoComplete="off"
              placeholder="R$ 00.000,00"
              onChange={handleChangeSalarioAtual}
            />
          </BodyContainer>
          <BodyContainer>
            <Botao
              type="submit"
              value="Cadastrar Funcionário"
              onClick={enviaDados}
            />
          </BodyContainer>
        </form>
      </CardContainer>
    </>
  );
};

export default CadastrarFuncionario;
