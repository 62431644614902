const formatMoney = (value) => {
  return value
    .replace(
      /[ ■ç¡™£¢∞§¶•ªº–≠«œ∑´®†¥¨øπ“‘æ…¬˚∆˙©ƒ∂ßåΩ≈Ç√∫µ≤≥÷{}|˜`ˆA-Za-z!-+-/:-_]/g,
      ""
    ) // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\.\d{3})\d+?$/, "$1") // captura 3 numeros seguidos de um . e não deixa ser digitado mais nada
    .replace(/(,\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de uma , e não deixa ser digitado mais nada
};

export default formatMoney;
