import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import CardContainer from "../../../../styles/CardContainer";
import BodyContainer from "../../../../styles/BodyContainer";
import api from "../../../../service/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BotaoExcluir from "../../../../styles/BotaoExcluir";
import { useNavigate } from "react-router-dom";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaRH } from "../../../../utils/verificaTipoConta";
import toastError from "../../../../utils/toastError";
import toastSuccess from "../../../../utils/toastSuccess";
import LoadingRocket from "../../../../components/animations/LoadingRocket";

const ExcluirFuncionario = () => {
  VerificaLogin();
  VerificaTipoContaRH();
  const navigate = useNavigate();
  const [dataFuncionario, setDataFuncionario] = useState([]);
  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState({
    id: "",
  });
  const [abreCampos, setAbreCampos] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get("/api/buscarFuncionarios").then((response) => {
      setDataFuncionario(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    dataFuncionario.map((funcionario) => {
      if (funcionario.nome === nomeFuncionario) {
        setFuncionarioSelecionado(funcionario);
      }
      return null;
    });
  }, [nomeFuncionario, dataFuncionario]);

  const handleChangeNomeFuncionario = (event) => {
    setNomeFuncionario(event.target.value);
    setAbreCampos(true);
  };

  const deletarFuncionario = async () => {
    const { id } = funcionarioSelecionado;
    try {
      await api
        .post(`/api/excluirFuncionario`, {
          id: id,
        })
        .then(() => {
          setTimeout(() => {
            toastSuccess("Funcionário excluído com sucesso");
          }, 100);
          navigate("/dashboard");
        });
    } catch {
      toastError("Erro ao excluir funcionário");
    }
  };

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <form onClick={(e) => e.preventDefault()}>
          <BodyContainer>
            <h2>Selecione o Funcionário</h2>
            <FormControl>
              <InputLabel>Nome</InputLabel>
              <Select
                value={nomeFuncionario}
                label="Nome"
                onChange={handleChangeNomeFuncionario}
              >
                {dataFuncionario.map((funcionario, index) => (
                  <MenuItem key={index} value={funcionario.nome}>
                    {funcionario.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BodyContainer>
          {abreCampos ? (
            <>
              <BodyContainer>
                <BotaoExcluir
                  type="submit"
                  value="Excluir Funcionário"
                  onClick={deletarFuncionario}
                />
              </BodyContainer>
            </>
          ) : null}
        </form>
      </CardContainer>
    </>
  );
};

export default ExcluirFuncionario;
