import React, { useState, useEffect } from "react";
import VerificaLogin from "../../../../utils/verificaLogin";
import Navbar from "../../../../components/navbar/Navbar";
import CardContainer from "../../../../styles/CardContainer";
import BodyContainer from "../../../../styles/BodyContainer";
import Botao from "../../../../styles/Botao";
import { useNavigate } from "react-router-dom";

import api from "../../../../service/api";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import formatCPF from "../../../../utils/formatCPF";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ptBR } from "date-fns/locale";
import formatPIS from "../../../../utils/formatPIS";
import formatCEP from "../../../../utils/formatCEP";
import formatMoney from "../../../../utils/formatMoney";

import { VerificaTipoContaRH } from "../../../../utils/verificaTipoConta";
import toastError from "../../../../utils/toastError";
import toastSuccess from "../../../../utils/toastSuccess";
import LoadingRocket from "../../../../components/animations/LoadingRocket";

const listaDeCargos = [
  "Agrônomo Júnior",
  "Agrônomo Pleno",
  "Analista Administrativo",
  "Assistente de Atendimento",
  "Analista de Marketing",
  "Analista de Negócios",
  "Analista de RH",
  "Analista de Vendas",
  "Aprendiz",
  "Assistente Administrativo",
  "Assistente de RH",
  "Assistente de Vendas",
  "Autônomo",
  "Auxiliar ADM Operacional",
  "Auxiliar Administrativo",
  "Auxiliar Administrativo Financeiro",
  "Auxiliar Contábil",
  "Auxiliar de Marketing",
  "Auxiliar de Vendas",
  "Bióloga Júnior",
  "Bióloga Pleno",
  "Bióloga Sênior",
  "Consultor de Processos",
  "Controlador de Pragas",
  "Controlador de Pragas Júnior",
  "Controlador de Pragas Master",
  "Controlador de Pragas Pleno",
  "Controlador de Pragas Sênior",
  "Desenvolvedor de TI",
  "Diretor - Presidente",
  "Engenheiro Agrônomo",
  "Estagiário",
  "Gerente Comercial",
  "Gerente de Unidade",
  "Gerente Geral",
  "Representante Comercial",
  "Supervisor de Vendas",
  "Supervisor Técnico",
  "Técnico em Manutenção",
  "Técnico de Segurança do Trabalho",
  "Agenciador de propaganda",
];

const listaDeEscolaridades = [
  "Analfabeto",
  "Até quarta série incompleta (primário incompleto)",
  "Com quarta série completa (primário completo)",
  "Primeiro grau incompleto",
  "Primeiro grau completo",
  "Segundo grau incompleto",
  "Segundo grau completo",
  "Superior incompleto",
  "Superior completo",
  "Mestrado completo",
  "Doutorado completo",
  "Pós-graduação/especialização",
  "Pós-doutorado",
];

const listaDeEstadoCivil = [
  "Solteiro",
  "Casado",
  "Separado",
  "Divorciado",
  "Viúvo",
];

const listaDeNacionalidades = [
  "Brasileiro",
  "Argentino",
  "Uruguaio",
  "Angolano",
];

const listaDeNiveis = [
  "Agenciador de propaganda",
  "Agrônomo Júnior",
  "Agrônomo Pleno",
  "Analista Administrativo",
  "Analista de RH",
  "Analista de Vendas",
  "Assistente Administrativo",
  "Assistente de Vendas",
  "Assistente de RH",
  "Aprendiz",
  "Auxiliar Administrativo",
  "Auxiliar Administrativo Financeiro",
  "Auxiliar Administrativo/Operacional",
  "Auxiliar Contábil",
  "Auxiliar de Marketing",
  "Auxiliar de Vendas",
  "Biologa Júnior",
  "Biologa Pleno",
  "Biologa Sênior",
  "Consultor Comercial",
  "Consultor de Processos",
  "Consultor de RH",
  "Controlador de Pragas",
  "Controlador de Pragas Júnior",
  "Controlador de Pragas Master",
  "Controlador de Pragas Pleno",
  "Controlador de Pragas Sênior",
  "Diretor-Presidente",
  "Diretor",
  "Engenheiro Agrônomo",
  "Estagiário",
  "Analista de Negócios",
  "Gerente Comercial",
  "Gerente de Unidade",
  "Gerente Geral",
  "Supervisor de Vendas",
  "Supervisor Técnico",
  "Técnico de Segurança do Trabalho",
];

const listaDeDepartamentos = [
  "Operacional",
  "Técnico",
  "Administrativo",
  "RH",
  "Comercial",
  "Gerencial",
  "Administrativo-Operacional",
  "Afastado",
];

const listaDeEmpresas = [
  "UNICONTROL CONTROLE DE PRAGAS LTDA",
  "UNICONTROL CONTROLE DE PRAGAS S/A - FILIAL FARROUPILHA",
  "FITOLOG LICENCIAMENTO DE FRANQUIAS LTDA",
  "NEWSIS SISTEMAS E SERVIÇOS DE INTERNET LTDA",
];

const listaCentroDeCusto = [
  "OPERACIONAL CANOAS",
  "COMERCIAL CANOAS",
  "ADMINISTRATIVO CANOAS",
  "DIREÇÃO",
  "OPERCIONAL FARROUPILHA",
  "COMERCIAL FARROUPILHA",
  "ADMINISTRATIVO FARROUPILHA",
  "ADMINISTRATIVO GERAL",
  "OPERCIONAL GERAL",
  "COMERCIAL GERAL",
];

listaDeCargos.sort();
listaDeEscolaridades.sort();
listaDeEstadoCivil.sort();
listaDeNacionalidades.sort();
listaDeNiveis.sort();
listaDeDepartamentos.sort();
listaDeEmpresas.sort();
listaCentroDeCusto.sort();

const EditarFuncionario = () => {
  VerificaLogin();
  VerificaTipoContaRH();
  const navigate = useNavigate();
  const [dataFuncionario, setDataFuncionario] = useState([]);
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState({
    id: "",
    nome: "",
    data_nascimento: null,
    cpf: "",
    cargo: "",
    ctps: "",
    escolaridade: "",
    estado_civil: "",
    nacionalidade: "",
    pis: "",
    rg: "",
    rua: "",
    numero: "",
    bairro: "",
    cidade: "",
    uf: "",
    cep: "",
    nivel: "",
    departamento: "",
    empresa: "",
    centro_custo: "",
    data_admissao: null,
    data_demissao: null,
    salario_inicial: "",
    salario_atual: "",
    cbo: "",
    serie: "",
  });

  const [nomeFuncionario, setNomeFuncionario] = useState("");
  const [abreCampos, setAbreCampos] = useState(false);
  const [verificaCPF, setVerificaCPF] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleChangeNomeFuncionario = (event) => {
    setNomeFuncionario(event.target.value);
    setAbreCampos(true);
  };

  useEffect(() => {
    api.get("/api/buscarFuncionarios").then((response) => {
      setDataFuncionario(response.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    dataFuncionario.map((funcionario) => {
      if (funcionario.nome === nomeFuncionario) {
        setFuncionarioSelecionado(funcionario);
      }
      return null;
    });
  }, [nomeFuncionario, dataFuncionario]);

  useEffect(() => {
    setFuncionarioSelecionado({
      ...funcionarioSelecionado,
      cpf: formatCPF(funcionarioSelecionado.cpf),
    });
  }, [funcionarioSelecionado.cpf]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFuncionarioSelecionado({
      ...funcionarioSelecionado,
      pis: formatPIS(funcionarioSelecionado.pis),
    });
  }, [funcionarioSelecionado.pis]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFuncionarioSelecionado({
      ...funcionarioSelecionado,
      cep: formatCEP(funcionarioSelecionado.cep),
    });
  }, [funcionarioSelecionado.cep]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFuncionarioSelecionado({
      ...funcionarioSelecionado,
      salario_inicial: formatMoney(funcionarioSelecionado.salario_inicial),
    });
  }, [funcionarioSelecionado.salario_inicial]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFuncionarioSelecionado({
      ...funcionarioSelecionado,
      salario_atual: formatMoney(funcionarioSelecionado.salario_atual),
    });
  }, [funcionarioSelecionado.salario_atual]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    api
      .post("/api/validarCpfCnpj", {
        cpf: funcionarioSelecionado.cpf,
      })
      .then((response) => {
        setVerificaCPF(response.data);
      });
  }, [funcionarioSelecionado.cpf]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (funcionarioSelecionado.cep.length === 9) {
      api
        .post("/api/buscaCep", { cep: funcionarioSelecionado.cep })
        .then((response) => {
          try {
            if (response) {
              setFuncionarioSelecionado({
                ...funcionarioSelecionado,
                rua: response.data.logradouro,
                bairro: response.data.bairro,
                cidade: response.data.localidade,
                uf: response.data.uf,
              });
            }
          } catch (error) {
            console.log(error);
          }
        });
    } else {
      setFuncionarioSelecionado({
        ...funcionarioSelecionado,
        rua: "",
        bairro: "",
        cidade: "",
        uf: "",
        numero: "",
      });
    }
  }, [funcionarioSelecionado.cep]); // eslint-disable-line react-hooks/exhaustive-deps

  const verificaDadosIncorretos = () => {
    if (!funcionarioSelecionado.nome) {
      toastError("O nome é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.data_nascimento) {
      toastError("A data de nascimento é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.cpf) {
      toastError("O CPF é obrigatório");
      return false;
    }
    if (!verificaCPF) {
      toastError("O CPF digitado não existe");
      return false;
    }
    if (!funcionarioSelecionado.ctps) {
      toastError("A CTPS é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.serie) {
      toastError("A série é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.cargo) {
      toastError("O cargo é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.cbo) {
      toastError("O CBO é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.escolaridade) {
      toastError("A escolaridade é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.estado_civil) {
      toastError("O estado civil é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.nacionalidade) {
      toastError("A nacionalidade é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.pis) {
      toastError("O PIS é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.rg) {
      toastError("O RG é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.rua) {
      toastError("A rua é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.numero) {
      toastError("O número é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.bairro) {
      toastError("O bairro é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.cidade) {
      toastError("A cidade é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.uf) {
      toastError("O estado é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.cep) {
      toastError("O CEP é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.nivel) {
      toastError("O nível é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.departamento) {
      toastError("O departamento é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.empresa) {
      toastError("A empresa é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.centro_custo) {
      toastError("O centro de custo é obrigatório");
      return false;
    }
    if (!funcionarioSelecionado.data_admissao) {
      toastError("A data de admissão é obrigatória");
      return false;
    }
    if (!funcionarioSelecionado.salario_inicial) {
      toastError("O salário inicial é obrigatório");
      return false;
    }

    if (
      funcionarioSelecionado.nome &&
      funcionarioSelecionado.data_nascimento &&
      funcionarioSelecionado.cpf &&
      verificaCPF &&
      funcionarioSelecionado.ctps &&
      funcionarioSelecionado.serie &&
      funcionarioSelecionado.cargo &&
      funcionarioSelecionado.cbo &&
      funcionarioSelecionado.escolaridade &&
      funcionarioSelecionado.estado_civil &&
      funcionarioSelecionado.nacionalidade &&
      funcionarioSelecionado.pis &&
      funcionarioSelecionado.rg &&
      funcionarioSelecionado.rua &&
      funcionarioSelecionado.numero &&
      funcionarioSelecionado.bairro &&
      funcionarioSelecionado.cidade &&
      funcionarioSelecionado.uf &&
      funcionarioSelecionado.cep &&
      funcionarioSelecionado.nivel &&
      funcionarioSelecionado.departamento &&
      funcionarioSelecionado.empresa &&
      funcionarioSelecionado.centro_custo &&
      funcionarioSelecionado.data_admissao &&
      funcionarioSelecionado.salario_inicial
    ) {
      return true;
    }
  };

  const enviarAlteracoes = async () => {
    if (verificaDadosIncorretos()) {
      try {
        const { data, status } = await api.post(
          "/api/editarFuncionarios",
          {
            id: funcionarioSelecionado.id,
            nome: funcionarioSelecionado.nome,
            data_nascimento: funcionarioSelecionado.data_nascimento,
            cpf: funcionarioSelecionado.cpf,
            ctps: funcionarioSelecionado.ctps,
            serie: funcionarioSelecionado.serie,
            cargo: funcionarioSelecionado.cargo,
            cbo: funcionarioSelecionado.cbo,
            escolaridade: funcionarioSelecionado.escolaridade,
            estado_civil: funcionarioSelecionado.estado_civil,
            nacionalidade: funcionarioSelecionado.nacionalidade,
            pis: funcionarioSelecionado.pis,
            rg: funcionarioSelecionado.rg,
            rua: funcionarioSelecionado.rua,
            numero: funcionarioSelecionado.numero,
            bairro: funcionarioSelecionado.bairro,
            cidade: funcionarioSelecionado.cidade,
            uf: funcionarioSelecionado.uf,
            cep: funcionarioSelecionado.cep,
            nivel: funcionarioSelecionado.nivel,
            departamento: funcionarioSelecionado.departamento,
            empresa: funcionarioSelecionado.empresa,
            centro_Custo: funcionarioSelecionado.centro_custo,
            data_admissao: funcionarioSelecionado.data_admissao,
            data_demissao: funcionarioSelecionado.data_demissao,
            salario_inicial: funcionarioSelecionado.salario_inicial,
            salario_atual: funcionarioSelecionado.salario_atual,
          },
          { withCredentials: true }
        );
        if (data) {
          if (status === 200) {
            setTimeout(() => {
              toastSuccess("Funcionário editado com sucesso");
            }, 100);
            navigate("/dashboard");
          }
        }
      } catch {
        toastError("Erro ao Editar funcionário");
      }
    } else {
      toastError("Erro ao cadastrar funcionário");
    }
  };

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <form onClick={(e) => e.preventDefault()}>
          <BodyContainer>
            <h2>Selecione o Funcionário</h2>
            <FormControl>
              <InputLabel>Nome</InputLabel>
              <Select
                value={nomeFuncionario}
                label="Nome"
                onChange={handleChangeNomeFuncionario}
              >
                {dataFuncionario.map((funcionario, index) => (
                  <MenuItem key={index} value={funcionario.nome}>
                    {funcionario.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </BodyContainer>
          {abreCampos ? (
            <>
              <BodyContainer>
                <h2>Dados do Funcionário</h2>
                <TextField
                  label="Nome"
                  variant="outlined"
                  autoComplete="off"
                  value={funcionarioSelecionado.nome}
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      nome: e.target.value,
                    })
                  }
                />
                <LocalizationProvider
                  adapterLocale={ptBR}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Data de Nascimento"
                    value={funcionarioSelecionado.data_nascimento}
                    onChange={(date) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        data_nascimento: date,
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <TextField
                  label="CPF"
                  value={formatCPF(funcionarioSelecionado.cpf)}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      cpf: e.target.value,
                    })
                  }
                />
                <TextField
                  label="CTPS"
                  value={funcionarioSelecionado.ctps}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      ctps: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Série"
                  value={funcionarioSelecionado.serie}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      serie: e.target.value,
                    })
                  }
                />
                <FormControl>
                  <InputLabel>Cargo</InputLabel>
                  <Select
                    value={funcionarioSelecionado.cargo}
                    label="Cargo"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        cargo: e.target.value,
                      })
                    }
                  >
                    {listaDeCargos.map((cargos, index) => (
                      <MenuItem key={index} value={cargos}>
                        {cargos}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="CBO nº"
                  value={funcionarioSelecionado.cbo}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      cbo: e.target.value,
                    })
                  }
                />
                <FormControl>
                  <InputLabel>Escolaridade</InputLabel>
                  <Select
                    value={funcionarioSelecionado.escolaridade}
                    label="Escolaridade"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        escolaridade: e.target.value,
                      })
                    }
                  >
                    {listaDeEscolaridades.map((escolaridades, index) => (
                      <MenuItem key={index} value={escolaridades}>
                        {escolaridades}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>Estado Civil</InputLabel>
                  <Select
                    value={funcionarioSelecionado.estado_civil}
                    label="Estado Civil"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        estado_civil: e.target.value,
                      })
                    }
                  >
                    {listaDeEstadoCivil.map((estados, index) => (
                      <MenuItem key={index} value={estados}>
                        {estados}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>Nacionalidade</InputLabel>
                  <Select
                    value={funcionarioSelecionado.nacionalidade}
                    label="Nacionalidade"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        nacionalidade: e.target.value,
                      })
                    }
                  >
                    {listaDeNacionalidades.map((nacionalidades, index) => (
                      <MenuItem key={index} value={nacionalidades}>
                        {nacionalidades}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label="PIS"
                  value={formatPIS(funcionarioSelecionado.pis)}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      pis: e.target.value,
                    })
                  }
                />
                <TextField
                  label="RG"
                  value={funcionarioSelecionado.rg}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      rg: e.target.value,
                    })
                  }
                />
              </BodyContainer>
              <BodyContainer>
                <h2>Endereço do Funcionário</h2>
                <TextField
                  label="CEP"
                  value={formatCEP(funcionarioSelecionado.cep)}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      cep: e.target.value,
                    })
                  }
                  maxLength="9"
                />
                <TextField
                  label="Rua"
                  value={funcionarioSelecionado.rua}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      rua: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Número/Complemento"
                  value={funcionarioSelecionado.numero}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      numero: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Bairro"
                  value={funcionarioSelecionado.bairro}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      bairro: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Cidade"
                  value={funcionarioSelecionado.cidade}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      cidade: e.target.value,
                    })
                  }
                />
                <TextField
                  label="UF"
                  value={funcionarioSelecionado.uf}
                  variant="outlined"
                  autoComplete="off"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      uf: e.target.value,
                    })
                  }
                />
              </BodyContainer>
              <BodyContainer>
                <h2>Dados da Função</h2>
                <FormControl>
                  <InputLabel>Nível</InputLabel>
                  <Select
                    value={funcionarioSelecionado.nivel}
                    label="Nivel"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        nivel: e.target.value,
                      })
                    }
                  >
                    {listaDeNiveis.map((niveis, index) => (
                      <MenuItem key={index} value={niveis}>
                        {niveis}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>Departamento</InputLabel>
                  <Select
                    value={funcionarioSelecionado.departamento}
                    label="Departamento"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        departamento: e.target.value,
                      })
                    }
                  >
                    {listaDeDepartamentos.map((departamentos, index) => (
                      <MenuItem key={index} value={departamentos}>
                        {departamentos}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>Empresa</InputLabel>
                  <Select
                    value={funcionarioSelecionado.empresa}
                    label="Empresa"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        empresa: e.target.value,
                      })
                    }
                  >
                    {listaDeEmpresas.map((empresas, index) => (
                      <MenuItem key={index} value={empresas}>
                        {empresas}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <InputLabel>Centro de Custo</InputLabel>
                  <Select
                    value={funcionarioSelecionado.centro_custo}
                    label="Centro de Custo"
                    onChange={(e) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        centro_custo: e.target.value,
                      })
                    }
                  >
                    {listaCentroDeCusto.map((centroDeCustos, index) => (
                      <MenuItem key={index} value={centroDeCustos}>
                        {centroDeCustos}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <LocalizationProvider
                  adapterLocale={ptBR}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Data de Admissão"
                    value={funcionarioSelecionado.data_admissao}
                    onChange={(date) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        data_admissao: date,
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <LocalizationProvider
                  adapterLocale={ptBR}
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    label="Data de Demissão"
                    value={funcionarioSelecionado.data_demissao}
                    onChange={(date) =>
                      setFuncionarioSelecionado({
                        ...funcionarioSelecionado,
                        data_demissao: date,
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <TextField
                  label="Salário Inicial"
                  value={formatMoney(funcionarioSelecionado.salario_inicial)}
                  variant="outlined"
                  autoComplete="off"
                  placeholder="R$ 00.000,00"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      salario_inicial: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Salário Atual"
                  value={formatMoney(funcionarioSelecionado.salario_atual)}
                  variant="outlined"
                  autoComplete="off"
                  placeholder="R$ 00.000,00"
                  onChange={(e) =>
                    setFuncionarioSelecionado({
                      ...funcionarioSelecionado,
                      salario_atual: e.target.value,
                    })
                  }
                />
              </BodyContainer>
              <BodyContainer>
                <Botao
                  type="submit"
                  name="Editar"
                  value="Editar Funcionário"
                  onClick={enviarAlteracoes}
                />
              </BodyContainer>
            </>
          ) : null}
        </form>
      </CardContainer>
    </>
  );
};

export default EditarFuncionario;
