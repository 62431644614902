import React from "react";
import styles from "../../styles/acessonegado.module.css";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import StopAnimation from "../../components/animations/StopAnimation";

const AcessoNegado = () => {
  const [cookies] = useCookies(["jwt"]);

  return (
    <div className={styles.container}>
      <main className={styles.subContainer}>
        <div
          style={{
            width: "150px",
            height: "150px",
          }}
        >
          <StopAnimation />
        </div>
        <div className={styles.error}>
          <h3
            style={{
              marginTop: "20px",
            }}
          >
            Ei, Parado aí!
          </h3>
          <p>Você não tem permissão para acessar esta página.</p>
          <p
            style={{
              marginTop: "10px",
              fontWeight: "600",
            }}
          >
            Verifique se você esta logado. Caso esteja, seu cargo não tem acesso
            a esta página.
          </p>
        </div>
        <Link to={cookies.jwt ? "/dashboard" : "/"} className={styles.botao}>
          VÁ PARA A PÁGINA INICIAL
        </Link>
      </main>
    </div>
  );
};

export default AcessoNegado;
