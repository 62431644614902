import React from "react";
import Introducao from "../introducao/Introducao";
import Navbar from "../../components/navbar/Navbar";

import VerificaLogin from "../../utils/verificaLogin";
import Footer from "../../components/footer/Footer";

const Dashboard = () => {
  VerificaLogin();
  return (
    <>
      <Navbar />
      <div
        style={{
          animation: "fadein 0.5s",
        }}
      >
        <Introducao />
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
