import React, { useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaAdministrador } from "../../../../utils/verificaTipoConta";
import { TextField } from "@mui/material";
import CardContainer from "../../../../styles/CardContainer";
import BodyContainer from "../../../../styles/BodyContainer";
import Switch from "react-switch";
import Botao from "../../../../styles/Botao";
import api from "../../../../service/api";
import toastSuccess from "../../../../utils/toastSuccess";
import toastError from "../../../../utils/toastError";
import LoadingRocket from "../../../../components/animations/LoadingRocket";
import scrollToTop from "../../../../utils/scrollToTop";

const CadastrarAcesso = () => {
  VerificaLogin();
  VerificaTipoContaAdministrador();

  const [nome, setNome] = useState("");
  const [fitolog, setFitolog] = useState(false);
  const [comercial, setComercial] = useState(false);
  const [adm, setAdm] = useState(false);
  const [rh, setRh] = useState(false);
  const [tecnico, setTecnico] = useState(true);
  const [newsis, setNewsis] = useState(false);
  const [marketing, setMarketing] = useState(true);
  const [projetos, setProjetos] = useState(false);
  const [controleGestao, setControleGestao] = useState(true);
  const [treinamentos, setTreinamentos] = useState(false);
  const [ti, setTi] = useState(true);
  const [temp, setTemp] = useState(true);
  const [franquias, setFranquias] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNome = (e) => setNome(e.target.value);

  const divStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f3f3f3",
    borderRadius: "10px",
  };

  const handleChangeFitolog = () => {
    !fitolog ? setFitolog(true) : setFitolog(false);
  };

  const handleChangeComercial = () => {
    !comercial ? setComercial(true) : setComercial(false);
  };

  const handleChangeAdm = () => {
    !adm ? setAdm(true) : setAdm(false);
  };

  const handleChangeRh = () => {
    !rh ? setRh(true) : setRh(false);
  };

  const handleChangeTecnico = () => {
    !tecnico ? setTecnico(true) : setTecnico(false);
  };

  const handleChangeNewsis = () => {
    !newsis ? setNewsis(true) : setNewsis(false);
  };

  const handleChangeMarketing = () => {
    !marketing ? setMarketing(true) : setMarketing(false);
  };

  const handleChangeProjetos = () => {
    !projetos ? setProjetos(true) : setProjetos(false);
  };

  const handleChangeControleGestao = () => {
    !controleGestao ? setControleGestao(true) : setControleGestao(false);
  };

  const handleChangeTreinamentos = () => {
    !treinamentos ? setTreinamentos(true) : setTreinamentos(false);
  };

  const handleChangeTi = () => {
    !ti ? setTi(true) : setTi(false);
  };

  const handleChangeTemp = () => {
    !temp ? setTemp(true) : setTemp(false);
  };

  const handleChangeFranquias = () => {
    !franquias ? setFranquias(true) : setFranquias(false);
  };

  const limpaCampos = () => {
    setNome("");
    setFitolog(false);
    setComercial(false);
    setAdm(false);
    setRh(false);
    setTecnico(false);
    setNewsis(false);
    setMarketing(false);
    setProjetos(false);
    setControleGestao(false);
    setTreinamentos(false);
    setTi(false);
    setTemp(false);
    setFranquias(false);
    scrollToTop();
  };

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <BodyContainer>
          <h1
            style={{
              fontSize: "1.8rem",
            }}
          >
            Acesso as pastas do Servidor
          </h1>
          <h3
            style={{
              marginTop: "40px",
            }}
          >
            Colaborador
          </h3>
          <TextField
            sx={{
              width: "100%",
            }}
            label="Nome"
            variant="outlined"
            value={nome}
            onChange={handleNome}
          />
          <h3
            style={{
              marginTop: "40px",
            }}
          >
            Pastas
          </h3>
          <div style={divStyle}>
            <p>Fitolog</p>
            <Switch
              checked={fitolog}
              onChange={handleChangeFitolog}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Comercial</p>
            <Switch
              checked={comercial}
              onChange={handleChangeComercial}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Administrativo</p>
            <Switch
              checked={adm}
              onChange={handleChangeAdm}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Recursos Humanos</p>
            <Switch
              checked={rh}
              onChange={handleChangeRh}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Técnico</p>
            <Switch
              checked={tecnico}
              onChange={handleChangeTecnico}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Newsis</p>
            <Switch
              checked={newsis}
              onChange={handleChangeNewsis}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Marketing</p>
            <Switch
              checked={marketing}
              onChange={handleChangeMarketing}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Projetos</p>
            <Switch
              checked={projetos}
              onChange={handleChangeProjetos}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Controle de Gestão</p>
            <Switch
              checked={controleGestao}
              onChange={handleChangeControleGestao}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Treinamentos</p>
            <Switch
              checked={treinamentos}
              onChange={handleChangeTreinamentos}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>TI</p>
            <Switch
              checked={ti}
              onChange={handleChangeTi}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Temp</p>
            <Switch
              checked={temp}
              onChange={handleChangeTemp}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <div style={divStyle}>
            <p>Franquias</p>
            <Switch
              checked={franquias}
              onChange={handleChangeFranquias}
              onColor="#045E88"
              activeBoxShadow={null}
            />
          </div>
          <Botao
            type={"submit"}
            value={"Cadastrar"}
            onClick={() => {
              setLoading(true);
              if (nome === "") {
                setLoading(false);
                return toastError("Preencha o campo nome");
              }
              api
                .post("/api/cadastrarAcesso", {
                  nome: nome,
                  fitolog: fitolog ? 1 : 0,
                  comercial: comercial ? 1 : 0,
                  adm: adm ? 1 : 0,
                  rh: rh ? 1 : 0,
                  tecnico: tecnico ? 1 : 0,
                  newsis: newsis ? 1 : 0,
                  marketing: marketing ? 1 : 0,
                  projetos: projetos ? 1 : 0,
                  controle_gestao: controleGestao ? 1 : 0,
                  treinamentos: treinamentos ? 1 : 0,
                  ti: ti ? 1 : 0,
                  temp: temp ? 1 : 0,
                  franquias: franquias ? 1 : 0,
                })
                .then((response) => {
                  if (response.data.erro) {
                    setLoading(false);
                    return toastError(response.data.erro);
                  }
                  setLoading(false);
                  toastSuccess(response.data.sucesso);
                  limpaCampos();
                });
            }}
          />
        </BodyContainer>
      </CardContainer>
    </>
  );
};

export default CadastrarAcesso;
