import styled from "styled-components";

const DocumentsContainer = styled.div`
  padding: 50px 50px;
  margin: 0px 60px;
  margin-bottom: 60px;
  background-color: #ffffff;
  border-radius: 5px;
  animation: fadein 0.5s;

  @media (max-width: 1180px) {
    padding: 40px 40px;
    margin: 20px;
  }
  @media (max-width: 600px) {
    padding: 20px 20px;
    margin: 10px;
  }
`;

export default DocumentsContainer;
