import React, { useState, useContext } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import logo_principal from "../../img/logo-principal";
import { Spin as Hamburger } from "hamburger-react";
import { UserTypeContext } from "../../contexts/UserTypeContext";

import toastSuccess from "../../utils/toastSuccess";

const Navbar = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [isOpen, setOpen] = useState(false);
  const context = useContext(UserTypeContext);

  const logOut = () => {
    navigate("/");
    setTimeout(() => {
      localStorage.clear();
      removeCookie("jwt");
      toastSuccess("Deslogado com sucesso");
    }, 200);
  };

  const verificaTipoUsuario = () => {
    if (
      context.userType === "RH" ||
      localStorage.getItem("userType") === "RH"
    ) {
      return (
        <>
          <Link to="/rh" className="link-navbar">
            <li>RH</li>
          </Link>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>Comercial</li>
          </a>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>Operacional</li>
          </a>
          <Link to="/register" className="link-navbar">
            <li>Registrar</li>
          </Link>
        </>
      );
    } else if (
      context.userType === "Comercial" ||
      localStorage.getItem("userType") === "Comercial"
    ) {
      return (
        <>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>RH</li>
          </a>
          <Link to="/comercial" className="link-navbar">
            <li>Comercial</li>
          </Link>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>Operacional</li>
          </a>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>Registrar</li>
          </a>
        </>
      );
    } else if (
      context.userType === "Operacional" ||
      localStorage.getItem("userType") === "Operacional"
    ) {
      return (
        <>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>RH</li>
          </a>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>Comercial</li>
          </a>
          <Link to="/operacional" className="link-navbar">
            <li>Operacional</li>
          </Link>
          {/* eslint-disable-next-line */}
          <a className="link-navbar" onClick={() => navigate("/acessoNegado")}>
            <li>Registrar</li>
          </a>
        </>
      );
    } else if (
      context.userType === "Administrador" ||
      localStorage.getItem("userType") === "Administrador"
    ) {
      return (
        <>
          <Link to="/administradores" className="link-navbar">
            <li>Administradores</li>
          </Link>
          <Link to="/rh" className="link-navbar">
            <li>RH</li>
          </Link>
          <Link to="/comercial" className="link-navbar">
            <li>Comercial</li>
          </Link>
          <Link to="/operacional" className="link-navbar">
            <li>Operacional</li>
          </Link>
          <Link to="/register" className="link-navbar">
            <li>Registrar</li>
          </Link>
        </>
      );
    }
  };

  const handleClick = () => {
    return (
      <div className="hamburger-menu">
        <div className="hamburger-menu-content">
          <div className="links-mobile">
            {context.userType === "Administrador" ||
            localStorage.getItem("userType") === "Administrador" ? (
              <Link to="/administradores">Administradores</Link>
            ) : null}
            <Link to="/rh">RH</Link>
            <Link to="/comercial">Comercial</Link>
            <Link to="/operacional">Operacional</Link>
            <Link to="/register">Registrar</Link>
            <button onClick={logOut} className="link-navbar-sair">
              <li>Sair</li>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <nav className="nav-navbar">
        <div>
          <Link to="/dashboard">
            <img src={logo_principal} id="logo-principal" alt="logo" />
          </Link>
        </div>
        <div className="list-navbar">
          <ul>
            {verificaTipoUsuario()}
            <button onClick={logOut} className="link-navbar-sair">
              <li>Sair</li>
            </button>
          </ul>
        </div>
        <div className="hamburguer">
          <Hamburger
            id="hamburguer"
            toggled={isOpen}
            toggle={setOpen}
            size={30}
            color="#ffffff"
            onToggle={(toggled) => handleClick(toggled)}
          />
          {isOpen ? handleClick() : null}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
