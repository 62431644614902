import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import api from "../../../../service/api";
import Navbar from "../../../../components/navbar/Navbar";
import DocumentsContainer from "../../../../styles/DocumentsContainer";
import LoadingRocket from "../../../../components/animations/LoadingRocket";
import style from "styled-components";
import ServerAnimation from "../../../../components/animations/ServerAnimation";
import { Link } from "react-router-dom";
import Botao from "../../../../styles/Botao";
import { MdCancel } from "react-icons/md";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import BotaoExcluir from "../../../../styles/BotaoExcluir";
import toastError from "../../../../utils/toastError";
import toastSuccess from "../../../../utils/toastSuccess";
import { FaUserEdit } from "react-icons/fa";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import { TextField } from "@mui/material";
import tipoEmpresa from "../../../../data/tipoEmpresa";
import tipoSetor from "../../../../data/tipoSetor";
import tipoUnidade from "../../../../data/tipoUnidade";
import tipoOffice from "../../../../data/tipoOffice";
import tipoWindows from "../../../../data/tipoWindows";

import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch } from "react-icons/ai";

const Table = style.div`
  color: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
`;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: window.innerWidth > 600 ? theme.spacing(5) : theme.spacing(0),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const GerenciarInfos = () => {
  const [expanded, setExpanded] = useState("");
  const [loading, setLoading] = useState(true);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [infos, setInfos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [idAcesso, setIdAcesso] = useState("");
  const [nomeAcesso, setNomeAcesso] = useState("");
  const [search, setSearch] = useState("");

  const [setor, setSetor] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [unidade, setUnidade] = useState("");

  const [usuarioRede, setUsuarioRede] = useState("");
  const [senhaRede, setSenhaRede] = useState("");
  const [email, setEmail] = useState("");
  const [senhaEmail, setSenhaEmail] = useState("");

  const [emailDiscord, setEmailDiscord] = useState("");
  const [senhaDiscord, setSenhaDiscord] = useState("");

  const [notebook, setNotebook] = useState("");
  const [nomeNotebook, setNomeNotebook] = useState("");
  const [office, setOffice] = useState("");
  const [windows, setWindows] = useState("");

  const [patrimonioNotebook, setPatrimonioNotebook] = useState("");
  const [patrimonioCooler, setPatrimonioCooler] = useState("");

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleOpenEditar = () => setModalEditar(true);
  const handleCloseEditar = () => setModalEditar(false);

  const handleChangeNome = (event) => setNomeAcesso(event.target.value);
  const handleChangeSetor = (event) => setSetor(event.target.value);
  const handleChangeEmpresa = (event) => setEmpresa(event.target.value);
  const handleChangeUnidade = (event) => setUnidade(event.target.value);

  const handleChangeUsuarioRede = (event) => setUsuarioRede(event.target.value);
  const handleChangeSenhaRede = (event) => setSenhaRede(event.target.value);
  const handleChangeEmail = (event) => setEmail(event.target.value);
  const handleChangeSenhaEmail = (event) => setSenhaEmail(event.target.value);

  const handleChangeEmailDiscord = (event) =>
    setEmailDiscord(event.target.value);
  const handleChangeSenhaDiscord = (event) =>
    setSenhaDiscord(event.target.value);

  const handleChangeNotebook = (event) => setNotebook(event.target.value);
  const handleChangeNomeNotebook = (event) =>
    setNomeNotebook(event.target.value);
  const handleChangeOffice = (event) => setOffice(event.target.value);
  const handleChangeWindows = (event) => setWindows(event.target.value);

  const handleChangePatrimonioNotebook = (event) =>
    setPatrimonioNotebook(event.target.value);
  const handleChangePatrimonioCooler = (event) =>
    setPatrimonioCooler(event.target.value);

  const editarInfos = (nome) => {
    infos.map((info) => {
      if (info.nome === nome) {
        setIdAcesso(info.id);
        setNomeAcesso(info.nome);
        setSetor(info.setor);
        setEmpresa(info.empresa);
        setUnidade(info.unidade);
        setUsuarioRede(info.usuario_rede);
        setSenhaRede(info.senha_rede);
        setEmail(info.email);
        setSenhaEmail(info.senha_email);
        setEmailDiscord(info.email_discord);
        setSenhaDiscord(info.senha_discord);
        setNotebook(info.marca_notebook);
        setNomeNotebook(info.nome_notebook);
        setOffice(info.office);
        setWindows(info.windows);
        setPatrimonioNotebook(info.patrimonio_notebook);
        setPatrimonioCooler(info.cooler_notebook);
      }
      return null;
    });
  };

  const buscaInfos = () => {
    api.get("api/buscarInfos").then((response) => {
      setInfos(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    buscaInfos();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    outline: "none",
  };

  const styleEditar = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxHeight: "80%",
    overflowY: "auto",
    outline: "none",
  };

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      {
        <Modal open={openDialog} onClose={handleCloseDialog}>
          <Box sx={style}>
            <h2
              style={{
                fontSize: "1.2rem",
                fontWeight: "normal",
              }}
            >
              Tem certeza que deseja remover{" "}
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {nomeAcesso}?
              </span>
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <BotaoExcluir
                type={"submit"}
                value={"Cancelar"}
                style={{
                  width: "100%",
                }}
                onClick={handleCloseDialog}
              />
              <Botao
                type={"submit"}
                value={"Confirmar"}
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  handleCloseDialog();
                  setLoading(true);
                  api
                    .post("api/excluirInfo", { id: idAcesso })
                    .then((response) => {
                      if (response.data.sucesso) {
                        toastSuccess("Informação excluída com sucesso");
                        setExpanded(false);
                        return buscaInfos();
                      }
                      toastError("Erro ao excluir informação");
                    });
                }}
              />
            </div>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={modalEditar}
          onClose={() => {
            handleCloseEditar();
          }}
        >
          <Box sx={styleEditar}>
            <h2
              style={{
                fontSize: "1.2rem",
                fontWeight: "bold",
              }}
            >
              Editar Acesso
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <TextField
                label="Nome"
                variant="outlined"
                autoComplete="off"
                value={nomeAcesso}
                onChange={handleChangeNome}
              />
              <FormControl>
                <InputLabel>Setor</InputLabel>
                <Select
                  value={setor}
                  onChange={handleChangeSetor}
                  label="Setor"
                >
                  {tipoSetor.map((setores, index) => (
                    <MenuItem key={index} value={setores}>
                      {setores}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Empresa</InputLabel>
                <Select
                  value={empresa}
                  onChange={handleChangeEmpresa}
                  label="Empresa"
                >
                  {tipoEmpresa.map((empresas, index) => (
                    <MenuItem key={index} value={empresas}>
                      {empresas}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Unidade</InputLabel>
                <Select
                  value={unidade}
                  onChange={handleChangeUnidade}
                  label="Unidade"
                >
                  {tipoUnidade.map((unidades, index) => (
                    <MenuItem key={index} value={unidades}>
                      {unidades}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <h3
                style={{
                  marginTop: "50px",
                }}
              >
                Rede
              </h3>
              <TextField
                label="Usuário da Rede"
                variant="outlined"
                autoComplete="off"
                value={usuarioRede}
                onChange={handleChangeUsuarioRede}
              />
              <TextField
                label="Senha da Rede"
                variant="outlined"
                autoComplete="off"
                value={senhaRede}
                onChange={handleChangeSenhaRede}
              />
              <TextField
                label="E-mail"
                variant="outlined"
                autoComplete="off"
                value={email}
                onChange={handleChangeEmail}
              />
              <TextField
                label="Senha do E-mail"
                variant="outlined"
                autoComplete="off"
                value={senhaEmail}
                onChange={handleChangeSenhaEmail}
              />
              <h3
                style={{
                  marginTop: "50px",
                }}
              >
                Discord
              </h3>
              <TextField
                label="E-mail do Discord"
                variant="outlined"
                autoComplete="off"
                value={emailDiscord}
                onChange={handleChangeEmailDiscord}
              />
              <TextField
                label="Senha do Discord"
                variant="outlined"
                autoComplete="off"
                value={senhaDiscord}
                onChange={handleChangeSenhaDiscord}
              />
              <h3
                style={{
                  marginTop: "50px",
                }}
              >
                Sistema
              </h3>
              <TextField
                label="Marca do Notebook"
                variant="outlined"
                autoComplete="off"
                value={notebook}
                onChange={handleChangeNotebook}
              />
              <TextField
                label="Nome do Notebook"
                variant="outlined"
                autoComplete="off"
                value={nomeNotebook}
                onChange={handleChangeNomeNotebook}
              />
              <FormControl>
                <InputLabel>Office</InputLabel>
                <Select
                  value={office}
                  onChange={handleChangeOffice}
                  label="Office"
                >
                  {tipoOffice.map((offices, index) => (
                    <MenuItem key={index} value={offices}>
                      {offices}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel>Windows</InputLabel>
                <Select
                  value={windows}
                  onChange={handleChangeWindows}
                  label="Windows"
                >
                  {tipoWindows.map((windows, index) => (
                    <MenuItem key={index} value={windows}>
                      {windows}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <h3
                style={{
                  marginTop: "50px",
                }}
              >
                Patrimônio
              </h3>
              <TextField
                label="Patrimônio do Notebook"
                variant="outlined"
                autoComplete="off"
                value={patrimonioNotebook}
                onChange={handleChangePatrimonioNotebook}
              />
              <TextField
                label="Patrimônio do Cooler"
                variant="outlined"
                autoComplete="off"
                value={patrimonioCooler}
                onChange={handleChangePatrimonioCooler}
              />
            </div>
            <Botao
              type={"submit"}
              value={"Confirmar"}
              onClick={() => {
                handleCloseEditar();
                setLoading(true);
                api
                  .post("api/editarInfo", {
                    id: idAcesso,
                    nome: nomeAcesso,
                    setor,
                    empresa,
                    unidade,
                    usuario_rede: usuarioRede,
                    senha_rede: senhaRede,
                    email,
                    senha_email: senhaEmail,
                    email_discord: emailDiscord,
                    senha_discord: senhaDiscord,
                    marca_notebook: notebook,
                    nome_notebook: nomeNotebook,
                    office,
                    windows,
                    patrimonio_notebook: patrimonioNotebook,
                    cooler_notebook: patrimonioCooler,
                  })
                  .then((response) => {
                    if (response.data.sucesso) {
                      setLoading(false);
                      buscaInfos();
                      return toastSuccess("Informacão editada com sucesso");
                    }
                    setLoading(false);
                    toastError("Erro ao editar informação");
                  });
              }}
            />
            <BotaoExcluir
              type={"submit"}
              value={"Cancelar"}
              style={{
                width: "100%",
                marginTop: "0px",
              }}
              onClick={handleCloseEditar}
            />
          </Box>
        </Modal>
      }
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <DocumentsContainer>
        <div
          style={
            window.innerWidth > 768
              ? {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "2rem",
                  backgroundColor: "#bdbdbd33",
                  padding: "1rem",
                  borderRadius: "5px",
                }
              : {
                  display: "flex",
                  flexDirection: "column",
                }
          }
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <ServerAnimation />
            <h1
              style={
                window.innerWidth > 768
                  ? {
                      color: "#1e1e1e",

                      fontSize: "1.8rem",
                    }
                  : {
                      textAlign: "center",
                      fontSize: "1.4rem",
                    }
              }
            >
              Gerenciar Informações
            </h1>
          </div>
          <div
            style={
              window.innerWidth > 768
                ? null
                : {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: "1rem",
                    width: "100%",
                  }
            }
          >
            <div
              style={
                window.innerWidth > 768
                  ? {
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }
                  : { width: "100%" }
              }
            >
              <TextField
                style={{
                  width: "100%",
                }}
                size={"small"}
                variant="outlined"
                placeholder="Buscar Informação"
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
              />
              <Link to="/administradores/infosGerais/cadastrarInfo">
                <Botao
                  value={"Cadastrar Nova Informação"}
                  type={"button"}
                  style={
                    window.innerWidth > 768
                      ? {
                          marginTop: "0",
                          padding: "0rem 1rem",
                        }
                      : {
                          width: "84vw",
                        }
                  }
                />
              </Link>
            </div>
          </div>
        </div>
        {infos
          .filter((info) => {
            if (search === "") {
              return info;
            } else if (info.nome.toLowerCase().includes(search.toLowerCase())) {
              return info;
            }
            return null;
          })
          .map((info, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                aria-controls={`panel${index}d-content`}
                id={`panel${index}d-header`}
              >
                <div
                  style={{
                    fontWeight: "500",
                    color: "#1e1e1e",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {info.nome}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                    }}
                  >
                    <FaUserEdit
                      size={20}
                      color={"#1e1e1e"}
                      onClick={(e) => {
                        e.stopPropagation();
                        editarInfos(info.nome);
                        handleOpenEditar();
                      }}
                    />
                    <MdCancel
                      size={20}
                      color={"#da2626"}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIdAcesso(info.id);
                        setNomeAcesso(info.nome);
                        handleOpenDialog();
                      }}
                    />
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  "& > :nth-of-type(odd)": {
                    backgroundColor: "#bdbdbd33",
                    borderRadius: "5px",
                  },
                }}
              >
                <Table>
                  <div>Nome</div> {info.nome}
                </Table>
                <Table>
                  <div>Setor</div> {info.setor}
                </Table>
                <Table>
                  <div>Empresa</div> {info.empresa}
                </Table>
                <Table>
                  <div>Unidade</div> {info.unidade}
                </Table>
                <Table>
                  <div>Usuário na Rede</div>
                  {info.usuario_rede}
                </Table>
                <Table>
                  <div>Senha da Rede</div>
                  {info.senha_rede}
                </Table>
                <Table>
                  <div>E-mail</div>
                  {info.email}
                </Table>
                <Table>
                  <div>Senha do E-mail</div>
                  {info.senha_email}
                </Table>
                <Table>
                  <div>E-mail do Discord</div>
                  {info.email_discord}
                </Table>
                <Table>
                  <div>Senha do Discord</div>
                  {info.senha_discord}
                </Table>
                <Table>
                  <div>Marca do Notebook</div>
                  {info.marca_notebook}
                </Table>
                <Table>
                  <div>Nome do Notebook</div>
                  {info.nome_notebook}
                </Table>
                <Table>
                  <div>Versão do Office</div>
                  {info.office}
                </Table>
                <Table>
                  <div>Versão do Windows</div>
                  {info.windows}
                </Table>
                <Table>
                  <div>Patrimônio do Notebook</div>
                  {info.patrimonio_notebook}
                </Table>
                <Table>
                  <div>Patrimônio do Cooler</div>
                  {info.cooler_notebook}
                </Table>
              </AccordionDetails>
            </Accordion>
          ))}
      </DocumentsContainer>
    </>
  );
};

export default GerenciarInfos;
