import styled from "styled-components";

const BodyContainer = styled.div`
  display: grid;
  padding: 0px 80px;
  padding-top: 60px;
  row-gap: 10px;

  @media (max-width: 1180px) {
    padding: 0px 40px;
    padding-top: 60px;
  }
  @media (max-width: 600px) {
    padding: 0px 20px;
    padding-top: 60px;
  }
`;

export default BodyContainer;
