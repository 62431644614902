const orderByASC = (arrayData, nameField) => {
  return arrayData.sort((a, b) => {
    if (a[nameField] < b[nameField]) {
      return -1;
    }
    if (a[nameField] > b[nameField]) {
      return 1;
    }
    return 0;
  });
};

export default orderByASC;
