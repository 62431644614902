import React from "react";
import Navbar from "../../components/navbar/Navbar";
import VerificaLogin from "../../utils/verificaLogin";
import { VerificaTipoContaAdministrador } from "../../utils/verificaTipoConta";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";

const Administradores = () => {
  VerificaLogin();
  VerificaTipoContaAdministrador();

  return (
    <>
      <Navbar />
      <div className="row">
        <Link to="/administradores/controleEmail" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Controle de E-mail"}
            paragrafo={"Todos os E-mails"}
          />
        </Link>
        <Link to="/administradores/controleServidor" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Controle de acessos ao Servidor"}
            paragrafo={"Todos os acessos ao Servidor"}
          />
        </Link>
        <Link to="/administradores/infosGerais" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Controle de Informações Gerais"}
            paragrafo={"Todos as Informações Gerais"}
          />
        </Link>
      </div>
    </>
  );
};

export default Administradores;
