import styled from "styled-components";

const InputFile = styled.label`
  padding: 0.4rem;
  font-weight: 300;
  width: 100%;
  background-color: #30ae50;
  color: #fff;
  text-align: center;
  display: block;
  height: 40px;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  &:hover {
    background-color: #4cd16e;
  }
`;

export default InputFile;
