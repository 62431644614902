import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import Login from "./pages/login/Login";
import NotFound from "./pages/notfound/NotFound";
import Dashboard from "./pages/dashboard/Dashboard";
import Register from "./pages/register/Register";
import Rh from "./pages/rh/Rh";
import Comercial from "./pages/comercial/Comercial";
import Operacional from "./pages/operacional/Operacional";
import GeradorDocumentos from "./pages/rh/geradorDocumentos/GeradorDocumentos";
import ConsultaHorasTrabalhadas from "./pages/rh/consultaHorasTrabalhadas/ConsultaHorasTrabalhadas";
import AvaliacaoRH from "./pages/rh/avaliacao/AvaliacaoRH";
import GeradorContratos from "./pages/comercial/geradorContratos/GeradorContratos";
import AvaliacaoComercial from "./pages/comercial/avaliacaoComercial/AvaliacaoComercial";
import ControleEstoque from "./pages/operacional/controleEstoque/ControleEstoque";
import AvaliacaoOperacional from "./pages/operacional/avaliacaoOperacional/AvaliacaoOperacional";
import CadastrarFuncionario from "./pages/rh/geradorDocumentos/cadastrarFuncionario/CadastrarFuncionario";
import EditarFuncionario from "./pages/rh/geradorDocumentos/editarFuncionario/EditarFuncionario";
import ExcluirFuncionario from "./pages/rh/geradorDocumentos/excluirFuncionario/ExcluirFuncionario";
import { UserTypeProvider } from "./contexts/UserTypeContext";
import { ToastContainer } from "react-toastify";
import GerenciarDocumentos from "./pages/comercial/gerenciarDocumentos/GerenciarDocumentos";
import CadastrarProduto from "./pages/operacional/controleEstoque/cadastrarProduto/CadastrarProduto";
import CadastrarUniforme from "./pages/operacional/controleEstoque/cadastrarUniforme/CadastrarUniforme";
import ListarMovimentacao from "./pages/operacional/controleEstoque/listarMovimentacao/ListarMovimentacao";
import SolicitacaoPedidos from "./pages/operacional/controleEstoque/solicitacaoPedidos/SolicitacaoPedidos";
import BuscarProdutoCodBarras from "./pages/operacional/controleEstoque/buscarProdutoCodBarras/BuscarProdutoCodBarras";
import ReativarProdutos from "./pages/operacional/controleEstoque/reativarProdutos/ReativarProdutos";
import Administradores from "./pages/administradores/Administradores";
import AcessoNegado from "./pages/acessoNegado/AcessoNegado";
import ControleEmail from "./pages/administradores/controleEmail/ControleEmail";
import ControleEmailGrupo from "./pages/administradores/controleEmail/controleEmailGrupo/ControleEmailGrupo";
import ControleEmailFranq from "./pages/administradores/controleEmail/controleEmailFranq/ControleEmailFranq";
import ControleEmailNewsis from "./pages/administradores/controleEmail/controleEmailNewsis/ControleEmailNewsis";
import ControleEmailLabs from "./pages/administradores/controleEmail/controleEmailLabs/ControleEmailLabs";
import ControleServidor from "./pages/administradores/controleServidor/ControleServidor";
import CadastrarAcesso from "./pages/administradores/controleServidor/cadastrarAcesso/CadastrarAcesso";
import ListarAcessos from "./pages/administradores/controleServidor/listarAcessos/ListarAcessos";
import InfosGerais from "./pages/administradores/infosGerais/InfosGerais";
import CadastrarInfo from "./pages/administradores/infosGerais/cadastrarInfo/CadastrarInfo";
import GerenciarInfos from "./pages/administradores/infosGerais/gerenciarInfos/GerenciarInfos";

const App = () => {
  return (
    <UserTypeProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route exact path="/rh" element={<Rh />} />
          <Route path="/rh/geradorDocumentos" element={<GeradorDocumentos />} />
          <Route
            path="/rh/consultaHorasTrabalhadas"
            element={<ConsultaHorasTrabalhadas />}
          />
          <Route path="/rh/avaliacaoRH" element={<AvaliacaoRH />} />
          <Route exact path="/comercial" element={<Comercial />} />
          <Route
            path="/rh/geradorDocumentos/cadastrarFuncionario"
            element={<CadastrarFuncionario />}
          />
          <Route
            path="/rh/geradorDocumentos/editarFuncionario"
            element={<EditarFuncionario />}
          />
          <Route
            path="/rh/geradorDocumentos/excluirFuncionario"
            element={<ExcluirFuncionario />}
          />
          <Route
            path="/comercial/gerenciarDocumentos"
            element={<GerenciarDocumentos />}
          />
          <Route
            path="/comercial/geradorContratos"
            element={<GeradorContratos />}
          />
          <Route
            path="/comercial/avaliacaoComercial"
            element={<AvaliacaoComercial />}
          />
          <Route exact path="/operacional" element={<Operacional />} />
          <Route
            path="/operacional/ControleEstoque"
            element={<ControleEstoque />}
          />
          <Route
            path="/operacional/avaliacaoOperacional"
            element={<AvaliacaoOperacional />}
          />
          <Route
            path="/operacional/controleEstoque/cadastrarProduto"
            element={<CadastrarProduto />}
          />
          <Route
            path="/operacional/controleEstoque/cadastrarUniforme"
            element={<CadastrarUniforme />}
          />
          <Route
            path="/operacional/ControleEstoque/listarMovimentacao"
            element={<ListarMovimentacao />}
          />
          <Route
            path="/operacional/ControleEstoque/solicitacaoPedidos"
            element={<SolicitacaoPedidos />}
          />
          <Route
            path="/operacional/ControleEstoque/buscarProdutoPeloCodBarras"
            element={<BuscarProdutoCodBarras />}
          />
          <Route
            path="/operacional/ControleEstoque/reativarProdutos"
            element={<ReativarProdutos />}
          />
          <Route path="/administradores" element={<Administradores />} />
          <Route
            path="/administradores/controleEmail"
            element={<ControleEmail />}
          />
          <Route
            path="/administradores/controleEmail/controleEmailGrupo"
            element={<ControleEmailGrupo />}
          />
          <Route
            path="/administradores/controleEmail/controleEmailFranq"
            element={<ControleEmailFranq />}
          />
          <Route
            path="/administradores/controleEmail/controleEmailNewsis"
            element={<ControleEmailNewsis />}
          />
          <Route
            path="/administradores/controleEmail/controleEmailLabs"
            element={<ControleEmailLabs />}
          />
          <Route
            path="/administradores/controleServidor"
            element={<ControleServidor />}
          />
          <Route
            path="/administradores/controleServidor/cadastrarAcesso"
            element={<CadastrarAcesso />}
          />
          <Route
            path="/administradores/controleServidor/listarAcessos"
            element={<ListarAcessos />}
          />
          <Route
            path="/administradores/infosGerais"
            element={<InfosGerais />}
          />
          <Route
            path="/administradores/infosGerais/cadastrarInfo"
            element={<CadastrarInfo />}
          />
          <Route
            path="/administradores/infosGerais/gerenciarInfos"
            element={<GerenciarInfos />}
          />
          <Route path="/acessoNegado" element={<AcessoNegado />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </UserTypeProvider>
  );
};

export default App;
