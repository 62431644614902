import styled from "styled-components";

const CardContainer = styled.div`
  box-shadow: 3px 3px 13px 0px rgba(50, 50, 50, 0.22);
  margin: 0px 400px;
  background-color: white;
  border-radius: 5px;
  padding-bottom: 80px;
  margin-bottom: 80px;
  animation: fadein 0.5s;

  @media (max-width: 1300px) {
    margin-top: 0px;
    margin-right: 300px;
    margin-bottom: 150px;
    margin-left: 300px;
  }

  @media (max-width: 1180px) {
    margin-top: 0px;
    margin-right: 200px;
    margin-bottom: 200px;
    margin-left: 200px;
  }

  @media (max-width: 900px) {
    margin-top: 0px;
    margin-right: 60px;
    margin-bottom: 60px;
    margin-left: 60px;
  }

  @media (max-width: 600px) {
    margin-top: 0px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
`;

export default CardContainer;
