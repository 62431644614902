const formatData60Dias = (date) => {
  const dateObj = new Date(date);
  dateObj.setDate(dateObj.getDate() + 90);
  const day = dateObj.getDate();
  const month = dateObj.getMonth() + 1;
  const year = dateObj.getFullYear();

  return `${day}/${month}/${year}`;
};

export default formatData60Dias;
