import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import BodyContainer from "../../../../styles/BodyContainer";
import CardContainer from "../../../../styles/CardContainer";
import VerificaLogin from "../../../../utils/verificaLogin";
import DocumentsContainer from "../../../../styles/DocumentsContainer";
import { VerificaTipoContaOperacional } from "../../../../utils/verificaTipoConta";
import tipoControleEstoque from "../../../../data/tipoControleEstoque";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import api from "../../../../service/api";
import Botao from "../../../../styles/Botao";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import formatData from "../../../../utils/formatData";

import Pagination from "@mui/material/Pagination";
import { Tooltip } from "@mui/material";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { AiOutlineSearch } from "react-icons/ai";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import orderByDESC from "../../../../utils/orderByDESC";
import orderByASC from "../../../../utils/orderByASC";
import LoadingRocket from "../../../../components/animations/LoadingRocket";

const ListarMovimentacao = () => {
  VerificaLogin();
  VerificaTipoContaOperacional();

  const [tipoItem, setTipoItem] = useState("");
  const [abreCampos, setAbreCampos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [movimentacaoData, setMovimentacaoData] = useState([]);
  const [tipoItemFlag, setTipoItemFlag] = useState("");

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(8);

  const [openArrowProduto, setOpenArrowProduto] = useState({
    nome: false,
    medida: false,
    quantidade: false,
    tipo: false,
    unidade: false,
    placa: false,
    valor_pago: false,
    nf: false,
    data: false,
  });

  const [openArrowUniforme, setOpenArrowUniforme] = useState({
    nome: false,
    tamanho: false,
    quantidade: false,
    tipo: false,
    unidade: false,
    operador: false,
    valor_pago: false,
    nf: false,
    data: false,
  });

  const handleChangeTipoItem = (event) => {
    setTipoItem(event.target.value);
  };

  const buscaMovimentacoes = () => {
    if (tipoItem === "Produtos") {
      setLoading(true);
      api.get("/api/buscaListaMovimentacaoProduto").then((response) => {
        setLoading(false);
        setTipoItemFlag("Produtos");
        setMovimentacaoData(response.data);
        setAbreCampos(true);
        setCurrentPage(1);
        setSearch("");
        clearArrows();
        if (!loading) {
          setTimeout(() => {
            window.scrollTo({
              top: 550,
              behavior: "smooth",
            });
          }, 250);
        }
      });
    } else if (tipoItem === "Uniformes e EPI's") {
      setLoading(true);
      api.get("/api/buscaListaMovimentacaoUniforme").then((response) => {
        setLoading(false);
        setTipoItemFlag("Uniformes e EPI's");
        setMovimentacaoData(response.data);
        setAbreCampos(true);
        setCurrentPage(1);
        setSearch("");
        clearArrows();
        if (!loading) {
          setTimeout(() => {
            window.scrollTo({
              top: 550,
              behavior: "smooth",
            });
          }, 250);
        }
      });
    }
  };

  const clearArrows = () => {
    setOpenArrowProduto({
      nome: false,
      medida: false,
      quantidade: false,
      tipo: false,
      unidade: false,
      placa: false,
      valor_pago: false,
      nf: false,
      data: false,
    });

    setOpenArrowUniforme({
      name: false,
      tamanho: false,
      qtd: false,
      tipo: false,
      unidade: false,
      operador: false,
      valor_pago: false,
      nf: false,
      data: false,
    });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  // função que adiciona zero na casa decimal caso seja necessário
  const addZeroToFinal = (value) => {
    let valueBefore = "";
    let valueAfter = "";
    let boolean = false;

    for (let i = 0; i < value.length; i++) {
      if (value[i] === ",") {
        boolean = true;
      }
      if (boolean) {
        valueBefore = valueBefore + value[i];
      } else {
        valueAfter = valueAfter + value[i];
      }
    }

    if (valueBefore.length === 2) {
      valueBefore = valueBefore + "0";
      value = valueAfter + valueBefore;
    }

    if (valueBefore.length === 0) {
      return (value = value + ",00");
    } else {
      return value;
    }
  };

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <BodyContainer>
          <h2>Listar Movimentações de Estoque</h2>
          <FormControl>
            <InputLabel>Tipo de Item</InputLabel>
            <Select
              label="Tipo de Item"
              value={tipoItem}
              onChange={handleChangeTipoItem}
            >
              {tipoControleEstoque.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Botao
            type={"submit"}
            value="Buscar Movimentações"
            onClick={() => {
              buscaMovimentacoes();
            }}
          />
        </BodyContainer>
      </CardContainer>
      {abreCampos ? (
        <DocumentsContainer>
          {tipoItemFlag === "Produtos" ? (
            <div
              style={
                window.innerWidth > 768
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "40px",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "center",
                      marginBottom: "20px",
                    }
              }
            >
              <TextField
                variant="outlined"
                placeholder="Busca"
                value={search}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearch(e.target.value);
                }}
                style={
                  window.innerWidth > 768 ? { width: "20%" } : { width: "100%" }
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
              />
              <p
                style={
                  window.innerWidth > 768
                    ? {
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                      }
                    : {
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                        marginBottom: "20px",
                      }
                }
              >
                Total de movimentações:{" "}
                {search
                  ? movimentacaoData.filter((item) => {
                      if (
                        item.produto
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.tipo
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.nome_unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.placa
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        (item.valor_pago !== null &&
                          item.valor_pago
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        (item.n_fiscal !== null &&
                          item.n_fiscal
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        formatData(item.data)
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                      ) {
                        return item;
                      } else {
                        return null;
                      }
                    }).length
                  : movimentacaoData.length}
              </p>
            </div>
          ) : (
            <div
              style={
                window.innerWidth > 768
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "40px",
                    }
                  : {
                      display: "flex",
                      flexDirection: "column-reverse",
                      alignItems: "center",
                      marginBottom: "20px",
                    }
              }
            >
              <TextField
                variant="outlined"
                placeholder="Busca"
                value={search}
                onChange={(e) => {
                  setCurrentPage(1);
                  setSearch(e.target.value);
                }}
                style={
                  window.innerWidth > 768 ? { width: "20%" } : { width: "100%" }
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AiOutlineSearch />
                    </InputAdornment>
                  ),
                }}
              />
              <p
                style={
                  window.innerWidth > 768
                    ? {
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                      }
                    : {
                        width: "100%",
                        textAlign: "center",
                        fontSize: "14px",
                        padding: "16.5px 14px",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "5px",
                        marginBottom: "20px",
                      }
                }
              >
                Total de movimentações:{" "}
                {search
                  ? movimentacaoData.filter((item) => {
                      if (
                        item.uniforme_epi
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.tamanho
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.nome
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.nome_unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.operador
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        (item.valor_pago !== null &&
                          item.valor_pago
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        (item.n_fiscal !== null &&
                          item.n_fiscal
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        formatData(item.data)
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                      ) {
                        return item;
                      } else {
                        return null;
                      }
                    }).length
                  : movimentacaoData.length}
              </p>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table size="medium" aria-label="a dense table">
              {tipoItemFlag === "Produtos" ? (
                <TableHead
                  sx={
                    window.innerWidth > 768
                      ? {
                          background:
                            "linear-gradient(45deg, #055f89, #0187c6)",
                        }
                      : {
                          background: "#055f89",
                        }
                  }
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "15%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <p>Produto</p>
                        {openArrowProduto.nome ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                              });
                              orderByASC(movimentacaoData, "produto");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: true,
                                medida: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                placa: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "produto");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Medida</p>
                        {openArrowProduto.medida ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                medida: false,
                              });
                              orderByASC(movimentacaoData, "unidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: true,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                placa: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "unidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Quantidade</p>
                        {openArrowProduto.quantidade ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                quantidade: false,
                              });
                              orderByASC(movimentacaoData, "quantidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: true,
                                tipo: false,
                                unidade: false,
                                placa: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "quantidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Tipo</p>
                        {openArrowProduto.tipo ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                tipo: false,
                              });
                              orderByASC(movimentacaoData, "tipo");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                tipo: true,
                                unidade: false,
                                placa: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "tipo");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "12%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Unidade</p>
                        {openArrowProduto.unidade ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                unidade: false,
                              });
                              orderByASC(movimentacaoData, "nome_unidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                tipo: false,
                                unidade: true,
                                placa: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "nome_unidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Placa</p>
                        {openArrowProduto.placa ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                placa: false,
                              });
                              orderByASC(movimentacaoData, "placa");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                placa: true,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "placa");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Valor</p>
                        {openArrowProduto.valor_pago ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                valor_pago: false,
                              });
                              orderByASC(movimentacaoData, "valor_pago");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                placa: false,
                                valor_pago: true,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "valor_pago");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Nota F.</p>
                        {openArrowProduto.nf ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nf: false,
                              });
                              orderByASC(movimentacaoData, "n_fiscal");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                placa: false,
                                valor_pago: false,
                                nf: true,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "n_fiscal");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Data</p>
                        {openArrowProduto.data ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                data: false,
                              });
                              orderByASC(movimentacaoData, "data");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowProduto({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                placa: false,
                                valor_pago: false,
                                nf: false,
                                data: true,
                              });
                              orderByDESC(movimentacaoData, "data");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
              ) : (
                <TableHead
                  sx={
                    window.innerWidth > 768
                      ? {
                          background:
                            "linear-gradient(45deg, #055f89, #0187c6)",
                        }
                      : {
                          background: "#055f89",
                        }
                  }
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        width: "15%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                      >
                        <p>Produto</p>
                        {openArrowUniforme.nome ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                              });
                              orderByASC(movimentacaoData, "uniforme_epi");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: true,
                                tamanho: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                operador: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "uniforme_epi");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Tamanho</p>
                        {openArrowUniforme.tamanho ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                tamanho: false,
                              });
                              orderByASC(movimentacaoData, "tamanho");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: true,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                operador: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "tamanho");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Quantidade</p>
                        {openArrowUniforme.quantidade ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                quantidade: false,
                              });
                              orderByASC(movimentacaoData, "quantidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: false,
                                quantidade: true,
                                tipo: false,
                                unidade: false,
                                operador: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "quantidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Tipo</p>
                        {openArrowUniforme.tipo ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                tipo: false,
                              });
                              orderByASC(movimentacaoData, "nome");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: false,
                                quantidade: false,
                                tipo: true,
                                unidade: false,
                                operador: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "nome");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "12%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Unidade</p>
                        {openArrowUniforme.unidade ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                unidade: false,
                              });
                              orderByASC(movimentacaoData, "nome_unidade");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: false,
                                quantidade: false,
                                tipo: false,
                                unidade: true,
                                operador: false,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "nome_unidade");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "15%",
                        color: "#ffffff",
                      }}
                      align="center"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Operador</p>
                        {openArrowUniforme.operador ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                operador: false,
                              });
                              orderByASC(movimentacaoData, "operador");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                operador: true,
                                valor_pago: false,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "operador");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Valor</p>
                        {openArrowUniforme.valor_pago ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                valor_pago: false,
                              });
                              orderByASC(movimentacaoData, "valor_pago");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                medida: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                placa: false,
                                valor_pago: true,
                                nf: false,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "valor_pago");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Nota F.</p>
                        {openArrowUniforme.nf ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nf: false,
                              });
                              orderByASC(movimentacaoData, "n_fiscal");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                operador: false,
                                valor_pago: false,
                                nf: true,
                                data: false,
                              });
                              orderByDESC(movimentacaoData, "n_fiscal");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      sx={{
                        width: "10%",
                        color: "#ffffff",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>Data</p>
                        {openArrowUniforme.data ? (
                          <IoIosArrowUp
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                data: false,
                              });
                              orderByASC(movimentacaoData, "data");
                            }}
                          />
                        ) : (
                          <IoIosArrowDown
                            cursor={"pointer"}
                            size={20}
                            onClick={() => {
                              setOpenArrowUniforme({
                                nome: false,
                                tamanho: false,
                                quantidade: false,
                                tipo: false,
                                unidade: false,
                                operador: false,
                                valor_pago: false,
                                nf: false,
                                data: true,
                              });
                              orderByDESC(movimentacaoData, "data");
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
              )}

              <TableBody>
                {movimentacaoData
                  .filter((item) => {
                    if (search === "") {
                      return item;
                    } else if (tipoItemFlag === "Produtos") {
                      if (
                        item.produto
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.tipo
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.nome_unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.placa
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        (item.valor_pago !== null &&
                          item.valor_pago
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        (item.n_fiscal !== null &&
                          item.n_fiscal
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        formatData(item.data)
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                      ) {
                        return item;
                      } else {
                        return null;
                      }
                    } else if (tipoItemFlag === "Uniformes e EPI's") {
                      if (
                        item.uniforme_epi
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.tamanho
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.quantidade
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.nome
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.nome_unidade
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        item.operador
                          .toUpperCase()
                          .includes(search.toUpperCase()) ||
                        (item.valor_pago !== null &&
                          item.valor_pago
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        (item.n_fiscal !== null &&
                          item.n_fiscal
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())) ||
                        formatData(item.data)
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())
                      ) {
                        return item;
                      } else {
                        return null;
                      }
                    } else {
                      return item;
                    }
                  })
                  .slice(indexOfFirstPost, indexOfLastPost)
                  .map((row, index) => {
                    const verificaCorTipoProduto = () => {
                      if (row.tipo === "Entrada") {
                        return "#3ebf2d";
                      } else if (row.tipo === "Baixa") {
                        return "#d13737";
                      } else if (row.tipo === "Cadastro") {
                        return "#3e9bf2";
                      } else if (row.tipo === "Inativação") {
                        return "#a5a5a5";
                      }
                    };

                    const verificaCorTipoUniforme = () => {
                      if (row.nome === "Entrada") {
                        return "#3ebf2d";
                      } else if (row.nome === "Baixa") {
                        return "#d13737";
                      } else if (row.nome === "Cadastro") {
                        return "#3e9bf2";
                      } else if (row.nome === "Inativação") {
                        return "#a5a5a5";
                      } else if (row.nome === "-") {
                        return "#6c6c6c";
                      }
                    };
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {tipoItemFlag === "Produtos" ? (
                          <>
                            <TableCell>
                              {row.produto.length > 15 ? (
                                <Tooltip title={row.produto.toUpperCase()}>
                                  <p>
                                    {row.produto.toUpperCase().substring(0, 15)}
                                    ...
                                  </p>
                                </Tooltip>
                              ) : (
                                <p>{row.produto.toUpperCase()}</p>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.unidade.toUpperCase()}</p>
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.quantidade}</p>
                            </TableCell>
                            <TableCell align="center">
                              <p
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: verificaCorTipoProduto(),
                                  color: "#ffffff",
                                  borderRadius: "5px",
                                  padding: "4px 2px",
                                }}
                              >
                                {row.tipo.toUpperCase()}
                              </p>
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.nome_unidade.toUpperCase()}</p>
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.placa}</p>
                            </TableCell>
                            {row.valor_pago ? (
                              <TableCell align="center">
                                <p>
                                  R${" "}
                                  {addZeroToFinal(
                                    row.valor_pago
                                      .toString()
                                      .split(".")
                                      .join(",")
                                  )}
                                </p>
                              </TableCell>
                            ) : (
                              <TableCell align="center">
                                <p>-</p>
                              </TableCell>
                            )}
                            {row.n_fiscal !== null ? (
                              <TableCell align="center">
                                <p>{row.n_fiscal}</p>
                              </TableCell>
                            ) : (
                              <TableCell align="center">
                                <p>-</p>
                              </TableCell>
                            )}
                            <TableCell align="center">
                              <p>{formatData(row.data)}</p>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              {row.uniforme_epi.length > 15 ? (
                                <Tooltip title={row.uniforme_epi.toUpperCase()}>
                                  <p>
                                    {row.uniforme_epi
                                      .toUpperCase()
                                      .substring(0, 15)}
                                    ...
                                  </p>
                                </Tooltip>
                              ) : (
                                <p>{row.uniforme_epi.toUpperCase()}</p>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.tamanho.toUpperCase()}</p>
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.quantidade}</p>
                            </TableCell>
                            <TableCell align="center">
                              <p
                                style={{
                                  cursor: "pointer",
                                  backgroundColor: verificaCorTipoUniforme(),
                                  color: "#ffffff",
                                  borderRadius: "5px",
                                  padding: "4px 2px",
                                }}
                              >
                                {row.nome.toUpperCase()}
                              </p>
                            </TableCell>
                            <TableCell align="center">
                              <p>{row.nome_unidade.toUpperCase()}</p>
                            </TableCell>
                            <TableCell align="center">
                              {row.operador.length > 15 ? (
                                <Tooltip title={row.operador.toUpperCase()}>
                                  <p>
                                    {row.operador
                                      .toUpperCase()
                                      .substring(0, 15)}
                                    ...
                                  </p>
                                </Tooltip>
                              ) : (
                                <p>{row.operador.toUpperCase()}</p>
                              )}
                            </TableCell>
                            {row.valor_pago ? (
                              <TableCell align="center">
                                <p>
                                  R${" "}
                                  {addZeroToFinal(
                                    row.valor_pago
                                      .toString()
                                      .split(".")
                                      .join(",")
                                  )}
                                </p>
                              </TableCell>
                            ) : (
                              <TableCell align="center">
                                <p>-</p>
                              </TableCell>
                            )}
                            {row.n_fiscal !== null ? (
                              <TableCell align="center">
                                <p>{row.n_fiscal}</p>
                              </TableCell>
                            ) : (
                              <TableCell align="center">
                                <p>-</p>
                              </TableCell>
                            )}
                            <TableCell align="center">
                              <p>{formatData(row.data)}</p>
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
              marginTop: "50px",
            }}
            count={Math.ceil(
              tipoItemFlag === "Produtos"
                ? movimentacaoData.filter(
                    (produto) =>
                      produto.produto
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      produto.unidade
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      produto.quantidade
                        .toString()
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      produto.tipo
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      produto.nome_unidade
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      produto.placa
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      (produto.valor_pago !== null &&
                        produto.valor_pago
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())) ||
                      (produto.n_fiscal !== null &&
                        produto.n_fiscal
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())) ||
                      formatData(produto.data)
                        .toString()
                        .toUpperCase()
                        .includes(search.toUpperCase())
                  ).length / postsPerPage
                : movimentacaoData.filter(
                    (uniforme) =>
                      uniforme.uniforme_epi
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      uniforme.tamanho
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      uniforme.quantidade
                        .toString()
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      uniforme.nome
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      uniforme.nome_unidade
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      uniforme.operador
                        .toUpperCase()
                        .includes(search.toUpperCase()) ||
                      (uniforme.valor_pago !== null &&
                        uniforme.valor_pago
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())) ||
                      (uniforme.n_fiscal !== null &&
                        uniforme.n_fiscal
                          .toString()
                          .toUpperCase()
                          .includes(search.toUpperCase())) ||
                      formatData(uniforme.data)
                        .toString()
                        .toUpperCase()
                        .includes(search.toUpperCase())
                  ).length / postsPerPage
            )}
            color="primary"
            onChange={(event, value) => {
              setCurrentPage(value);
            }}
            page={currentPage}
          />
        </DocumentsContainer>
      ) : null}
    </>
  );
};

export default ListarMovimentacao;
