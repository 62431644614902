import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";

import VerificaLogin from "../../utils/verificaLogin";
import { VerificaTipoContaRH } from "../../utils/verificaTipoConta";

const Rh = () => {
  VerificaLogin();
  VerificaTipoContaRH();
  return (
    <>
      <Navbar />
      <div className="row">
        <Link to="/rh/geradorDocumentos" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Documentos"}
            paragrafo={"Gerador de Documentos"}
          />
        </Link>
        <Link to="/rh/geradorDocumentos/cadastrarFuncionario" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Cadastrar Funcionário"}
            paragrafo={"Cadastre um novo funcionário"}
          />
        </Link>
        <Link to="/rh/geradorDocumentos/editarFuncionario" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Editar Funcionário"}
            paragrafo={"Edite as informações do funcionário"}
          />
        </Link>
        <Link to="/rh/geradorDocumentos/excluirFuncionario" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Excluir Funcionário"}
            paragrafo={"Exclua um funcionário"}
          />
        </Link>
        <Link to="/rh/consultaHorasTrabalhadas" className="link">
          <Card
            cor={"#50b9ff"}
            titulo={"Horas Trabalhadas"}
            paragrafo={"Consulta Horas Trabalhadas"}
          />
        </Link>
        <Link to="/rh/avaliacaoRH" className="link">
          <Card cor={"#F3D62E"} titulo={"Avaliação"} paragrafo={"Avaliação"} />
        </Link>
      </div>
    </>
  );
};

export default Rh;
