import React from "react";
import Navbar from "../../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import Card from "../../../components/card/Card";
import VerificaLogin from "../../../utils/verificaLogin";
import { VerificaTipoContaAdministrador } from "../../../utils/verificaTipoConta";

const ControleEmail = () => {
  VerificaLogin();
  VerificaTipoContaAdministrador();

  return (
    <>
      <Navbar />
      <div className="row">
        <Link
          to="/administradores/controleEmail/controleEmailGrupo"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"E-mails do Grupo"}
            paragrafo={"Controle os E-mails do Grupo"}
          />
        </Link>
        <Link
          to="/administradores/controleEmail/controleEmailFranq"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"E-mails dos Franqueados"}
            paragrafo={"Controle os E-mails dos Franqueados"}
          />
        </Link>
        <Link
          to="/administradores/controleEmail/controleEmailNewsis"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"E-mails do Newsis"}
            paragrafo={"Controle os E-mails do Newsis"}
          />
        </Link>
        <Link
          to="/administradores/controleEmail/controleEmailLabs"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"E-mails da Labs"}
            paragrafo={"Controle os E-mails da Labs"}
          />
        </Link>
      </div>
    </>
  );
};

export default ControleEmail;
