import React from "react";
import Lottie from "lottie-react";
import error from "../../assets/lottie/error.json";

const ErrorAnimation = () => (
  <Lottie
    animationData={error}
    loop={false}
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.1rem",
      width: "30px",
      height: "30px",
    }}
  />
);

export default ErrorAnimation;
