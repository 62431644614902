const tipoSetor = [
  "Operacional",
  "Comercial",
  "Diversos",
  "TI",
  "Biólogo",
  "Diretor",
  "Estagiário",
  "Marketing",
  "Administrativo",
  "RH",
  "Fitolog",
  "Newsis",
];

tipoSetor.sort();

export default tipoSetor;
