import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const VerificaTipoContaRegistrar = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = () => {
      if (
        localStorage.getItem("userType") !== "RH" &&
        localStorage.getItem("userType") !== "Administrador"
      ) {
        navigate("/acessoNegado");
      }
    };
    verifyUser();
  }, []); // eslint-disable-line
};

export const VerificaTipoContaRH = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = () => {
      if (
        localStorage.getItem("userType") !== "RH" &&
        localStorage.getItem("userType") !== "Administrador"
      ) {
        navigate("/acessoNegado");
      }
    };
    verifyUser();
  }, []); // eslint-disable-line
};

export const VerificaTipoContaComercial = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = () => {
      if (
        localStorage.getItem("userType") !== "Comercial" &&
        localStorage.getItem("userType") !== "Administrador"
      ) {
        navigate("/acessoNegado");
      }
    };
    verifyUser();
  }, []); // eslint-disable-line
};

export const VerificaTipoContaOperacional = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = () => {
      if (
        localStorage.getItem("userType") !== "Operacional" &&
        localStorage.getItem("userType") !== "Administrador"
      ) {
        navigate("/acessoNegado");
      }
    };
    verifyUser();
  }, []); // eslint-disable-line
};

export const VerificaTipoContaAdministrador = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = () => {
      if (localStorage.getItem("userType") !== "Administrador") {
        navigate("/acessoNegado");
      }
    };
    verifyUser();
  }, []); // eslint-disable-line
};
