import React from "react";
import styles from "../../styles/introducao.module.css";

const Introducao = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <h1>Bem vindo ao Ecore Web!</h1>
          <p>
            Aqui você pode acessar suas ferramentas de maneira muito mais fácil.
          </p>
          <p>
            É só escolher a opção desejada no menu e aproveitar as ferramentas!
          </p>
        </div>
      </div>
    </>
  );
};

export default Introducao;
