import React from "react";
import { AiFillFacebook, AiFillInstagram, AiFillYoutube } from "react-icons/ai";

const Footer = () => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        width: "100%",
        height: "100px",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AiFillFacebook
        size={35}
        color={"#ffffff"}
        cursor={"pointer"}
        onClick={() => {
          window.open("https://www.facebook.com/unicontrol.controle", "_blank");
        }}
      />

      <AiFillInstagram
        size={35}
        color={"#ffffff"}
        cursor={"pointer"}
        onClick={() => {
          window.open(
            "https://www.instagram.com/unicontrol.controle",
            "_blank"
          );
        }}
      />
      <AiFillYoutube
        size={35}
        color={"#ffffff"}
        cursor={"pointer"}
        onClick={() => {
          window.open(
            "https://www.youtube.com/channel/UC0IS3AbLO7T76Hp3vNGwUVA",
            "_blank"
          );
        }}
      />
    </div>
  );
};

export default Footer;
