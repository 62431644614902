import styled from "styled-components";

const BodyCard = styled.div`
  border-radius: 10px;
  box-shadow: 3px 3px 13px 0px rgba(50, 50, 50, 0.22);
  padding: 30px;
  margin: 20px;
  width: 500px;
  transition: all 0.3s ease-out;
  background-color: white;

  &:hover {
    transform: translateY(-10px);
    cursor: pointer;
  }

  @media (max-width: 1175px) {
    width: 80vw;
  }

  @media (max-width: 600px) {
    width: 90vw;
    margin: 5px;
    &:hover {
      transform: translateY(0px);
    }
  }

  > h2 {
    font-size: 1.5rem;
    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }

  > p {
    color: #a3a5ae;
    font-size: 16px;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export default BodyCard;
