import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import CardContainer from "../../../../styles/CardContainer";
import BodyContainer from "../../../../styles/BodyContainer";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaOperacional } from "../../../../utils/verificaTipoConta";
import { TextField } from "@mui/material";
import Botao from "../../../../styles/Botao";
import api from "../../../../service/api";

import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import BotaoConfirmar from "../../../../styles/BotaoConfirmar";
import BotaoExcluir from "../../../../styles/BotaoExcluir";
import BotaoTransferencia from "../../../../styles/BotaoTransferencia";

import toastError from "../../../../utils/toastError";
import toastSuccess from "../../../../utils/toastSuccess";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Carousel from "react-elastic-carousel";

import tipoControleEstoque from "../../../../data/tipoControleEstoque";

import formatOnlyNumber from "../../../../utils/formatOnlyNumber";

import { MdError } from "react-icons/md";
import LoadingRocket from "../../../../components/animations/LoadingRocket";

const BuscarProdutoCodBarras = () => {
  VerificaLogin();
  VerificaTipoContaOperacional();

  const [codBarras, setCodBarras] = useState("");
  const [codBarrasData, setCodBarrasData] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [open, setOpen] = useState(false);

  const [quantidade, setQuantidade] = useState("");
  const [nf, setNf] = useState("");
  const [placasData, setPlacasData] = useState([]);
  const [placa, setPlaca] = useState("");

  const [movimentacao, setMovimentacao] = useState({});

  const [modalEntradaAux, setModalEntradaAux] = useState(false);
  const [modalBaixaAux, setModalBaixaAux] = useState(false);
  const [tipoItem, setTipoItem] = useState("");

  const [valorPagoProduto, setValorPagoProduto] = useState(0);

  const [operadorData, setOperadorData] = useState([]);
  const [operador, setOperador] = useState("");

  const [unidade, setUnidade] = useState(0);

  const [openModalTransferencia, setOpenModalTransferencia] = useState(false);

  const [unidadesData, setUnidadesData] = useState([]);
  const [unidadeDestino, setUnidadeDestino] = useState("");
  const [quantidadeTransferencia, setQuantidadeTransferencia] = useState(0);

  const [mensagemComparacao, setMensagemComparacao] = useState("");

  const [modalComparaUltimoValorPago, setModalComparaUltimoValorPago] =
    useState(false);

  const handleChangeUnidadeDestino = (event) => {
    setUnidadeDestino(event.target.value);
  };

  useEffect(() => {
    api.get("/api/buscaPlacas").then((response) => {
      setPlacasData(response.data);
    });
  }, []);

  useEffect(() => {
    api
      .post("/api/buscaOperadores", {
        id_unidade: unidade,
      })
      .then((response) => {
        setOperadorData(response.data);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    api.get("/api/buscaUnidades").then((response) => {
      setUnidadesData(response.data);
      setLoading(false);
    });
  }, [loading]);

  const handleChangeCodBarras = (event) => {
    setCodBarras(event.target.value);
  };

  const handleChangeQuantidade = (event) => {
    setQuantidade(event.target.value);
  };

  const handleChangeNF = (event) => {
    setNf(event.target.value);
  };

  const handleChangeTipoItem = (event) => {
    setTipoItem(event.target.value);
  };

  const handleChangeQuantidadeTransferencia = (event) => {
    setQuantidadeTransferencia(event.target.value);
  };

  useEffect(() => {
    setNf(formatOnlyNumber(nf));
  }, [nf]);

  // estilo do modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    overflowY: "auto",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  return (
    <>
      {loading || loading2 ? <LoadingRocket /> : null}
      {
        <Modal
          open={modalComparaUltimoValorPago}
          onClose={() => {
            setModalComparaUltimoValorPago(false);
          }}
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <MdError size={25} color={"#d13637"} />
            </div>
            <p
              style={{
                textAlign: "center",
              }}
            >
              {mensagemComparacao}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              {tipoItem === "Produtos" ? (
                <BotaoConfirmar
                  type={"submit"}
                  value={"Confirmar"}
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setLoading2(true);
                    api
                      .post("/api/movimentacaoIncluirProduto", {
                        id_unidade: movimentacao.unidade_id,
                        id_produto: movimentacao.id,
                        quantidade: quantidade,
                        n_fiscal: nf,
                        valor_pago: valorPagoProduto,
                      })
                      .then((response) => {
                        if (response.data.error) {
                          setLoading2(false);
                          toastError(response.data.error);
                        } else {
                          setLoading2(false);
                          setModalComparaUltimoValorPago(false);
                          toastSuccess("Entrada realizada com sucesso");
                          setQuantidade("");
                          setNf("");
                          setValorPagoProduto("");
                        }
                      });
                  }}
                />
              ) : (
                <BotaoConfirmar
                  type={"submit"}
                  value={"Confirmar"}
                  style={{
                    width: "100%",
                  }}
                  onClick={() => {
                    setLoading2(true);
                    api
                      .post("/api/movimentacaoIncluirUniforme", {
                        id_unidade: movimentacao.id_unidade,
                        id_uniforme: movimentacao.id,
                        quantidade: quantidade,
                        n_fiscal: nf,
                        valor_pago: valorPagoProduto,
                      })
                      .then((response) => {
                        if (response.data.error) {
                          setLoading2(false);
                          toastError(response.data.error);
                        } else {
                          setLoading2(false);
                          setModalComparaUltimoValorPago(false);
                          toastSuccess("Entrada realizada com sucesso");
                          setQuantidade("");
                          setNf("");
                          setValorPagoProduto("");
                        }
                      });
                  }}
                />
              )}
              <BotaoExcluir
                type={"submit"}
                value={"Cancelar"}
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  setModalComparaUltimoValorPago(false);
                }}
              />
            </div>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={openModalTransferencia}
          onClose={() => {
            setOpenModalTransferencia(false);
            setQuantidadeTransferencia(0);
            setUnidadeDestino("");
          }}
        >
          <Box sx={style}>
            <>
              <h3
                style={{
                  marginBottom: "20px",
                }}
              >
                Transferência entre unidades
              </h3>
              {tipoItem === "Produtos" ? (
                <>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <InputLabel>Unidades</InputLabel>
                    <Select
                      value={unidadeDestino}
                      label="Assinatura"
                      onChange={handleChangeUnidadeDestino}
                    >
                      {unidadesData
                        .filter((unidadesparam) => unidade !== unidadesparam.id)
                        .map((unidades, index) => (
                          <MenuItem key={index} value={unidades.id}>
                            {unidades.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    value={
                      !quantidadeTransferencia ? "" : quantidadeTransferencia
                    }
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={handleChangeQuantidadeTransferencia}
                  />
                  <Botao
                    type={"submit"}
                    value={"Transferir"}
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      setLoading2(true);
                      if (!quantidadeTransferencia && !unidadeDestino) {
                        setLoading2(false);
                        return toastError("Preencha todos os campos");
                      }
                      if (quantidadeTransferencia > movimentacao.quantidade) {
                        setLoading2(false);
                        return toastError(
                          "Quantidade de produto maior que a disponível"
                        );
                      }
                      api
                        .post("/api/transicaoEntreUnidadesProduto", {
                          remetente: unidade,
                          destinatario: unidadeDestino,
                          quantidade: quantidadeTransferencia,
                          id_produto: movimentacao.id,
                        })
                        .then((response) => {
                          if (response.data.error) {
                            return toastError(response.data.error);
                          }
                          setLoading2(false);
                          toastSuccess("Transferência realizada com sucesso");
                          setOpenModalTransferencia(false);
                          setQuantidadeTransferencia(0);
                          setUnidadeDestino("");
                        });
                    }}
                  />
                </>
              ) : (
                <>
                  <FormControl
                    sx={{
                      width: "100%",
                    }}
                  >
                    <InputLabel>Unidades</InputLabel>
                    <Select
                      value={unidadeDestino}
                      label="Assinatura"
                      onChange={handleChangeUnidadeDestino}
                    >
                      {unidadesData
                        .filter((unidadesparam) => unidade !== unidadesparam.id)
                        .map((unidades, index) => (
                          <MenuItem key={index} value={unidades.id}>
                            {unidades.nome}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <TextField
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    value={
                      !quantidadeTransferencia ? "" : quantidadeTransferencia
                    }
                    label="Quantidade"
                    variant="outlined"
                    autoComplete="off"
                    onChange={handleChangeQuantidadeTransferencia}
                  />
                  <Botao
                    type={"submit"}
                    value={"Transferir"}
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      setLoading2(true);
                      if (!quantidadeTransferencia && !unidadeDestino) {
                        setLoading2(false);
                        return toastError("Preencha todos os campos");
                      }
                      if (quantidadeTransferencia > movimentacao.quantidade) {
                        setLoading2(false);
                        return toastError(
                          "Quantidade de uniforme/EPI maior que a disponível"
                        );
                      }
                      api
                        .post("/api/transicaoEntreUnidadesUniforme", {
                          remetente: unidade,
                          destinatario: unidadeDestino,
                          quantidade: quantidadeTransferencia,
                          id_uniforme: movimentacao.id,
                        })
                        .then((response) => {
                          if (response.data.error) {
                            return toastError(response.data.error);
                          }
                          setLoading2(false);
                          toastSuccess("Transferência realizada com sucesso");
                          setOpenModalTransferencia(false);
                          setQuantidadeTransferencia(0);
                          setUnidadeDestino("");
                        });
                    }}
                  />
                </>
              )}
            </>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
            setQuantidade("");
            setNf("");
            setPlaca("");
            setValorPagoProduto("");
          }}
        >
          <Box sx={style}>
            <Carousel showArrows={false}>
              {codBarrasData &&
                codBarrasData.map((item, index) => {
                  if (tipoItem === "Produtos") {
                    return (
                      <div key={index}>
                        <h3
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          Informações do Item
                        </h3>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          NOME: {item.nome}
                        </p>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          MEDIDA: {item.unidade}
                        </p>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          QUANTIDADE: {item.quantidade}
                        </p>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          UNIDADE: {item.nome_unidade.toUpperCase()}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            marginBottom: "1rem",
                          }}
                        >
                          <BotaoConfirmar
                            style={{
                              width: "100%",
                            }}
                            type={"submit"}
                            value={"Entrada"}
                            onClick={() => {
                              setMovimentacao(item);
                              setQuantidade("");
                              setNf("");
                              setValorPagoProduto("");
                              setModalEntradaAux(true);
                              setOpen(false);
                            }}
                          />
                          <BotaoExcluir
                            style={{
                              width: "100%",
                            }}
                            type={"submit"}
                            value={"Baixa"}
                            onClick={() => {
                              setMovimentacao(item);
                              setQuantidade("");
                              setPlaca("");
                              setModalBaixaAux(true);
                              setOpen(false);
                            }}
                          />
                        </div>
                        <BotaoTransferencia
                          type={"submit"}
                          value={"Transferir"}
                          onClick={() => {
                            setMovimentacao(item);
                            setUnidade(item.unidade_id);
                            setOpen(false);
                            setOpenModalTransferencia(true);
                          }}
                        />
                      </div>
                    );
                  } else if (tipoItem === "Uniformes e EPI's") {
                    return (
                      <div key={index}>
                        <h3
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          Informações do Item
                        </h3>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          NOME: {item.nome}
                        </p>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          TAMANHO: {item.tamanho}
                        </p>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          QUANTIDADE: {item.quantidade}
                        </p>
                        <p
                          style={{
                            fontFamily: "Arial",
                            paddingBottom: "10px",
                          }}
                        >
                          UNIDADE: {item.nome_unidade.toUpperCase()}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "10px",
                            marginBottom: "1rem",
                          }}
                        >
                          <BotaoConfirmar
                            style={{
                              width: "100%",
                            }}
                            type={"submit"}
                            value={"Entrada"}
                            onClick={() => {
                              setMovimentacao(item);
                              setQuantidade("");
                              setNf("");
                              setValorPagoProduto("");
                              setModalEntradaAux(true);
                              setOpen(false);
                            }}
                          />
                          <BotaoExcluir
                            style={{
                              width: "100%",
                            }}
                            type={"submit"}
                            value={"Baixa"}
                            onClick={() => {
                              setUnidade(item.id_unidade);
                              setMovimentacao(item);
                              setLoading(true);
                              setQuantidade("");
                              setModalBaixaAux(true);
                              setOpen(false);
                            }}
                          />
                        </div>
                        <BotaoTransferencia
                          type={"submit"}
                          value={"Transferir"}
                          onClick={() => {
                            setMovimentacao(item);
                            setUnidade(item.id_unidade);
                            setOpen(false);
                            setOpenModalTransferencia(true);
                          }}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </Carousel>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={modalEntradaAux}
          onClose={() => {
            setModalEntradaAux(false);
          }}
        >
          <Box sx={style}>
            <h3>Entrada de Produto</h3>
            <div>
              <TextField
                style={{
                  width: "100%",
                  marginTop: "20px",
                }}
                label="Quantidade"
                variant="outlined"
                autoComplete="off"
                value={quantidade}
                onChange={handleChangeQuantidade}
              />
              <TextField
                sx={{
                  width: "100%",
                  marginTop: "10px",
                }}
                value={!valorPagoProduto ? "" : valorPagoProduto}
                label="Valor Pago"
                placeholder="R$ 00,00"
                variant="outlined"
                autoComplete="off"
                onChange={(e) => setValorPagoProduto(e.target.value)}
              />
              <TextField
                style={{
                  width: "100%",
                  marginTop: "10px",
                }}
                label="Nota Fiscal"
                variant="outlined"
                autoComplete="off"
                value={formatOnlyNumber(nf)}
                onChange={handleChangeNF}
              />
              <Botao
                style={{
                  width: "100%",
                }}
                type="submit"
                value={"Confirmar"}
                onClick={() => {
                  setLoading(true);
                  if (tipoItem === "Produtos") {
                    if (!quantidade || !nf || !valorPagoProduto) {
                      setLoading(false);
                      toastError("Preencha todos os campos");
                    } else {
                      setModalEntradaAux(false);
                      api
                        .post("/api/comparaValoresDeCompraProduto", {
                          valor_pago: valorPagoProduto,
                          id_produto: movimentacao.id,
                        })
                        .then((response) => {
                          if (response.data !== true) {
                            setLoading(false);
                            setMensagemComparacao(response.data);
                            return setModalComparaUltimoValorPago(true);
                          }
                          api
                            .post("/api/movimentacaoIncluirProduto", {
                              id_unidade: movimentacao.unidade_id,
                              id_produto: movimentacao.id,
                              quantidade: quantidade,
                              n_fiscal: nf,
                              valor_pago: valorPagoProduto,
                            })
                            .then((response) => {
                              if (response.data.error) {
                                toastError(response.data.error);
                              } else {
                                setLoading(false);
                                toastSuccess("Entrada realizada com sucesso");
                                setQuantidade("");
                                setNf("");
                                setValorPagoProduto("");
                              }
                            });
                        });
                    }
                  } else if (tipoItem === "Uniformes e EPI's") {
                    if (!quantidade || !nf || !valorPagoProduto) {
                      setLoading(false);
                      toastError("Preencha todos os campos");
                    } else {
                      setModalEntradaAux(false);
                      api
                        .post("/api/comparaValoresDeCompraUniforme", {
                          valor_pago: valorPagoProduto,
                          id_uniforme: movimentacao.id,
                        })
                        .then((response) => {
                          if (response.data !== true) {
                            setLoading(false);
                            setMensagemComparacao(response.data);
                            return setModalComparaUltimoValorPago(true);
                          }
                          api
                            .post("/api/movimentacaoIncluirUniforme", {
                              id_unidade: movimentacao.id_unidade,
                              id_uniforme: movimentacao.id,
                              quantidade: quantidade,
                              n_fiscal: nf,
                              valor_pago: valorPagoProduto,
                            })
                            .then((response) => {
                              if (response.data.error) {
                                toastError(response.data.error);
                              } else {
                                setLoading(false);
                                toastSuccess("Entrada realizada com sucesso");
                                setQuantidade("");
                                setNf("");
                                setValorPagoProduto("");
                              }
                            });
                        });
                    }
                  } else {
                    setLoading(false);
                    toastError("Erro ao realizar entrada");
                  }
                }}
              />
            </div>
          </Box>
        </Modal>
      }
      {
        <Modal
          open={modalBaixaAux}
          onClose={() => {
            setModalBaixaAux(false);
            setOperador("");
            setOperadorData([]);
          }}
        >
          <Box sx={style}>
            {tipoItem === "Produtos" ? (
              <h3>Baixa de Produto</h3>
            ) : (
              <h3>Baixa de Uniforme</h3>
            )}
            <div>
              <TextField
                style={{
                  width: "100%",
                  marginTop: "20px",
                }}
                label="Quantidade"
                variant="outlined"
                autoComplete="off"
                value={quantidade}
                onChange={handleChangeQuantidade}
              />
              {tipoItem === "Produtos" ? (
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <InputLabel
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Placa do Carro
                  </InputLabel>
                  <Select
                    style={{
                      marginTop: "10px",
                    }}
                    value={placa}
                    label="Placa do Carro"
                    onChange={(e) => setPlaca(e.target.value)}
                  >
                    {placasData
                      .filter((placas) => placas.placa !== "-")
                      .map((placas, index) => (
                        <MenuItem key={index} value={placas.id}>
                          {placas.placa}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <InputLabel
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Operadores
                  </InputLabel>
                  <Select
                    style={{
                      marginTop: "10px",
                    }}
                    value={operador}
                    label="Operadores"
                    onChange={(e) => setOperador(e.target.value)}
                  >
                    {operadorData
                      .filter((operador) => operador.nome !== "-")
                      .map((operador, index) => (
                        <MenuItem key={index} value={operador.id}>
                          {operador.nome}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              <Botao
                style={{
                  width: "100%",
                }}
                type="submit"
                value={"Confirmar"}
                onClick={() => {
                  setLoading(true);
                  setLoading2(true);
                  if (tipoItem === "Produtos") {
                    if (!placa || !quantidade) {
                      setLoading(false);
                      toastError("Preencha todos os campos");
                    } else {
                      if (quantidade > movimentacao.quantidade) {
                        setLoading(false);
                        toastError("Quantidade maior que a disponível");
                      } else {
                        api
                          .post("/api/movimentacaoDarBaixaProduto", {
                            id_unidade: movimentacao.unidade_id,
                            id_produto: movimentacao.id,
                            quantidade: quantidade,
                            id_placa: placa,
                          })
                          .then((response) => {
                            if (response.data.error) {
                              setLoading(false);
                              toastError(response.data.error);
                            } else {
                              setModalBaixaAux(false);
                              if (
                                movimentacao.quantidade - quantidade <
                                movimentacao.quantidade_minima
                              ) {
                                api.post("/api/enviarEmailOperacional", {
                                  produto: movimentacao.nome,
                                  unidade: movimentacao.unidade,
                                });
                              }
                              setLoading2(false);
                              setLoading(false);
                              toastSuccess("Baixa realizada com sucesso");
                              setQuantidade("");
                              setPlaca("");
                            }
                          });
                      }
                    }
                  } else if (tipoItem === "Uniformes e EPI's") {
                    setLoading(true);
                    if (!operador || !quantidade) {
                      setLoading(false);
                      toastError("Preencha todos os campos");
                    } else {
                      if (quantidade > movimentacao.quantidade) {
                        setLoading(false);
                        toastError("Quantidade maior que a disponível");
                      } else {
                        api
                          .post("/api/movimentacaoDarBaixaUniforme", {
                            id_unidade: movimentacao.id_unidade,
                            id_uniforme: movimentacao.id,
                            quantidade: quantidade,
                            id_operador: operador,
                          })
                          .then((response) => {
                            if (response.data.error) {
                              setLoading(false);
                              toastError(response.data.error);
                            } else {
                              setModalBaixaAux(false);
                              if (
                                movimentacao.quantidade - quantidade <
                                movimentacao.quantidade_minima
                              ) {
                                api.post("/api/enviarEmailOperacional", {
                                  produto: movimentacao.nome,
                                  unidade: movimentacao.unidade,
                                });
                              }
                              setLoading2(false);
                              setLoading(false);
                              toastSuccess("Baixa realizada com sucesso");
                              setQuantidade("");
                              setPlaca("");
                            }
                          });
                      }
                    }
                  } else {
                    setLoading(false);
                    toastError("Erro ao realizar baixa");
                  }
                }}
              />
            </div>
          </Box>
        </Modal>
      }
      <Navbar />
      <CardContainer>
        <BodyContainer>
          <h2>Buscar Produto ou Uniforme/EPI pelo Código de Barras</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <FormControl
              sx={{
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <InputLabel>Tipo de Item</InputLabel>
              <Select
                label="Tipo de Item"
                value={tipoItem}
                onChange={handleChangeTipoItem}
              >
                {tipoControleEstoque.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{
                width: "100%",
              }}
              autoFocus
              label="Código de Barras"
              variant="outlined"
              autoComplete="off"
              value={codBarras}
              onChange={handleChangeCodBarras}
            />
            <Botao
              style={{
                marginTop: "50px",
                width: "100%",
              }}
              type="submit"
              value="Buscar Código de Barras"
              onClick={() => {
                if (!codBarras) {
                  return toastError("Preencha o campo de código de barras");
                }
                setCodBarras("");
                setLoading(true);
                if (tipoItem === "Produtos") {
                  api
                    .post("/api/buscarProdutoPeloCodBarras", {
                      cod_barras: codBarras,
                    })
                    .then((response) => {
                      if (response.data.error) {
                        setLoading(false);
                        toastError(response.data.error);
                      } else {
                        setCodBarrasData(response.data);
                        setLoading(false);
                        setOpen(true);
                      }
                    });
                } else if (tipoItem === "Uniformes e EPI's") {
                  api
                    .post("/api/buscarUniformePeloCodBarras", {
                      cod_barras: codBarras,
                    })
                    .then((response) => {
                      if (response.data.error) {
                        setLoading(false);
                        toastError(response.data.error);
                      } else {
                        setCodBarrasData(response.data);
                        setLoading(false);
                        setOpen(true);
                      }
                    });
                } else {
                  setLoading(false);
                  toastError("Selecione um tipo de item");
                }
              }}
            />
          </form>
        </BodyContainer>
      </CardContainer>
    </>
  );
};

export default BuscarProdutoCodBarras;
