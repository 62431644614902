import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaOperacional } from "../../../../utils/verificaTipoConta";
import api from "../../../../service/api";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DocumentsContainer from "../../../../styles/DocumentsContainer";
import Paper from "@mui/material/Paper";
import formatData from "../../../../utils/formatData";

import Pagination from "@mui/material/Pagination";
import { IoMdTime } from "react-icons/io";
import { ImList2 } from "react-icons/im";

import { Box, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";

// Material UI Date-Picker
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ptBR } from "date-fns/locale";

import TextField from "@mui/material/TextField";
import Botao from "../../../../styles/Botao";
import BotaoExcluir from "../../../../styles/BotaoExcluir";
import BotaoConfirmar from "../../../../styles/BotaoConfirmar";

import toastError from "../../../../utils/toastError";
import toastSuccess from "../../../../utils/toastSuccess";

import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import LoadingRocket from "../../../../components/animations/LoadingRocket";

import ShopAnimation from "../../../../components/animations/ShopAnimation";

const SolicitacaoPedidos = () => {
  VerificaLogin();
  VerificaTipoContaOperacional();

  const [loading, setLoading] = useState(true);
  const [solicitacoes, setSolicitacoes] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(8);

  const [openModalProduto, setOpenModalProduto] = useState(false);
  const [idPedido, setIdPedido] = useState("");
  const [produtos, setProdutos] = useState([]);

  const [openModalStatus, setOpenModalStatus] = useState(false);

  const [statusDoPedido, setStatusDoPedido] = useState("");

  const [data, setData] = useState(null);

  const [textFieldAdd, setTextFieldAdd] = useState(1);

  const [object, setObject] = useState("");
  const [concat, setConcat] = useState("");

  useEffect(() => {
    api.get("/api/buscarPedidos").then((response) => {
      setSolicitacoes(response.data);
      setLoading(false);
    });
  }, [loading]);

  useEffect(() => {
    setConcat(Object.values(object).join("/"));
  }, [object]);

  // eslint-disable-next-line no-unused-vars
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    api
      .post("/api/buscarProdutosDoPedido", {
        id_pedido: idPedido,
      })
      .then((response) => {
        setProdutos(response.data);
      });
  }, [openModalProduto, idPedido]);

  const handleChangeData = (date) => {
    setData(date);
  };

  // estilo do modal
  const styleListarProdutos = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    maxHeight: "80%",
    overflowY: "auto",
    outline: "none",
  };

  const styleAlterar = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    outline: "none",
  };

  const styleListarProdutosMobile = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 355,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    fontSize: "0.7rem",
    maxHeight: "80%",
    overflowY: "auto",
    outline: "none",
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const verificaModal = () => {
    if (statusDoPedido === "Solicitado") {
      return (
        <>
          <LocalizationProvider
            adapterLocale={ptBR}
            dateAdapter={AdapterDateFns}
          >
            <DatePicker
              minDate={new Date().setMonth(new Date().getMonth() - 6)}
              maxDate={new Date().setMonth(new Date().getMonth() + 6)}
              label="Data de Início"
              value={data}
              onChange={handleChangeData}
              renderInput={(params) => (
                <TextField
                  sx={{ width: "100%", marginBottom: "10px" }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          <Botao
            type={"submit"}
            style={{
              width: "100%",
            }}
            value={"Alterar Status"}
            onClick={() => {
              if (!data) {
                toastError("Preencha todos os campos");
              } else {
                api
                  .post("/api/alterarStatusPedidoComprado", {
                    id_pedido: idPedido,
                    data_nf: data,
                    numero_nf: concat,
                  })
                  .then((response) => {
                    if (response.data === "Status alterado com sucesso") {
                      toastSuccess(response.data);
                      setTextFieldAdd(1);
                      setObject("");
                      setConcat("");
                      setData(null);
                      setOpenModalStatus(false);
                      setLoading(true);
                    } else {
                      toastError(response.data.error);
                    }
                  });
              }
            }}
          />
          <BotaoExcluir
            style={{
              width: "100%",
              marginTop: "10px",
            }}
            type="submit"
            value={"Cancelar Solicitação"}
            onClick={() => {
              api
                .post("/api/alterarStatusPedidoCancelado", {
                  id_pedido: idPedido,
                })
                .then((response) => {
                  if (response.data === "Pedido cancelado com sucesso") {
                    toastSuccess(response.data);
                    setTextFieldAdd(1);
                    setObject("");
                    setConcat("");
                    setData(null);
                    setOpenModalStatus(false);
                    setLoading(true);
                  } else {
                    toastError(response.data.error);
                  }
                });
            }}
          />
        </>
      );
    } else if (statusDoPedido === "Comprado") {
      return (
        <>
          {[...Array(textFieldAdd)].map((name, index) => (
            <TextField
              style={{
                width: "100%",
                marginBottom: "10px",
              }}
              key={index}
              label="Número Nota Fiscal"
              variant="outlined"
              autoComplete="off"
              value={object[index] || ""}
              onChange={(e) => {
                setObject({ ...object, [index]: e.target.value });
              }}
            />
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <MdRemoveCircle
              size={25}
              color="#d13737"
              cursor={"pointer"}
              onClick={() => {
                if (textFieldAdd > 1) {
                  setTextFieldAdd(textFieldAdd - 1);
                  delete object[textFieldAdd - 1];
                  setConcat(Object.values(object).join("/"));
                }
              }}
            />
            <MdAddCircle
              size={25}
              color="#3992ff"
              cursor={"pointer"}
              onClick={() => {
                setTextFieldAdd(textFieldAdd + 1);
              }}
            />
          </div>
          <BotaoConfirmar
            style={{
              width: "100%",
            }}
            type={"submit"}
            value="Finalizar Solicitação"
            onClick={() => {
              api
                .post("/api/alterarStatusPedidoFinalizado", {
                  id_pedido: idPedido,
                  data_nf: data,
                  numero_nf: concat,
                })
                .then((response) => {
                  if (response.data === "Status alterado com sucesso") {
                    toastSuccess(response.data);
                    setOpenModalStatus(false);
                    setLoading(true);
                  } else {
                    toastError(response.data.error);
                  }
                });
            }}
          />
        </>
      );
    }
  };

  return (
    <>
      <Modal
        open={openModalProduto && !loading}
        onClose={() => {
          setOpenModalProduto(false);
          setIdPedido("");
        }}
      >
        <Box
          sx={
            window.innerWidth > 800
              ? styleListarProdutos
              : styleListarProdutosMobile
          }
        >
          <>
            <h2
              style={{
                marginBottom: "20px",
              }}
            >
              Lista de Produtos
            </h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            />
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                marginBottom: "50px",
              }}
            >
              <h4
                style={{
                  textAlign: "start",
                }}
              >
                Quantidade
              </h4>
              <h4
                style={{
                  textAlign: "center",
                }}
              >
                Produto
              </h4>
              <h4
                style={{
                  textAlign: "end",
                }}
              >
                Unidade/Tamanho
              </h4>
            </div>
            {produtos.map((produto, index) => (
              <div
                key={index}
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  borderBottom: "1px solid #ccc",
                  marginBottom: "10px",
                }}
              >
                <p
                  style={{
                    textAlign: "start",
                    paddingBottom: "10px",
                  }}
                >
                  {produto.quantidade}
                </p>
                {produto.nome.length > 15 ? (
                  <Tooltip title={produto.nome}>
                    <p
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {produto.nome.substring(0, 15)}...
                    </p>
                  </Tooltip>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {produto.nome}
                  </p>
                )}
                <p
                  style={{
                    textAlign: "end",
                  }}
                >
                  {produto.unidade}
                </p>
              </div>
            ))}
          </>
        </Box>
      </Modal>
      <Modal
        open={openModalStatus}
        onClose={() => {
          setOpenModalStatus(false);
          setIdPedido("");
          setTextFieldAdd(1);
          setObject("");
          setConcat("");
          setData(null);
        }}
      >
        <Box sx={styleAlterar}>
          <>
            <h3
              style={{
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Alterar Status do Pedido
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            />
            {verificaModal()}
          </>
        </Box>
      </Modal>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <DocumentsContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "50px",
            justifyContent: "center",
            gap: "2rem",
            backgroundColor: "#bdbdbd33",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
        >
          <ShopAnimation />
          <h1
            style={{
              color: "#1e1e1e",
              fontWeight: "500",
              fontSize: "2rem",
            }}
          >
            Solicitação de Pedidos
          </h1>
        </div>
        <TableContainer component={Paper}>
          <Table size="medium" aria-label="a dense table">
            <TableHead
              sx={
                window.innerWidth > 768
                  ? {
                      background: "linear-gradient(45deg, #055f89, #0187c6)",
                    }
                  : {
                      background: "#055f89",
                    }
              }
            >
              <TableRow>
                <TableCell
                  sx={{
                    width: "15%",
                    color: "#ffffff",
                  }}
                >
                  <p>Franquia</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "15%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <p>Solicitado por</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <p>Status</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <p>NF</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <p>Data da NF</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <p>Solicitado Dia</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <p>Alterar Status</p>
                </TableCell>
                <TableCell
                  sx={{
                    width: "10%",
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                >
                  <p>Produtos</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {solicitacoes
                .slice(indexOfFirstPost, indexOfLastPost)
                .map((solicitacao, index) => {
                  const verificaCorStatus = () => {
                    if (solicitacao.nome_status === "Solicitado") {
                      return "#f6dd00";
                    } else if (solicitacao.nome_status === "Comprado") {
                      return "#3992ff";
                    } else if (solicitacao.nome_status === "Finalizado") {
                      return "#3ebf2d";
                    } else if (solicitacao.nome_status === "Cancelado") {
                      return "#d13737";
                    }
                  };

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {solicitacao.franquia.length > 15 ? (
                          <Tooltip title={solicitacao.franquia.toUpperCase()}>
                            <p>
                              {solicitacao.franquia
                                .toUpperCase()
                                .substring(0, 15)}
                              ...
                            </p>
                          </Tooltip>
                        ) : (
                          <p>{solicitacao.franquia.toUpperCase()}</p>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {solicitacao.nome.length > 15 ? (
                          <Tooltip title={solicitacao.nome.toUpperCase()}>
                            <p>
                              {solicitacao.nome.toUpperCase().substring(0, 15)}
                              ...
                            </p>
                          </Tooltip>
                        ) : (
                          <p>{solicitacao.nome.toUpperCase()}</p>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <p
                          style={{
                            backgroundColor: verificaCorStatus(),
                            color: "#ffffff",
                            borderRadius: "5px",
                            padding: "4px 2px",
                          }}
                        >
                          {solicitacao.nome_status.toUpperCase()}
                        </p>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {solicitacao.numero_nota_fiscal === null ? (
                          <p>-</p>
                        ) : (
                          <p>{solicitacao.numero_nota_fiscal}</p>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {solicitacao.numero_nota_fiscal === null ? (
                          <p>-</p>
                        ) : (
                          <p>{formatData(solicitacao.data_nota_fiscal)}</p>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <p>{formatData(solicitacao.data_solicitacao)}</p>
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <IoMdTime
                          style={{
                            cursor: "pointer",
                            color: "#05628f",
                          }}
                          size={25}
                          onClick={() => {
                            setIdPedido(solicitacao.id);
                            setStatusDoPedido(solicitacao.nome_status);
                            const status = solicitacao.nome_status;
                            if (status === "Cancelado") {
                              setIdPedido("");
                              toastError(
                                "Não é possível alterar o status de um pedido cancelado"
                              );
                            } else if (status === "Finalizado") {
                              setIdPedido("");
                              toastError(
                                "Não é possível alterar o status de um pedido finalizado"
                              );
                            } else {
                              setOpenModalStatus(true);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <ImList2
                          style={{
                            cursor: "pointer",
                            color: "#05628f",
                          }}
                          size={20}
                          onClick={() => {
                            setLoading(true);
                            setIdPedido(solicitacao.id);
                            setOpenModalProduto(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            marginTop: "50px",
          }}
          count={Math.ceil(solicitacoes.length / postsPerPage)}
          color="primary"
          onChange={(event, value) => {
            setCurrentPage(value);
          }}
          page={currentPage}
        />
      </DocumentsContainer>
    </>
  );
};

export default SolicitacaoPedidos;
