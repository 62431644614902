import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";

import VerificaLogin from "../../utils/verificaLogin";
import { VerificaTipoContaOperacional } from "../../utils/verificaTipoConta";

const Operacional = () => {
  VerificaLogin();
  VerificaTipoContaOperacional();
  return (
    <>
      <Navbar />
      <div className="row">
        <Link
          to="/operacional/controleEstoque/cadastrarProduto"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"Cadastrar Produto"}
            paragrafo={"Cadastre um novo produto para o estoque"}
          />
        </Link>
        <Link
          to="/operacional/controleEstoque/cadastrarUniforme"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"Cadastrar Uniforme / EPI"}
            paragrafo={"Cadastre um novo uniforme ou EPI para o estoque"}
          />
        </Link>
        <Link to="/operacional/controleEstoque" className="link">
          <Card
            cor={"#33ae40"}
            titulo={"Estoque"}
            paragrafo={"Controle de Estoque"}
          />
        </Link>
        <Link
          to="/operacional/controleEstoque/buscarProdutoPeloCodBarras"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"Código de Barras"}
            paragrafo={"Buscar Produto pelo código de Barras"}
          />
        </Link>
        <Link
          to="/operacional/controleEstoque/listarMovimentacao"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"Movimentacão de Estoque"}
            paragrafo={"Liste todas as movimentações do estoque"}
          />
        </Link>
        <Link
          to="/operacional/controleEstoque/solicitacaoPedidos"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"Solicitacão de Pedidos"}
            paragrafo={"Solicitações de compras dos Franqueados"}
          />
        </Link>
        <Link
          to="/operacional/controleEstoque/reativarProdutos"
          className="link"
        >
          <Card
            cor={"#33ae40"}
            titulo={"Reativar Produtos"}
            paragrafo={"Reative produtos que foram inativados"}
          />
        </Link>
        <Link to="/operacional/avaliacaoOperacional" className="link">
          <Card cor={"#F3D62E"} titulo={"Avaliação"} paragrafo={"Avaliação"} />
        </Link>
      </div>
    </>
  );
};

export default Operacional;
