import styled from "styled-components";

const BotaoExcluir = styled.input`
  margin-top: 1.5rem;
  padding: 0.4rem;
  background: #d13637;
  cursor: pointer;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  border-radius: 5px;
  transition: all 0.3s ease;
  height: 40px;
  :hover {
    background: #ff4444;
  }
`;

export default BotaoExcluir;
