import React, { useState } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaAdministrador } from "../../../../utils/verificaTipoConta";
import InfosAnimation from "../../../../components/animations/InfosAnimation";
import CardContainer from "../../../../styles/CardContainer";
import BodyContainer from "../../../../styles/BodyContainer";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import tipoSetor from "../../../../data/tipoSetor";
import tipoEmpresa from "../../../../data/tipoEmpresa";
import tipoUnidade from "../../../../data/tipoUnidade";
import tipoOffice from "../../../../data/tipoOffice";
import tipoWindows from "../../../../data/tipoWindows";

import Botao from "../../../../styles/Botao";

import api from "../../../../service/api";
import toastSuccess from "../../../../utils/toastSuccess";
import toastError from "../../../../utils/toastError";
import LoadingRocket from "../../../../components/animations/LoadingRocket";
import scrollToTop from "../../../../utils/scrollToTop";

const CadastrarInfo = () => {
  VerificaLogin();
  VerificaTipoContaAdministrador();

  const [nome, setNome] = useState("");
  const [setor, setSetor] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [unidade, setUnidade] = useState("");

  const [usuarioRede, setUsuarioRede] = useState("");
  const [senhaRede, setSenhaRede] = useState("");
  const [email, setEmail] = useState("");
  const [senhaEmail, setSenhaEmail] = useState("");

  const [emailDiscord, setEmailDiscord] = useState("");
  const [senhaDiscord, setSenhaDiscord] = useState("");

  const [notebook, setNotebook] = useState("");
  const [nomeNotebook, setNomeNotebook] = useState("");
  const [office, setOffice] = useState("");
  const [windows, setWindows] = useState("");

  const [patrimonioNotebook, setPatrimonioNotebook] = useState("");
  const [patrimonioCooler, setPatrimonioCooler] = useState("");

  const [loading, setLoading] = useState(false);

  const handleChangeNome = (event) => setNome(event.target.value);
  const handleChangeSetor = (event) => setSetor(event.target.value);
  const handleChangeEmpresa = (event) => setEmpresa(event.target.value);
  const handleChangeUnidade = (event) => setUnidade(event.target.value);

  const handleChangeUsuarioRede = (event) => setUsuarioRede(event.target.value);
  const handleChangeSenhaRede = (event) => setSenhaRede(event.target.value);
  const handleChangeEmail = (event) => setEmail(event.target.value);
  const handleChangeSenhaEmail = (event) => setSenhaEmail(event.target.value);

  const handleChangeEmailDiscord = (event) =>
    setEmailDiscord(event.target.value);
  const handleChangeSenhaDiscord = (event) =>
    setSenhaDiscord(event.target.value);

  const handleChangeNotebook = (event) => setNotebook(event.target.value);
  const handleChangeNomeNotebook = (event) =>
    setNomeNotebook(event.target.value);
  const handleChangeOffice = (event) => setOffice(event.target.value);
  const handleChangeWindows = (event) => setWindows(event.target.value);

  const handleChangePatrimonioNotebook = (event) =>
    setPatrimonioNotebook(event.target.value);
  const handleChangePatrimonioCooler = (event) =>
    setPatrimonioCooler(event.target.value);

  const limpaCampos = () => {
    setNome("");
    setSetor("");
    setEmpresa("");
    setUnidade("");
    setUsuarioRede("");
    setSenhaRede("");
    setEmail("");
    setSenhaEmail("");
    setEmailDiscord("");
    setSenhaDiscord("");
    setNotebook("");
    setNomeNotebook("");
    setOffice("");
    setWindows("");
    setPatrimonioNotebook("");
    setPatrimonioCooler("");
  };

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <BodyContainer
          style={{
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <InfosAnimation />
            <h1
              style={
                window.innerWidth > 768
                  ? {
                      color: "#1e1e1e",

                      fontSize: "1.8rem",
                    }
                  : {
                      textAlign: "center",
                      fontSize: "1.4rem",
                    }
              }
            >
              Informações Gerais
            </h1>
          </div>
          <h3>Dados</h3>
          <TextField
            label="Nome"
            variant="outlined"
            autoComplete="off"
            value={nome}
            onChange={handleChangeNome}
          />
          <FormControl>
            <InputLabel>Setor</InputLabel>
            <Select value={setor} onChange={handleChangeSetor} label="Setor">
              {tipoSetor.map((setor, index) => (
                <MenuItem key={index} value={setor}>
                  {setor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Empresa</InputLabel>
            <Select
              value={empresa}
              onChange={handleChangeEmpresa}
              label="Empresa"
            >
              {tipoEmpresa.map((empresa, index) => (
                <MenuItem key={index} value={empresa}>
                  {empresa}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Unidade</InputLabel>
            <Select
              value={unidade}
              onChange={handleChangeUnidade}
              label="Unidade"
            >
              {tipoUnidade.map((unidade, index) => (
                <MenuItem key={index} value={unidade}>
                  {unidade}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h3
            style={{
              marginTop: "50px",
            }}
          >
            Rede
          </h3>
          <TextField
            label="Usuário da Rede"
            variant="outlined"
            autoComplete="off"
            value={usuarioRede}
            onChange={handleChangeUsuarioRede}
          />
          <TextField
            label="Senha da Rede"
            variant="outlined"
            autoComplete="off"
            value={senhaRede}
            onChange={handleChangeSenhaRede}
          />
          <TextField
            label="E-mail"
            variant="outlined"
            autoComplete="off"
            value={email}
            onChange={handleChangeEmail}
          />
          <TextField
            label="Senha do E-mail"
            variant="outlined"
            autoComplete="off"
            value={senhaEmail}
            onChange={handleChangeSenhaEmail}
          />
          <h3
            style={{
              marginTop: "50px",
            }}
          >
            Discord
          </h3>
          <TextField
            label="E-mail do Discord"
            variant="outlined"
            autoComplete="off"
            value={emailDiscord}
            onChange={handleChangeEmailDiscord}
          />
          <TextField
            label="Senha do Discord"
            variant="outlined"
            autoComplete="off"
            value={senhaDiscord}
            onChange={handleChangeSenhaDiscord}
          />
          <h3
            style={{
              marginTop: "50px",
            }}
          >
            Sistema
          </h3>
          <TextField
            label="Marca do Notebook"
            variant="outlined"
            autoComplete="off"
            value={notebook}
            onChange={handleChangeNotebook}
          />
          <TextField
            label="Nome do Notebook"
            variant="outlined"
            autoComplete="off"
            value={nomeNotebook}
            onChange={handleChangeNomeNotebook}
          />
          <FormControl>
            <InputLabel>Office</InputLabel>
            <Select value={office} onChange={handleChangeOffice} label="Office">
              {tipoOffice.map((office, index) => (
                <MenuItem key={index} value={office}>
                  {office}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel>Windows</InputLabel>
            <Select
              value={windows}
              onChange={handleChangeWindows}
              label="Windows"
            >
              {tipoWindows.map((windows, index) => (
                <MenuItem key={index} value={windows}>
                  {windows}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <h3
            style={{
              marginTop: "50px",
            }}
          >
            Patrimônio
          </h3>
          <TextField
            label="Patrimônio do Notebook"
            variant="outlined"
            autoComplete="off"
            value={patrimonioNotebook}
            onChange={handleChangePatrimonioNotebook}
          />
          <TextField
            label="Patrimônio do Cooler"
            variant="outlined"
            autoComplete="off"
            value={patrimonioCooler}
            onChange={handleChangePatrimonioCooler}
          />
          <Botao
            value={"Cadastrar"}
            type={"submit"}
            onClick={() => {
              if (
                !nome ||
                !setor ||
                !empresa ||
                !unidade ||
                !usuarioRede ||
                !senhaRede ||
                !email ||
                !senhaEmail ||
                !emailDiscord ||
                !senhaDiscord ||
                !notebook ||
                !nomeNotebook ||
                !office ||
                !windows ||
                !patrimonioNotebook ||
                !patrimonioCooler
              ) {
                return toastError("Preencha todos os campos");
              }
              setLoading(true);
              api
                .post("api/cadastrarInfo", {
                  nome,
                  setor,
                  empresa,
                  unidade,
                  usuario_rede: usuarioRede,
                  senha_rede: senhaRede,
                  email,
                  senha_email: senhaEmail,
                  email_discord: emailDiscord,
                  senha_discord: senhaDiscord,
                  marca_notebook: notebook,
                  nome_notebook: nomeNotebook,
                  office,
                  windows,
                  patrimonio_notebook: patrimonioNotebook,
                  cooler_notebook: patrimonioCooler,
                })
                .then((response) => {
                  toastSuccess(response.data.sucesso);
                  setLoading(false);
                  limpaCampos();
                  scrollToTop();
                });
            }}
          />
        </BodyContainer>
      </CardContainer>
    </>
  );
};

export default CadastrarInfo;
