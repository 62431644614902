const tipoServico = [
  "Controle de insetos",
  "Higienização dos Reservatórios de Água",
  "Análise Microbiológica de Água",
  "Higienização de bebedouros",
  "Manejo de Animais Silvestres",
  "Análise Físio química",
  "Locação de Armadilhas Biológicas",
  "Manejo de Pombos",
  "Controle de ratos Sinantrópicos",
  "Manutenção de Armadilhas Luminosas",
  "Locação de Armadilhas Luminosas",
  "Monitoramento Larval (moscas e mosquitos)",
  "Análise Microbiológica",
  "Manutenção de Armadilhas Biológicas",
  "Cupins",
];

tipoServico.sort();

export default tipoServico;
