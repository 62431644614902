import React, { useState, useEffect } from "react";
import Navbar from "../../../../components/navbar/Navbar";
import BodyContainer from "../../../../styles/BodyContainer";
import CardContainer from "../../../../styles/CardContainer";
import VerificaLogin from "../../../../utils/verificaLogin";
import { VerificaTipoContaOperacional } from "../../../../utils/verificaTipoConta";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import api from "../../../../service/api";
import Botao from "../../../../styles/Botao";

import scrollToTop from "../../../../utils/scrollToTop";
import formatOnlyNumber from "../../../../utils/formatOnlyNumber";

import toastSuccess from "../../../../utils/toastSuccess";
import toastError from "../../../../utils/toastError";
import LoadingRocket from "../../../../components/animations/LoadingRocket";
import Switch from "react-switch";

const CadastrarProduto = () => {
  VerificaLogin();
  VerificaTipoContaOperacional();
  const [loading, setLoading] = useState(true);
  const [nomeProduto, setNomeProduto] = useState("");
  const [unidadeMedida, setUnidadeMedida] = useState("");
  const [unidadesData, setUnidadesData] = useState([]);
  const [unidade, setUnidade] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [quantidadeMinima, setQuantidadeMinima] = useState("");
  const [numNotaFiscal, setNumNotaFiscal] = useState("");
  const [codBarras, setCodBarras] = useState("");
  const [valorBase, setValorBase] = useState("");

  const [checked, setChecked] = useState(true);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  const handleChangeNomeProduto = (event) => {
    setNomeProduto(event.target.value);
  };

  const handleChangeUnidadeMedida = (event) => {
    setUnidadeMedida(event.target.value);
  };

  const handleChangeUnidade = (event) => {
    setUnidade(event.target.value);
  };

  const handleChangeQuantidade = (event) => {
    setQuantidade(event.target.value);
  };

  const handleChangeNumNotaFiscal = (event) => {
    setNumNotaFiscal(event.target.value);
  };

  const handleChangeQuantidadeMinima = (event) => {
    setQuantidadeMinima(event.target.value);
  };

  const handleChangeCodBarras = (event) => {
    setCodBarras(event.target.value);
  };

  const handleChangeValorBase = (event) => {
    setValorBase(event.target.value);
  };

  useEffect(() => {
    api.get("/api/buscaUnidades").then((response) => {
      setUnidadesData(response.data);
      setLoading(false);
    });
  }, [loading]);

  useEffect(() => {
    setQuantidade(formatOnlyNumber(quantidade));
  }, [quantidade]);

  useEffect(() => {
    setQuantidadeMinima(formatOnlyNumber(quantidadeMinima));
  }, [quantidadeMinima]);

  useEffect(() => {
    setNumNotaFiscal(formatOnlyNumber(numNotaFiscal));
  }, [numNotaFiscal]);

  useEffect(() => {
    setCodBarras(formatOnlyNumber(codBarras));
  }, [codBarras]);

  const limpaCampos = () => {
    setNomeProduto("");
    setUnidadeMedida("");
    setUnidade("");
    setQuantidade("");
    setQuantidadeMinima("");
    setNumNotaFiscal("");
    setCodBarras("");
    setValorBase("");
  };

  return (
    <>
      {loading ? <LoadingRocket /> : null}
      <Navbar />
      <CardContainer>
        <form onClick={(e) => e.preventDefault()}>
          <BodyContainer>
            <h2>Informações do Produto</h2>
            <TextField
              label="Nome do Produto"
              value={nomeProduto}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeNomeProduto}
            />
            <TextField
              label="Unidade de Medida"
              value={unidadeMedida}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeUnidadeMedida}
            />
            <FormControl>
              <InputLabel>Unidade</InputLabel>
              <Select
                label="Unidade"
                value={unidade}
                onChange={handleChangeUnidade}
              >
                {unidadesData.map((unidades, index) => (
                  <MenuItem key={index} value={unidades.id}>
                    {unidades.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Quantidade"
              value={formatOnlyNumber(quantidade)}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeQuantidade}
            />
            <TextField
              label="Quantidade Mínima"
              value={formatOnlyNumber(quantidadeMinima)}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeQuantidadeMinima}
            />
            <TextField
              label="Número da Nota Fiscal"
              value={formatOnlyNumber(numNotaFiscal)}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeNumNotaFiscal}
            />
            <TextField
              label="Valor Base"
              placeholder="R$ 00,00"
              variant="outlined"
              value={valorBase}
              onChange={handleChangeValorBase}
            />
            <TextField
              label="Código de Barras"
              value={formatOnlyNumber(codBarras)}
              variant="outlined"
              autoComplete="off"
              onChange={handleChangeCodBarras}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px",
                backgroundColor: "#f3f3f3",
                borderRadius: "10px",
              }}
            >
              <p>Este produto deve aparecer no AGC?</p>

              <Switch
                checked={checked}
                onChange={handleChange}
                onColor="#045E88"
                activeBoxShadow={null}
              />
            </div>
          </BodyContainer>
          <BodyContainer>
            <Botao
              type={codBarras ? "submit" : "button"}
              value="Cadastrar Produto"
              onClick={() => {
                if (
                  !nomeProduto ||
                  !unidadeMedida ||
                  !unidade ||
                  !quantidade ||
                  !quantidadeMinima ||
                  !numNotaFiscal ||
                  !valorBase
                ) {
                  toastError("Preencha todos os campos");
                } else {
                  setLoading(true);
                  api
                    .post("/api/cadastrarProduto", {
                      nome: nomeProduto,
                      unidade_medida: unidadeMedida,
                      id_unidade: unidade,
                      quantidade: quantidade,
                      quantidade_minima: quantidadeMinima,
                      n_fiscal: numNotaFiscal,
                      valor_base: valorBase,
                      cod_barras: codBarras === "" ? null : codBarras,
                      status_agc: checked ? 1 : 0,
                    })
                    .then((response) => {
                      if (response.data.error) {
                        toastError(response.data.error);
                      } else {
                        toastSuccess("Produto cadastrado com sucesso");
                        limpaCampos();
                        scrollToTop();
                      }
                    });
                }
              }}
            />
          </BodyContainer>
        </form>
      </CardContainer>
    </>
  );
};

export default CadastrarProduto;
