import React from "react";
import BodyCard from "./style";

const Card = (props) => {
  return (
    <>
      <BodyCard
        style={{
          borderLeft: `5px solid ${props.cor}`,
          animation: "fadein 0.5s",
        }}
      >
        <h2>{props.titulo}</h2>
        <p>{props.paragrafo}</p>
      </BodyCard>
    </>
  );
};

export default Card;
