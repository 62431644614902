import React from "react";
import Lottie from "lottie-react";
import mail from "../../assets/lottie/mail.json";

const MailAnimation = () => (
  <Lottie
    animationData={mail}
    loop={false}
    style={{
      width: "100px",
    }}
  />
);

export default MailAnimation;
