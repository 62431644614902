import React from "react";
import Lottie from "lottie-react";
import infos from "../../assets/lottie/infos.json";

const InfosAnimation = () => (
  <Lottie
    animationData={infos}
    loop={false}
    style={{
      width: "150px",
      height: "150px",
    }}
  />
);

export default InfosAnimation;
